import EmailService from '@/services/EmailService.js'
export const namespaced = true

export const state = {
  showDialog: false,
  htmlEmail: ''
}

export const mutations = {
  SET_HTML_EMAIL (state, val) {
    state.htmlEmail = val
  },
  SET_SHOW_DIALOG (state, val) {
    state.showDialog = val
  }
}

export const actions = {
  show ({ commit }, id) {
    EmailService.getEmailHtml(id).then(response => {
      commit('SET_HTML_EMAIL', response.data.data.html)
      commit('SET_SHOW_DIALOG', true)
    })
  }
}
