import API from './API.js'

export default {
  //
  // GET
  //
  getReceipts ({ requestId, offset, visibleReceipts, sortedColumn, sortedDesc, filterRegisters, filterPaymentMethods, filterCreatedAt, searchString }) {
    return API.httpClient.get('receipts', {
      params: {
        request_id: requestId,
        offset,
        visible_receipts: visibleReceipts,
        sorted_column: sortedColumn,
        sorted_desc: sortedDesc,
        filter_registers: filterRegisters,
        filter_payment_methods: filterPaymentMethods,
        filter_created_at: filterCreatedAt,
        search_string: searchString
      }
    })
  },
  getReceipt (id) {
    return API.httpClient.get(`receipts/${id}`)
  },
  initiateReceiptCopy ({ registerId, receiptId }) {
    return API.httpClient.post('receipts/initiate-copy', {
      register_id: registerId,
      receipt_id: receiptId
    })
  }
}
