<template>
<div class="date-range-picker-container v-input pt-0 theme--light v-text-field v-text-field--is-booted v-text-field--enclosed v-text-field--outlined" category-id="0">
  <div class="v-input__control" :class="{ 'has-error': validation.hasError }">
    <div class="v-input__slot mb-0" >
      <fieldset aria-hidden="true"><legend style="width: auto; font-size: 13px; color: transparent;"><span>{{ title }}</span></legend></fieldset>
      <div class="v-text-field__slot">
        <label class="v-label v-label--active theme--light" style="right: auto; position: absolute;">{{ title }}</label>
        <div class="date-range-picker rounded">
          <v-row class="pa-0 ma-0">
            <v-col
              class="pa-0 ma-0 pl-1"
              cols="12"
              sm="5"
              @click="firstDateClick"
            >
              <v-text-field
                v-model="firstDateReadable"
                :class="classListFirstDateInput"
                height="56"
                filled
                readonly
                tabindex="-1"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="2"
              class="pa-0 ma-0 d-flex align-content-center flex-wrap"
            >
              <v-icon
                style="display: flex; flex: 1 1 auto;"
                class="">
                mdi-arrow-right-thick
              </v-icon>
            </v-col>
            <v-col
              class="pa-0 ma-0"
              cols="12"
              sm="5"
              @click="secondDateClick"
            >
              <v-text-field
                v-model="secondDateReadable"
                :class="classListSecondDateInput"
                height="56"
                filled
                readonly
                tabindex="-1"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-dialog
            v-if="isMobile"
            v-model="showCalendar"
            activator=".date-range-picker"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
          >
            <v-toolbar
              v-if="isMobile"
              dark
              color="primary"
            >
              <v-btn
                icon
                dark
                @click="showCalendar = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>{{ promptText }}</v-toolbar-title>
            </v-toolbar>
            <v-card>
              <co-calendar
                :title="promptText"
                :date-focused="dateFocused"
                :first-date="firstDate"
                :second-date="secondDate"
                :initial-month="initialMonth"
                :date-status="dateStatus"
                :is-loading-first-month="isLoadingFirstMonth"
                :is-loading-second-month="isLoadingSecondMonth"
                :allow-all-dates="allowAllDates"
                @set-date-focus="setDateFocus"
                @set-first-date="setFirstDate"
                @set-second-date="setSecondDate"
                @navigated="calendarNavigated"
              ></co-calendar>
            </v-card>
          </v-dialog>
          <v-menu
            v-else
            v-model="showCalendar"
            :activator="activator"
            :close-on-content-click="false"
            offset-y
            nudge-bottom="8"
          >
            <co-calendar
              :title="promptText"
              :date-focused="dateFocused"
              :first-date="firstDate"
              :second-date="secondDate"
              :initial-month="initialMonth"
              :date-status="dateStatus"
              :is-loading-first-month="isLoadingFirstMonth"
              :is-loading-second-month="isLoadingSecondMonth"
              :allow-all-dates="allowAllDates"
              @set-date-focus="setDateFocus"
              @set-first-date="setFirstDate"
              @set-second-date="setSecondDate"
              @navigated="calendarNavigated"
            ></co-calendar>
          </v-menu>
        </div>
      </div>
      <div
        v-if="isLoading"
        role="progressbar"
        aria-valuemin="0"
        aria-valuemax="100"
        class="v-progress-linear v-progress-linear--absolute v-progress-linear--visible theme--light"
        style="height: 2px;"
      >
        <div class="v-progress-linear__background primary" style="opacity: 0.3; left: 0%; width: 100%;"></div>
        <div class="v-progress-linear__buffer"></div>
        <div class="v-progress-linear__indeterminate v-progress-linear__indeterminate--active" style="">
          <div class="v-progress-linear__indeterminate long primary"></div>
          <div class="v-progress-linear__indeterminate short primary"></div>
        </div>
      </div>
    </div>
    <span v-if="validation.hasError" class="text-caption pl-2 pt-1 error--text">{{ validation.errorText }}</span>
  </div>
</div>
<!-- <div class="v-text-field v-text-field--outlined pt-0"> -->
<!-- <span :class="{ 'text-caption pl-2': true, 'error--text': validation.hasError }">{{ title }}</span> -->
<!-- <label for="input-233" class="v-label v-label--active theme--light" style="left: 0px; right: auto; position: absolute;">Karttext</label> -->

<!-- </div> -->
</template>

<script>
import CoCalendar from './CoCalendar.vue'

export default {
  name: 'DateRangePicker',
  components: {
    CoCalendar
  },
  props: {
    title: String,
    promptTitleFirstDate: String,
    promptTitleSecondDate: String,
    firstDate: Object,
    secondDate: Object,
    isMobile: Boolean,
    validation: Object,
    availability: Array,
    allowAllDates: Boolean,
    monthsLoading: Array,
    isLoading: Boolean
  },
  data: () => ({
    showCalendar: false,
    dateFocused: '',
    firstCalendarDate: null,
    secondCalendarDate: null,
    activator: null
  }),
  computed: {
    classListFirstDateInput: function () {
      return {
        'px-0 mb-0 rounded text-h5': true,
        'has-focus': (this.dateFocused === 'first')
      }
    },
    classListSecondDateInput: function () {
      return {
        'px-0 mb-0 rounded text-h5': true,
        'has-focus': (this.dateFocused === 'second')
      }
    },
    isLoadingFirstMonth: function () {
      const dt = window.dayjs.parseDate(this.firstCalendarDate)
      return this.monthsLoading.filter(f => { return f.type === this.dateFocused && f.month === dt.month() + 1 && f.year === dt.year() }).length > 0
    },
    isLoadingSecondMonth: function () {
      const dt = window.dayjs.parseDate(this.secondCalendarDate)
      return this.monthsLoading.filter(f => { return f.type === this.dateFocused && f.month === dt.month() + 1 && f.year === dt.year() }).length > 0
    },
    dateStatus: function () {
      const dateFocused = this.dateFocused ? this.dateFocused : 'first'
      // console.log('dateStatus', this.firstCalendarDate, this.secondCalendarDate, 'datefocused: ' + dateFocused, this.availability)
      const arr = []
      const dt1 = window.dayjs.parseDate(this.firstCalendarDate)
      const firstMonth = this.availability.filter(t => {
        return t.type === dateFocused && t.year === dt1.year() && t.month === dt1.month() + 1
      })[0]

      if (!firstMonth) {
        // console.log('missing firstMonth')
        this.$emit('missing-availability', { type: dateFocused, year: dt1.year(), month: dt1.month() + 1 })
      } else {
        console.log('found firstMonth', firstMonth)
        const firstDate = window.dayjs([firstMonth.year, firstMonth.month])
        let dt = firstDate
        while (dt.isSame(firstDate, 'month')) {
          arr.push({
            date: dt,
            isEnabled: firstMonth.dates.find(x => x === dt.format('YYYYMMDD')) != null
          })
          dt = dt.add(1, 'day')
        }
      }

      const dt2 = window.dayjs.parseDate(this.secondCalendarDate)
      const secondMonth = this.availability.filter(t => {
        return t.type === dateFocused && t.year === dt2.year() && t.month === dt2.month() + 1
      })[0]
      if (!secondMonth) {
        // console.log('missing secondMonth')
        this.$emit('missing-availability', { type: dateFocused, year: dt2.year(), month: dt2.month() + 1 })
      } else {
        console.log('found secondMonth', secondMonth)
        const firstDate = window.dayjs([secondMonth.year, secondMonth.month])
        let dt = firstDate
        while (dt.isSame(firstDate, 'month')) {
          arr.push({
            date: dt,
            isEnabled: secondMonth.dates.find(x => x === dt.format('YYYYMMDD')) != null
          })
          dt = dt.add(1, 'day')
        }
      }

      return arr
    },
    isMdAndDown: function () {
      return this.$vuetify.breakpoint.mdAndDown
    },
    initialMonth: function () {
      let dt = window.dayjs.today().startOf('month')
      if (this.firstDate) {
        if (this.dateFocused === 'second') {
          if (this.secondDate) {
            dt = this.secondDate.startOf('month')
          }
        } else {
          dt = this.firstDate.startOf('month')
        }
      }
      return dt
    },
    firstDateReadable: function () {
      const showYear = !this.firstDate.isSame(window.dayjs.today(), 'year') || !this.secondDate.isSame(window.dayjs.today(), 'year')
      if (this.isMdAndDown && !this.isMobile) {
        if (showYear) {
          return this.firstDate.format('D/M-YY')
        }
        return this.firstDate.format('D/M')
      } else {
        if (showYear) {
          return this.firstDate.format('ddd D MMM YYYY')
        }
        return this.firstDate.format('ddd D MMM')
      }
    },
    secondDateReadable: function () {
      const showYear = !this.firstDate.isSame(window.dayjs.today(), 'year') || !this.secondDate.isSame(window.dayjs.today(), 'year')
      if (this.isMdAndDown && !this.isMobile) {
        if (showYear) {
          return this.secondDate.format('D/M-YY')
        }
        return this.secondDate.format('D/M')
      } else {
        if (showYear) {
          return this.secondDate.format('ddd D MMM YYYY')
        }
        return this.secondDate.format('ddd D MMM')
      }
    },
    promptText: function () {
      return this.dateFocused === 'first' ? this.promptTitleFirstDate : this.promptTitleSecondDate
    }
  },
  methods: {
    setFirstDate: function (dt) {
      this.$emit('set-first-date', dt)
    },
    setSecondDate: function (dt) {
      this.$emit('set-second-date', dt)
    },
    calendarNavigated: function (val) {
      console.log('calendarNavigated')
      this.firstCalendarDate = val.firstMonth
      this.secondCalendarDate = val.secondMonth
    },
    setDateFocus: function (val) {
      if (val === '') {
        this.showCalendar = false
      }
      this.dateFocused = val
    },
    firstDateClick: function () {
      this.dateFocused = 'first'
    },
    secondDateClick: function () {
      console.log('secondDateClick', this.dateFocused)
      this.dateFocused = 'second'
    }
  },
  watch: {
    showCalendar: function () {
      console.log('watch showCalendar', this.dateFocused)
      if (!this.showCalendar) {
        this.dateFocused = ''
      } else if (this.dateFocused === '') {
        this.dateFocused = 'first'
      }
    }
  },
  created () {
    console.log('created daterangepicker', this.initialMonth)
    this.firstCalendarDate = this.initialMonth.format('YYYY-MM-DD')
    this.secondCalendarDate = this.initialMonth.add(1, 'month').format('YYYY-MM-DD')
  },
  mounted () {
    this.activator = this.$el.querySelector('.date-range-picker')
  }
}
</script>
