import API from './API.js'

export default {
  //
  // GET
  //
  getRegister (workstationId) {
    return API.httpClient.get('register/find', {
      params: {
        workstation_id: workstationId
      }
    })
  },
  fetchRegisters () {
    return API.httpClient.get('register')
  },
  getReport (registerId, reportId) {
    return API.httpClient.get('register/report', {
      params: {
        register_id: registerId,
        report_id: reportId
      }
    })
  },
  getJournal (registerId) {
    return API.httpClient.get('register/journal', {
      params: {
        register_id: registerId
      }
    })
  },
  getAccountingCalendarData (registerId, month) {
    return API.httpClient.get('register/accountingcalendardata', {
      params: {
        register_id: registerId,
        month
      }
    })
  },

  //
  // POST
  //
  openRegister (registerId, balance) {
    return API.httpClient.post('register/open', {
      register_id: registerId,
      balance
    })
  },
  closeRegister (registerId) {
    return API.httpClient.post('register/close', {
      register_id: registerId
    })
  },
  assignRegister ({ registerId, workstationId }) {
    return API.httpClient.post('register/assign-register', {
      register_id: registerId,
      workstation_id: workstationId
    })
  },
  setReconciliationCountedTotals (reconciliationId, countedTotals) {
    return API.httpClient.post('reconciliation/setcountedtotals', {
      reconciliation_id: reconciliationId,
      counted_totals: countedTotals
    })
  },
  finishReconciliation (reconciliationId, comment, transaction) {
    return API.httpClient.post('reconciliation/finish', {
      reconciliation_id: reconciliationId,
      comment,
      transaction
    })
  },
  openCashDrawer (registerId) {
    return API.httpClient.post('register/opencashdrawer', {
      register_id: registerId
    })
  }
}
