<template>
  <div :class="{ 'accommodation-charge-view': true, 'is-overlay': overlay }">
    <v-card class="list-card d-flex flex-column overflow-x-hidden overflow-y-auto" :tile="isShowEditingOverlay" style="position: relative;">
      <v-card-title
        class="text-center d-block py-2"
        @click="headerClicked"
      >
        {{ dateSelectionTitle }}
      </v-card-title>
      <v-btn v-if="accommodationChargesSelected.length > 0 || (overlay && isOpen)" icon style="position: absolute; right: 6px; top: 6px;" @click="close">
        <v-icon>
          mdi-close
        </v-icon>
      </v-btn>
      <template v-if="accommodationChargesSelected.length === 0 && suggestedAccommodationCharges.length > 0">
        <div class="list-card-content d-flex flex-column flex-grow-1">
          <!-- <div class="text-subtitle-2 ml-4">Prisförslag</div> -->
          <div class="flex-grow-1">
            <v-list-item
              v-for="(suggestedAccommodationCharge, i) in suggestedAccommodationCharges"
              :key="i"
              @mouseover="hoveringSuggestedAccommodationCharge(suggestedAccommodationCharge)"
              @mouseout="unsetHoveringSuggestedAccommodationCharge"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-list-item-content v-bind="attrs" v-on="on">
                    <v-list-item-title style="text-overflow: ellipsis; overflow: hidden;">{{ suggestedAccommodationCharge.text_line_1 }}</v-list-item-title>
                    <v-list-item-subtitle style="text-overflow: ellipsis; overflow: hidden;">{{ suggestedAccommodationCharge.text_line_2 }}</v-list-item-subtitle>
                  </v-list-item-content>
                </template>
                <div>
                  <div class="d-block">{{ suggestedAccommodationCharge.text_line_1 }}</div>
                  <div class="d-block">{{ suggestedAccommodationCharge.text_line_2 }}</div>
                </div>
              </v-tooltip>
              <v-list-item-icon>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="success-on-hover"
                      small
                      text
                      fab
                      color="primary"
                      @click="acceptSuggestedAccommodationCharge(suggestedAccommodationCharge)"
                      :loading="savingDates.includes(suggestedAccommodationCharge.start.format('YYYY-MM-DD'))"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-content-save</v-icon>
                    </v-btn>
                  </template>
                  <span>Acceptera prisförslag</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="error-on-hover"
                      small
                      text
                      fab
                      color="primary"
                      @click="dismissSuggestedAccommodationCharge(suggestedAccommodationCharge)"
                      :loading="dismissingDates.includes(suggestedAccommodationCharge.start.format('YYYY-MM-DD'))"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-cancel</v-icon>
                    </v-btn>
                  </template>
                  <span>Avfärda prisförslag</span>
                </v-tooltip>
              </v-list-item-icon>
            </v-list-item>
          </div>
          <div class="px-4 pb-4 mt-auto">
            <v-btn
              color="success"
              block
              class="mt-2"
              :disabled="(dismissingDates.length + savingDates.length) > 0"
              :loading="savingDates.length > 1"
              @click="acceptAllSuggestedAccommodationCharges"
            >
              Acceptera alla prisförslag
            </v-btn>
            <v-btn
              color="error"
              block
              text
              class="mt-4"
              :disabled="(dismissingDates.length + savingDates.length) > 0"
              :loading="dismissingDates.length > 1"
              @click="dismissAllSuggestedAccommodationCharges"
            >
              Avfärda alla prisförslag
            </v-btn>
          </div>
        </div>
      </template>
      <template v-else-if="accommodationChargesSelected.length > 0">
        <template v-if="hasNoChargesInSelectedDates">
          <v-alert
            dense
            text
            type="info"
            class="d-block mx-4 mt-2"
          >
            <div class="d-flex">
              <div class="flex-grow-1 my-auto">
                Det finns inga platsavgifter under vald period
              </div>
            </div>
          </v-alert>
        </template>
        <template v-else-if="hasDaysWithoutCharges">
          <v-alert
            dense
            text
            type="info"
            class="d-block mx-4 mt-2"
          >
            <div class="d-flex">
              <div class="flex-grow-1 my-auto">
                Det finns dagar utan platsavgifter under vald period
              </div>
            </div>
          </v-alert>
        </template>
        <template v-else-if="hasDaysWithMultiNightCharges">
          <v-alert
            dense
            text
            type="info"
            class="d-block mx-4 mt-2"
          >
            <div class="d-flex">
              <div class="flex-grow-1 my-auto">
                Perioden innehåller platsavgifter som avser flera nätter
              </div>
            </div>
          </v-alert>
        </template>

        <template v-else>
          <v-list :dense="overlay" class="py-0">
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>{{ positionNameSelectedCharges }}</v-list-item-title>
                <v-list-item-subtitle>Plats</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>{{ noteSelectedCharges }}</v-list-item-title>
                <v-list-item-subtitle>Notering</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>{{ priceSelectedCharges }}</v-list-item-title>
                <v-list-item-subtitle>{{ priceLabel }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>{{ discountSelectedCharges }}</v-list-item-title>
                <v-list-item-subtitle>Rabatt</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </template>
        <div class="mx-4 mb-4 mt-auto">
          <v-btn
            v-if="selectionIsMultiNightCharge"
            block
            text
            class="mb-4"
            color="success"
            @click="splitMultiNightCharge"
          >
            Konvertera till dygnspris
          </v-btn>
          <v-btn
            color="success"
            block
            :disabled="hasDaysWithMultiNightCharges"
            @click="startEditCharges"
          >{{ editButtonText }}</v-btn>
        </div>
      </template>
      <template v-else>
        <!-- Inga suggested och inga valda datum -->
        <v-alert
          dense
          text
          type="info"
          class="d-block mx-4 mt-2"
        >
          <div class="d-flex">
            <div class="flex-grow-1 my-auto">
              Välj datum i kalendern för att redigera platsavgifter. Du kan dra med muspekaren för att markera flera datum att redigera samtidigt.
            </div>
          </div>
        </v-alert>
      </template>

      <div v-if="!!editedCharge" class="d-flex flex-column overflow-hidden" style="position: absolute; height: 100%; width: 100%; top: 0; left: 0; pointer-events: none; z-index: 2;">
        <v-fade-transition>
          <div @click="closeEditingOverlay" v-show="isShowEditingOverlay" style="height: 48px; opacity: 0.23; background-color: rgb(33, 33, 33); pointer-events: auto;"></div>
        </v-fade-transition>
        <v-scroll-y-reverse-transition>
          <div v-show="isShowEditingOverlay" class="flex-grow-1">
            <v-card class="full-height d-flex flex-column" style="pointer-events: auto;">
              <v-toolbar class="flex-shrink-1 flex-grow-0" flat>
                <div class="d-flex">
                  <v-toolbar-title class="headline">{{ editButtonText }}</v-toolbar-title>
                </div>
                <v-spacer></v-spacer>
                <v-btn icon @click="closeEditingOverlay">
                  <v-icon>
                    mdi-close
                  </v-icon>
                </v-btn>
              </v-toolbar>
              <div class="flex-grow-1 px-7 pt-3">
                <price-input
                  v-model="editedCharge"
                  three-rows
                  :error-messages="priceErrorMessages"
                  @updated-price="validatePrice"
                ></price-input>
              </div>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="success"
                  @click="saveEditedCharge"
                  :loading="isSavingEditedCharge"
                >Spara</v-btn>
              </v-card-actions>
            </v-card>
          </div>
        </v-scroll-y-reverse-transition>
      </div>
    </v-card>
  </div>
</template>

<script>
import PriceInput from '@/components/product/PriceInput.vue'

export default {
  name: 'AccommodationChargeView',
  components: {
    PriceInput
  },
  props: {
    overlay: Boolean,
    isOpen: Boolean,
    booking: Object,
    calendarItems: Array,
    loading: Boolean,
    selectedDates: Array
  },
  data: () => ({
    isShowEditingOverlay: false,
    editedCharge: null,
    isSavingEditedCharge: false,
    hoveringDates: [],
    savingDates: [],
    dismissingDates: [],
    priceError: ''
  }),
  computed: {
    editButtonText: function () {
      if (this.selectedDates.length === 0) {
        return ''
      }
      if (this.hasNoChargesInSelectedDates) {
        if (this.selectedDates.length > 1) {
          return 'Skapa platsavgifter'
        } else {
          return 'Skapa platsavgift'
        }
      } else {
        if (this.selectionIsMultiNightCharge) {
          return 'Redigera periodpris'
        } else if (this.selectedDates.length > 1) {
          return 'Redigera platsavgifter'
        } else {
          return 'Redigera platsavgift'
        }
      }
    },
    dateSelectionTitle: function () {
      if (this.selectedDates.length === 1) {
        const firstDate = window.dayjs.parseDate(this.selectedDates[0])
        return `${firstDate.format('D MMM YYYY')}`
      } else if (this.selectedDates.length > 1) {
        const firstDate = window.dayjs.parseDate(this.selectedDates[0])
        const lastDate = window.dayjs.parseDate(this.selectedDates[this.selectedDates.length - 1])
        return `${firstDate.format('D MMM YYYY')} - ${lastDate.format('D MMM YYYY')}`
      } else if (this.suggestedAccommodationCharges.length > 0) {
        return 'Prisförslag'
      }
      return 'Platsavgiftskalender'
    },
    accommodationChargesSelected: function () {
      if (this.selectedDates.length === 0) {
        return []
      }
      const accommodationCharges = []
      this.selectedDates.forEach(date => {
        const calendarItem = this.calendarItems.find(ci => ci.date === date)
        if (calendarItem?.accommodation_charge) {
          if (accommodationCharges.findIndex(ac => !!ac && ac.id === calendarItem.accommodation_charge.id) === -1) {
            // Lägger inte till accommodationCharge om det redan finns accommodationCharge med samma id
            accommodationCharges.push(calendarItem.accommodation_charge)
          }
        } else {
          accommodationCharges.push(null)
        }
      })
      return accommodationCharges
    },
    suggestedAccommodationCharges: function () {
      return this.calendarItems.map(ci => ci.suggested_accommodation_charge).filter(ci => !!ci)
    },
    allAccommodationCharges: function () {
      return this.calendarItems.map(ci => ci.accommodation_charge).filter(ci => !!ci)
    },
    hasNoChargesInSelectedDates: function () {
      // inga accommodation charges under valda datum
      return this.accommodationChargesSelected.filter(ac => !!ac).length === 0
    },
    hasDaysWithoutCharges: function () {
      // finns dagar utan accommodation charges
      return this.accommodationChargesSelected.filter(ac => !ac).length > 0
    },
    hasDaysWithMultiNightCharges: function () {
      const hasMultiNightCharges = this.accommodationChargesSelected.filter(ac => !!ac && ac.nights > 1).length > 0
      return hasMultiNightCharges && this.accommodationChargesSelected.length > 1
    },
    priceLabel: function () {
      const hasMultiNightCharges = this.accommodationChargesSelected.filter(ac => !!ac && ac.nights > 1).length > 0
      if (hasMultiNightCharges) {
        return 'Pris'
      }
      return 'Pris / natt'
    },
    positionNameSelectedCharges: function () {
      if (this.accommodationChargesSelected.length === 0) {
        return ''
      }
      const isSamePosition = this.accommodationChargesSelected.every(ac => ac.position_id === this.accommodationChargesSelected[0].position_id)
      if (isSamePosition) {
        if (this.accommodationChargesSelected[0].position) {
          return this.accommodationChargesSelected[0].position.full_name
        } else {
          return 'Okänd plats' // platsen kan ha tagits bort
        }
      }
      return 'Olika platser'
    },
    noteSelectedCharges: function () {
      if (this.accommodationChargesSelected.length === 0) {
        return ''
      }
      const isSameNote = this.accommodationChargesSelected.every(ac => ac.note === this.accommodationChargesSelected[0].note)
      if (isSameNote) {
        if (this.accommodationChargesSelected[0].note === '' || this.accommodationChargesSelected[0].note === null) {
          return '-'
        }
        return this.accommodationChargesSelected[0].note
      }
      return 'Olika noteringar'
    },
    priceSelectedCharges: function () {
      if (this.accommodationChargesSelected.length === 0) {
        return ''
      }
      const isSamePrice = this.accommodationChargesSelected.every(ac => ac.price === this.accommodationChargesSelected[0].price)
      if (isSamePrice) {
        const price = this.accommodationChargesSelected[0].price / 100
        return `${price}kr`
      }
      return 'Olika priser'
    },
    discountSelectedCharges: function () {
      if (this.accommodationChargesSelected.length === 0) {
        return ''
      }
      const isSameDiscount = this.accommodationChargesSelected.every(ac => ac.discount_type === this.accommodationChargesSelected[0].discount_type && ac.discount_value === this.accommodationChargesSelected[0].discount_value)
      if (isSameDiscount) {
        const discountValue = this.accommodationChargesSelected[0].discount_value / 100
        if (this.accommodationChargesSelected[0].discount_type === 'flat') {
          return `${discountValue}kr rabatt`
        } else if (this.accommodationChargesSelected[0].discount_type === 'fixed') {
          return `Nedsatt från ${this.accommodationChargesSelected[0].original_price / 100}kr`
        } else if (this.accommodationChargesSelected[0].discount_type === 'percent') {
          return `${discountValue}% rabatt`
        }
        return 'Ingen rabatt'
      }
      return 'Olika rabatter'
    },
    priceErrorMessages: function () {
      if (this.priceError === '') {
        return []
      }
      return [this.priceError]
    },
    selectionIsMultiNightCharge: function () {
      // true om man valt ett periodpris, och inget annat
      if (
        this.accommodationChargesSelected.length !== 1 ||
        this.accommodationChargesSelected[0] === null ||
        this.accommodationChargesSelected[0].nights <= 1 ||
        this.selectedDates.length !== this.accommodationChargesSelected[0].nights
      ) {
        return false
      }
      // Gör extra kontroll för säkerhets skull
      if (this.accommodationChargesSelected[0].start.format('YYYY-MM-DD') !== this.selectedDates[0] ||
      this.accommodationChargesSelected[0].end.add(-1, 'days').format('YYYY-MM-DD') !== this.selectedDates[this.selectedDates.length - 1]) {
        return false
      }
      return true
    }
  },
  methods: {
    closeEditingOverlay: function () {
      this.isShowEditingOverlay = false
    },
    close: function () {
      if (this.overlay) {
        this.$emit('close')
      } else {
        this.unselectDateRange()
      }
    },
    hoveringSuggestedAccommodationCharge: function (suggestAccommodationCharge) {
      const hoveringDates = []
      let iteratedDate = suggestAccommodationCharge.start.clone()
      while (iteratedDate.isBefore(suggestAccommodationCharge.end)) {
        hoveringDates.push(iteratedDate.format('YYYY-MM-DD'))
        iteratedDate = iteratedDate.add(1, 'days')
      }
      this.hoveringDates = hoveringDates
    },
    unsetHoveringSuggestedAccommodationCharge: function () {
      this.hoveringDates = []
    },
    splitMultiNightCharge: function () {
      if (this.selectionIsMultiNightCharge) {
        // valt en period med en accommodation charge med flera nätter, och inga andra datum, vi delar upp denna perioden på flera accommodation charges
        const multiNightCharge = this.accommodationChargesSelected[0]
        this.editedCharge = {
          id: 0,
          isPeriodAccommodationCharge: false,
          quantity: 1,
          discount_type: null,
          discount_value: 0,
          price: Math.round(multiNightCharge.price / multiNightCharge.nights),
          original_price: 0,
          note: ''
        }
        this.saveEditedCharge()
        return false
      }
    },
    startEditCharges: function () {
      const firstAccommodationCharge = this.accommodationChargesSelected[0]
      if (this.selectionIsMultiNightCharge) {
        // valt en period med en accommodation charge med flera nätter, och inga andra datum
        this.editedCharge = {
          id: firstAccommodationCharge.id,
          isPeriodAccommodationCharge: true,
          quantity: 1,
          discount_type: firstAccommodationCharge.discount_type,
          discount_value: firstAccommodationCharge.discount_value,
          price: firstAccommodationCharge.price,
          original_price: firstAccommodationCharge.original_price,
          note: ''
        }
      } else if (this.accommodationChargesSelected.length === 1) {
        if (firstAccommodationCharge === null) {
          // Redigerar en dag utan accommodation charge
          this.editedCharge = {
            id: 0,
            isPeriodAccommodationCharge: false,
            quantity: 1,
            discount_type: null,
            discount_value: 0,
            price: 0,
            original_price: 0,
            note: ''
          }
        } else {
          // Redigerar en dag med accommodation charge
          this.editedCharge = {
            id: firstAccommodationCharge.id,
            isPeriodAccommodationCharge: false,
            quantity: 1,
            discount_type: firstAccommodationCharge.discount_type,
            discount_value: firstAccommodationCharge.discount_value,
            price: firstAccommodationCharge.price,
            original_price: firstAccommodationCharge.original_price,
            note: ''
          }
        }
      } else {
        // Valt flera dagar
        if (this.hasDaysWithoutCharges) {
          // Finns dagar utan charges
          this.editedCharge = {
            id: 0,
            isPeriodAccommodationCharge: false,
            quantity: 1,
            discount_type: null,
            discount_value: 0,
            price: 0,
            original_price: 0,
            note: ''
          }
        } else {
          // Flera dagar, och alla har charges
          const isSamePrice = this.accommodationChargesSelected.every(ac => ac.price === this.accommodationChargesSelected[0].price)
          const isSameDiscount = this.accommodationChargesSelected.every(ac => ac.discount_type === this.accommodationChargesSelected[0].discount_type && ac.discount_value === this.accommodationChargesSelected[0].discount_value)
          this.editedCharge = {
            id: 0,
            isPeriodAccommodationCharge: false,
            quantity: 1,
            discount_type: isSameDiscount ? firstAccommodationCharge.discount_type : null,
            discount_value: isSameDiscount ? firstAccommodationCharge.discount_value : null,
            price: isSamePrice ? firstAccommodationCharge.price : 0,
            original_price: 0,
            note: ''
          }
        }
      }
      this.validatePrice()

      this.isShowEditingOverlay = true
    },
    validatePrice: function () {
      if (this.editedCharge.price === '' || this.editedCharge.price === null || this.editedCharge.price === undefined) {
        this.priceError = 'Ange ett pris'
        return false
      } else if (this.editedCharge.price < 0) {
        // negativa priser är inte tillåtet
        this.priceError = 'En vara kan inte ha negativt pris, ange ett negativt antal för att göra returer'
        return false
      } else {
        // Tillåter högre pris än original_price
        this.priceError = ''
        return true
      }
    },
    unselectDateRange: function () {
      this.$emit('unselect-dates')
    },
    headerClicked: function () {
      this.$emit('header-clicked')
    },
    saveEditedCharge: function () {
      const bookingId = this.booking.id

      if (!this.validatePrice()) {
        return false
      }

      this.isSavingEditedCharge = true

      // Lägger till laddningsanimationer
      const selectedDates = this.selectedDates.slice() // close() nollställer selectedDates, tar därför en kopia först
      selectedDates.forEach(dt => {
        this.savingDates.push(dt)
      })
      if (this.overlay) {
        this.close()
      }

      this.$store.dispatch('booking/saveAccommodationCharges', {
        bookingId,
        dates: selectedDates,
        isPeriodAccommodationCharge: this.editedCharge.isPeriodAccommodationCharge,
        price: this.editedCharge.price,
        discountType: this.editedCharge.discount_type,
        discountValue: this.editedCharge.discount_value
      })
        .then(() => {
          this.isSavingEditedCharge = false
          this.isShowEditingOverlay = false
          // Tar bort laddningsanimationer
          selectedDates.forEach(dt => {
            const i = this.savingDates.findIndex(d => d === dt)
            if (i >= 0) {
              this.savingDates.splice(i, 1)
            }
          })
        })
    },
    acceptSuggestedAccommodationCharge: function (suggestedAccommodationCharge) {
      const bookingId = this.booking.id

      // Lägger till laddningsanimationer
      const loadingDates = []
      let iteratedDate = suggestedAccommodationCharge.start.clone()
      while (iteratedDate.isBefore(suggestedAccommodationCharge.end)) {
        loadingDates.push(iteratedDate.format('YYYY-MM-DD'))
        iteratedDate = iteratedDate.add(1, 'days')
      }
      loadingDates.forEach(dt => {
        this.savingDates.push(dt)
      })
      if (this.overlay) {
        this.close()
      }

      const accommodationCharges = [suggestedAccommodationCharge]
      this.$store.dispatch('booking/confirmAccommodationCharges', {
        bookingId,
        accommodationCharges
      })
        .then(() => {
          // Tar bort laddningsanimationer
          loadingDates.forEach(dt => {
            let i = this.savingDates.findIndex(d => d === dt)
            if (i >= 0) {
              this.savingDates.splice(i, 1)
            }
            i = this.hoveringDates.findIndex(d => d === dt)
            if (i >= 0) {
              this.hoveringDates.splice(i, 1)
            }
          })
        })
    },
    dismissSuggestedAccommodationCharge: function (suggestedAccommodationCharge) {
      const bookingId = this.booking.id

      // Lägger till laddningsanimationer
      const loadingDates = []
      let iteratedDate = suggestedAccommodationCharge.start.clone()
      while (iteratedDate.isBefore(suggestedAccommodationCharge.end)) {
        loadingDates.push(iteratedDate.format('YYYY-MM-DD'))
        iteratedDate = iteratedDate.add(1, 'days')
      }
      loadingDates.forEach(dt => {
        this.dismissingDates.push(dt)
      })
      if (this.overlay) {
        this.close()
      }

      const accommodationCharges = [suggestedAccommodationCharge]
      this.$store.dispatch('booking/dismissAccommodationCharges', {
        bookingId,
        accommodationCharges
      })
        .then(() => {
          // Tar bort laddningsanimationer
          loadingDates.forEach(dt => {
            let i = this.dismissingDates.findIndex(d => d === dt)
            if (i >= 0) {
              this.dismissingDates.splice(i, 1)
            }
            i = this.hoveringDates.findIndex(d => d === dt)
            if (i >= 0) {
              this.hoveringDates.splice(i, 1)
            }
          })
        })
    },
    acceptAllSuggestedAccommodationCharges: function () {
      const bookingId = this.booking.id

      // Lägger till laddningsanimationer
      const loadingDates = []
      this.suggestedAccommodationCharges.forEach(suggestedAccommodationCharge => {
        let iteratedDate = suggestedAccommodationCharge.start.clone()
        while (iteratedDate.isBefore(suggestedAccommodationCharge.end)) {
          loadingDates.push(iteratedDate.format('YYYY-MM-DD'))
          iteratedDate = iteratedDate.add(1, 'days')
        }
      })
      loadingDates.forEach(dt => {
        this.savingDates.push(dt)
      })
      if (this.overlay) {
        this.close()
      }

      const accommodationCharges = this.suggestedAccommodationCharges
      this.$store.dispatch('booking/confirmAccommodationCharges', {
        bookingId,
        accommodationCharges
      })
        .then(() => {
          // Tar bort laddningsanimationer
          loadingDates.forEach(dt => {
            const i = this.savingDates.findIndex(d => d === dt)
            if (i >= 0) {
              this.savingDates.splice(i, 1)
            }
          })
          this.unsetHoveringSuggestedAccommodationCharge()
        })
    },
    dismissAllSuggestedAccommodationCharges: function () {
      const bookingId = this.booking.id

      // Lägger till laddningsanimationer
      const loadingDates = []
      this.suggestedAccommodationCharges.forEach(suggestedAccommodationCharge => {
        let iteratedDate = suggestedAccommodationCharge.start.clone()
        while (iteratedDate.isBefore(suggestedAccommodationCharge.end)) {
          loadingDates.push(iteratedDate.format('YYYY-MM-DD'))
          iteratedDate = iteratedDate.add(1, 'days')
        }
      })
      loadingDates.forEach(dt => {
        this.dismissingDates.push(dt)
      })
      if (this.overlay) {
        this.close()
      }

      const accommodationCharges = this.suggestedAccommodationCharges
      this.$store.dispatch('booking/dismissAccommodationCharges', {
        bookingId,
        accommodationCharges
      })
        .then(() => {
          // Tar bort laddningsanimationer
          loadingDates.forEach(dt => {
            const i = this.dismissingDates.findIndex(d => d === dt)
            if (i >= 0) {
              this.dismissingDates.splice(i, 1)
            }
          })
          this.unsetHoveringSuggestedAccommodationCharge()
        })
    }
  },
  watch: {
    hoveringDates: function () {
      this.$emit('set-hovering-dates', this.hoveringDates)
    },
    savingDates: function () {
      this.$emit('set-saving-dates', this.savingDates)
    },
    dismissingDates: function () {
      this.$emit('set-dismissing-dates', this.dismissingDates)
    },
    selectedDates: function () {
      this.isShowEditingOverlay = false
    }
  }
}
</script>
