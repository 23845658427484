<template>
  <v-list-item class="d-flex flex-wrap">
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-list-item-content
          @click="bookingClicked"
          v-bind="attrs"
          v-on="on"
          class="cursor-pointer"
        >
          <v-list-item-title v-text="title"></v-list-item-title>
          <v-list-item-subtitle v-text="subtitle"></v-list-item-subtitle>
        </v-list-item-content>
      </template>
      <span>Visa bokning</span>
    </v-tooltip>

    <v-list-item-icon v-show="booking.requests != null && booking.requests !== ''" class="ml-1 my-auto">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            aria-hidden="false"
            class="d-flex justify-align"
            v-bind="attrs"
            v-on="on"
          >
            mdi-message-text-outline
          </v-icon>
        </template>
        <div style="padding: 4px;">
          <h3>Meddelande från kunden:</h3>
          <i>{{ booking.requests }}</i>
        </div>
      </v-tooltip>
    </v-list-item-icon>

    <v-list-item-icon v-show="booking.remarks && booking.remarks !== ''" class="ml-1 my-auto">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            aria-hidden="false"
            class="d-flex justify-align"
            v-bind="attrs"
            v-on="on"
          >
            mdi-tag-text-outline
          </v-icon>
        </template>
        <div style="padding: 4px;">
          <h3>Bokningsanteckningar:</h3>
          <i>{{ booking.remarks }}</i>
        </div>
      </v-tooltip>
    </v-list-item-icon>

    <v-list-item-icon v-show="isDelayed" class="ml-1 my-auto">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            aria-hidden="false"
            class="d-flex justify-align"
            v-bind="attrs"
            v-on="on"
            color="error"
          >
            mdi-calendar-alert
          </v-icon>
        </template>
        <span>{{ delayedText }}</span>
      </v-tooltip>
    </v-list-item-icon>

    <v-list-item-icon class="ml-2 action-items">
      <div v-if="isDeleted" style="min-width: 150px;">
        <v-alert
          dense
          text
          color="warning"
          class="my-0"
        >
          <v-icon color="warning" class="mr-4">mdi-check-circle</v-icon>
          <div class="d-inline-block">Avbokad</div>
        </v-alert>
      </div>

      <div v-else-if="isCheckedOut" style="min-width: 150px;">
        <v-alert
          dense
          text
          type="success"
          class="my-0"
        >
          Utcheckad
        </v-alert>
      </div>

      <div v-else-if="!isCheckedIn">
        <v-btn color="secondary darken-2" @click="checkIn">
          Checka In
        </v-btn>
      </div>

      <div v-else-if="shouldCheckOut">
        <v-btn color="secondary darken-2" @click="startCheckOut">
          Påbörja utcheckning
        </v-btn>
      </div>

      <div v-else>
        <v-btn color="secondary darken-2" @click="openBookingInRegister">
          Öppna i Kassan
        </v-btn>
      </div>
    </v-list-item-icon>
  </v-list-item>
</template>

<script>

export default {
  name: 'CustomerBookingsListRow',
  components: {},
  props: {
    booking: Object
  },
  data: () => ({}),
  computed: {
    isCheckedIn: function () {
      return this.booking.checked_in_at != null && this.booking.checked_out_at === null
    },
    isCheckedOut: function () {
      return this.booking.checked_out_at != null
    },
    shouldCheckOut: function () {
      const today = window.dayjs.today()
      return this.isCheckedIn && this.booking.check_out.isSameOrBefore(today)
    },
    isDeleted: function () {
      return this.booking.deleted_at != null
    },
    isDelayed: function () {
      if (this.isDeleted) {
        return false
      }
      if (this.isCheckedIn) {
        return this.booking.check_out.isBefore(window.dayjs.today(), 'date')
      } else if (!this.isCheckedOut) {
        return this.booking.check_in.isBefore(window.dayjs.today(), 'date')
      }
      return false
    },
    title: function () {
      return `${this.booking.booking_number}: ${this.booking.position.full_name}`
    },
    subtitle: function () {
      if (this.booking.type === 'season') {
        return this.booking.subtitle
      }
      const firstDate = this.booking.check_in.toHumanReadableDate()
      const secondDate = this.booking.check_out.toHumanReadableDate()
      return `${firstDate} - ${secondDate} (${this.booking.daysFormatted})`
    },
    delayedText: function () {
      if (this.isCheckedIn && this.booking.check_out.isBefore(window.dayjs.today(), 'date')) {
        if (this.booking.check_out.isYesterday()) {
          return 'Denna bokning skulle checkat ut igår'
        } else {
          return 'Denna bokning skulle checkat ut ' + this.booking.check_out.format('ddd, D MMM, YYYY')
        }
      } else if (this.booking.check_in.isBefore(window.dayjs.today(), 'date')) {
        if (this.booking.check_in.isYesterday()) {
          return 'Denna bokning skulle checkat in igår'
        } else {
          return 'Denna bokning skulle checkat in ' + this.booking.check_in.format('ddd, D MMM, YYYY')
        }
      } else {
        return ''
      }
    }
  },
  methods: {
    bookingClicked: function () {
      this.$emit('clicked', this.booking)
    },
    openBookingInRegister: function () {
      this.$router.push({ name: 'PosRegister', params: { chargable: 'booking', id: this.booking.id } })
      this.$emit('close')
    },
    checkIn: function () {
      this.$store.dispatch('booking/checkIn', this.booking.id)
      this.$emit('close')
    },
    startCheckOut: function () {
      this.$store.dispatch('booking/startCheckOut', { booking: this.booking })
      this.$emit('close')
    }
  }
}
</script>
