import API from './API.js'

export default {
  //
  // GET
  //
  getTerminals () {
    return API.httpClient.get('terminal')
  },

  //
  // POST
  //
  reconcile (terminalId) {
    return API.httpClient.post('terminal/reconcile', {
      terminal_id: terminalId
    })
  },
  performAdministration (terminalId, action) {
    return API.httpClient.post('terminal/perform-administration', {
      terminal_id: terminalId,
      action
    })
  },
  cancelReceiptPayment (receiptId) {
    return API.httpClient.post('sales/cancel-receipt-payment', {
      receipt_id: receiptId
    })
  },
  connectSurfboardTerminal (terminalSerialNumber, registerId) {
    return API.httpClient.post('terminal/connect-surfboard-terminal', {
      terminal_serial_number: terminalSerialNumber,
      register_id: registerId
    })
  },
  assignTerminal ({ terminalId, workstationId, softwareProvider }) {
    return API.httpClient.post('terminal/assign-terminal', {
      terminal_id: terminalId,
      workstation_id: workstationId,
      software_provider: softwareProvider
    })
  }
}
