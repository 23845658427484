import RegisterService from '@/services/RegisterService.js'
export const namespaced = true

export const state = {
  customer: null, // kund öppen i kassa, t.ex. kund vald för fakturering - används inte när booking öppnas i kassan
  openedAt: null,
  report: null,
  register: null,
  netsTerminal: null,
  isIgnorePromptToOpen: false,
  isLoadingOpen: false,
  isClosingRegisterIds: [],
  isLoadingGetRegister: false,
  units: [],
  isFetchingUnits: false,
  isLoadingGetReportsRegisterIds: [],
  isPrintingReports: [],
  isPrintingXReport: false,
  isOpeningCashDrawer: false,
  isLoadingDownloadJournal: false,
  isLoadingAssignHostRegisterIds: [],
  isLoadingUnassignHostRegisterIds: [],
  openReport: null // Rapporten som visas i ReportDialog, dialogen visas direkt när denna inte är null
}

export const mutations = {
  SET_REGISTER (state, val) {
    console.log('SET_REGISTER')
    state.register = val
    if (state.register) {
      if (state.register.opened_at) {
        state.openedAt = window.dayjs.parseServerDateTime(state.register.opened_at)
      } else {
        state.openedAt = null
      }
      if (state.units && state.units.length > 0) {
        const i = state.units.findIndex(reg => reg.id === state.register.id)
        state.units.splice(i, 1, state.register)
      } else if (state.units && state.units.length === 0) {
        state.units.push(state.register)
      }
      this.dispatch('posErrors/checkForErrors')
    }
  },
  SET_TERMINAL (state, val) {
    state.netsTerminal = val
  },
  SET_IS_IGNORE_PROMPT_TO_OPEN (state, val) {
    state.isIgnorePromptToOpen = val
  },
  SET_IS_LOADING_OPEN (state, val) {
    state.isLoadingOpen = val
  },
  SET_IS_CLOSING_REGISTED_IDS (state, { registerId, val }) {
    if (val) {
      const i = state.isClosingRegisterIds.findIndex(id => id === registerId)
      if (i < 0) {
        state.isClosingRegisterIds.push(registerId)
      }
    } else {
      const i = state.isClosingRegisterIds.findIndex(id => id === registerId)
      state.isClosingRegisterIds.splice(i, 1)
    }
  },
  SET_IS_LOADING_GET_REGISTER (state, val) {
    state.isLoadingGetRegister = val
  },
  SET_UNITS (state, val) {
    state.units = val
  },
  SET_IS_FETCHING_UNITS (state, val) {
    state.isFetchingUnits = val
  },
  SET_IS_LOADING_GET_REPORTS_REGISTER_IDS (state, payload) {
    if (payload.val) {
      const i = state.isLoadingGetReportsRegisterIds.findIndex(id => id === payload.registerId)
      if (i < 0) {
        state.isLoadingGetReportsRegisterIds.push(payload.registerId)
      }
    } else {
      const i = state.isLoadingGetReportsRegisterIds.findIndex(id => id === payload.registerId)
      state.isLoadingGetReportsRegisterIds.splice(i, 1)
    }
  },
  SET_REGISTER_REPORTS (state, payload) {
    const i = state.units.findIndex(u => u.id === payload.registerId)
    let unit = state.units[i]
    unit = Object.assign({}, unit, { reports: payload.data })
    state.units.splice(i, 1, unit)
  },
  SET_IS_PRINTING_REPORTS (state, payload) {
    state.isPrintingReports.splice(payload.reportId, 1, payload.val)
  },
  SET_IS_PRINTING_X_REPORT (state, val) {
    state.isPrintingXReport = val
  },
  SET_IS_OPENING_CASH_DRAWER (state, val) {
    state.isOpeningCashDrawer = val
  },
  SET_IS_LOADING_DOWNLOAD_JOURNAL (state, val) {
    state.isLoadingDownloadJournal = val
  },
  SET_IS_LOADING_ASSIGN_HOST (state, payload) {
    if (payload.val) {
      const i = state.isLoadingAssignHostRegisterIds.findIndex(id => id === payload.registerId)
      if (i < 0) {
        state.isLoadingAssignHostRegisterIds.push(payload.registerId)
      }
    } else {
      const i = state.isLoadingAssignHostRegisterIds.findIndex(id => id === payload.registerId)
      state.isLoadingAssignHostRegisterIds.splice(i, 1)
    }
  },
  SET_IS_LOADING_UNASSIGN_HOST (state, payload) {
    if (payload.val) {
      const i = state.isLoadingUnassignHostRegisterIds.findIndex(id => id === payload.registerId)
      if (i < 0) {
        state.isLoadingUnassignHostRegisterIds.push(payload.registerId)
      }
    } else {
      const i = state.isLoadingUnassignHostRegisterIds.findIndex(id => id === payload.registerId)
      state.isLoadingUnassignHostRegisterIds.splice(i, 1)
    }
  },
  SET_OPEN_REPORT (state, val) {
    state.openReport = val
  },
  REPLACE_RECONCILIATION (state, reconciliation) {
    if (state.openReport.reconciliation.id === reconciliation.id) {
      state.openReport.reconciliation = reconciliation
    }
  },
  SET_CUSTOMER (state, val) {
    state.customer = val
  }
}

export const actions = {
  open ({ state, commit }, balance) {
    commit('SET_IS_LOADING_OPEN', true)
    RegisterService.openRegister(state.register.id, balance)
      .then(({ data }) => {
        if (data.status === 'success') {
          commit('SET_REGISTER', data.data)
        }
      })
      .finally(() => {
        commit('SET_IS_LOADING_OPEN', false)
      })
  },
  async close ({ commit, dispatch }, { registerId }) {
    commit('SET_IS_CLOSING_REGISTED_IDS', { registerId, val: true })
    return RegisterService.closeRegister(registerId)
      .then(({ data }) => {
        if (data.status === 'success') {
          commit('SET_REGISTER', data.data.register)
          commit('posErrors/SET_HAS_DID_NOT_CLOSE_ERROR', false, { root: true })
          if (!data.data.netsReconciliationError) {
            dispatch('snackbars/createSnackbar', {
              color: 'success',
              text: 'Kassan har stängts'
            }, { root: true })
          } else {
            dispatch('snackbars/createSnackbar', {
              color: 'warning',
              text: 'Kassan har stängts men dagsavslutet på terminalen misslyckades, försök igen genom terminalmenyn i CampingOnline'
            }, { root: true })
          }
          return true
        }
      })
      .catch((err) => {
        console.log('closeRegister error', err)
        return false
      })
      .finally(() => {
        commit('SET_IS_CLOSING_REGISTED_IDS', { registerId, val: false })
      })
  },
  fetchUnits ({ commit }) {
    commit('SET_IS_FETCHING_UNITS', true)
    commit('SET_UNITS', [])

    RegisterService.fetchRegisters()
      .then(({ data }) => {
        if (data.status === 'success') {
          commit('SET_UNITS', data.data)
        }
      })
      .finally(() => {
        commit('SET_IS_FETCHING_UNITS', false)
      })
  },
  fetchRegister ({ commit, rootState }) {
    commit('SET_REGISTER', null)
    commit('posErrors/SET_HAS_NOT_REGISTERED_ERROR', false, { root: true })
    commit('posErrors/SET_HAS_DID_NOT_CLOSE_ERROR', false, { root: true })

    const workstationId = rootState.workstation?.id

    commit('SET_IS_LOADING_GET_REGISTER', true)
    return RegisterService.getRegister(workstationId)
      .then(({ data }) => {
        if (data.status === 'success') {
          commit('posErrors/SET_HAS_GET_REGISTER_ERROR', false, { root: true })
          commit('posErrors/SET_GET_REGISTER_ERROR_MESSAGE', '', { root: true })
          commit('SET_REGISTER', data.data.register)
          commit('SET_TERMINAL', data.data.nets_terminal)
          if (data.data.register && !data.data.register.is_registered) {
            commit('posErrors/SET_HAS_NOT_REGISTERED_ERROR', true, { root: true })
          }
          if (data.data.register && data.data.register.opened_at && window.dayjs.parseServerDateTime(data.data.register.opened_at).isBefore(window.dayjs.today())) {
            commit('posErrors/SET_HAS_DID_NOT_CLOSE_ERROR', true, { root: true })
          }
        } else {
          commit('posErrors/SET_HAS_GET_REGISTER_ERROR', true, { root: true })
        }
      })
      .catch((err) => {
        console.log('fetchRegister error', err)
        commit('posErrors/SET_HAS_GET_REGISTER_ERROR', true, { root: true })
        commit('posErrors/SET_GET_REGISTER_ERROR_MESSAGE', err.message, { root: true })
      })
      .finally(() => {
        commit('SET_IS_LOADING_GET_REGISTER', false)
      })
  },
  printReport ({ commit }, { registerId, reportId }) {
    reportId = '' + reportId
    if (reportId.toLowerCase() === 'x') {
      commit('SET_IS_PRINTING_X_REPORT', true)
    } else {
      commit('SET_IS_PRINTING_REPORTS', { reportId, val: true })
    }

    RegisterService.getReport(registerId, reportId)
      .then(({ data }) => {
        if (data.status === 'success') {
          // Skickas som print attribut från servern, så utskriften sker automatiskt och hanteras därför inte här
          if (reportId.toLowerCase() === 'x') {
            commit('SET_IS_PRINTING_X_REPORT', false)
          } else {
            commit('SET_IS_PRINTING_REPORTS', { reportId, val: false })
          }
        }
      })
      .finally(() => {
        if (reportId.toLowerCase() === 'x') {
          commit('SET_IS_PRINTING_X_REPORT', false)
        } else {
          commit('SET_IS_PRINTING_REPORTS', { reportId, val: false })
        }
      })
  },
  downloadJournal ({ commit }, { registerId }) {
    commit('SET_IS_LOADING_DOWNLOAD_JOURNAL', true)
    RegisterService.getJournal(registerId)
      .then(({ data }) => {
        const url = window.URL.createObjectURL(new Blob([JSON.stringify(data)]))
        const link = document.createElement('a')
        link.href = url
        const filename = 'journal-' + data.manufacturer_id + '-' + data.to + '.json'
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()
      })
      .finally(() => {
        commit('SET_IS_LOADING_DOWNLOAD_JOURNAL', false)
      })
  },
  openCashDrawer ({ state, commit }, { registerId }) {
    const register = state.units.find(r => r.id === registerId)
    if (!register) {
      return false
    }
    commit('SET_IS_OPENING_CASH_DRAWER', true)
    return RegisterService.openCashDrawer(registerId)
      .then(({ data }) => {
        if (data.status === 'success') {
          window.printer.openDrawer(register.printer_port)
            .finally(() => {
              commit('SET_IS_OPENING_CASH_DRAWER', false)
            })
        } else {
          commit('SET_IS_OPENING_CASH_DRAWER', false)
        }
      })
      .catch(() => {
        commit('SET_IS_OPENING_CASH_DRAWER', false)
      })
  },
  assignThisHostToARegister ({ commit, dispatch, rootState }, { registerId }) {
    commit('SET_IS_LOADING_ASSIGN_HOST', { registerId, val: true })
    const workstationId = rootState.workstation.id

    RegisterService.assignRegister({ registerId, workstationId })
      .then(({ data }) => {
        if (data.status === 'success') {
          commit('SET_REGISTER', data.data.register)
          dispatch('snackbars/createSnackbar', {
            color: 'success',
            text: 'Kassaregistret har tilldelats den här enheten'
          }, { root: true })
          commit('posErrors/SET_HAS_GET_REGISTER_ERROR', false, { root: true })
          commit('posErrors/SET_GET_REGISTER_ERROR_MESSAGE', '', { root: true })
          if (!data.data.register.is_registered) {
            commit('posErrors/SET_HAS_NOT_REGISTERED_ERROR', true, { root: true })
          }
          if (data.data.register.opened_at && window.dayjs.parseServerDateTime(data.data.register.opened_at).isBefore(window.dayjs.today())) {
            commit('posErrors/SET_HAS_DID_NOT_CLOSE_ERROR', true, { root: true })
          }
        }
      })
      .finally(() => {
        commit('SET_IS_LOADING_ASSIGN_HOST', { registerId, val: false })
      })
  },
  unassignHostFromRegister ({ state, commit, dispatch }, { registerId }) {
    commit('SET_IS_LOADING_UNASSIGN_HOST', { registerId, val: true })

    RegisterService.assignRegister({ registerId, workstationId: null })
      .then(({ data }) => {
        if (data.status === 'success') {
          if (data.data.register.id === state.register.id) {
            // Uppdatera state.register om det är samma register, denna uppdaterar units också
            commit('SET_REGISTER', data.data.register)
          } else {
            // Uppdatera units med nya register objektet
            const i = state.units.findIndex(registerUnit => registerUnit.id === data.data.register.id)
            if (i >= 0) {
              state.units.splice(i, 1, data.data.register)
            }
          }
          dispatch('snackbars/createSnackbar', {
            color: 'success',
            text: 'Kassaregistret har kopplats bort från enheten'
          }, { root: true })
        }
      })
      .finally(() => {
        commit('SET_IS_LOADING_UNASSIGN_HOST', { registerId, val: false })
      })
  }
}

export const getters = {
  isCashRegister: function (state) {
    // Om enheten är ett kassaregister eller inte
    return !!state.register
  }
}
