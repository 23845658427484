import API from './API.js'

export default {
  //
  // GET
  //
  getEmailHtml (id) {
    return API.httpClient.get(`email/${id}`)
  }

  //
  // POST
  //
}
