import TerminalService from '@/services/TerminalService.js'
import CustomerCreditService from '../../../services/CustomerCreditService'
import InvoiceService from '../../../services/InvoiceService'
export const namespaced = true

export const state = {
  units: [],
  isFetchingUnits: false,
  terminalDisplayText: null,
  terminalErrorMessage: null,
  terminalErrorPrint: null,
  terminalCancelling: false,
  terminalRemainingTime: null,
  countdownInterval: null,
  isLoadingAssignWorkstationTerminalIds: [],
  isLoadingUnassignWorkstationTerminalIds: []
}

export const mutations = {
  SET_UNITS (state, val) {
    state.units = val
  },
  SET_IS_FETCHING_UNITS (state, val) {
    state.isFetchingUnits = val
  },
  SET_TERMINAL_DISPLAY_TEXT (state, val) {
    state.terminalDisplayText = val
  },
  SET_TERMINAL_ERROR_MESSAGE (state, val) {
    state.terminalErrorMessage = val
  },
  SET_TERMINAL_ERROR_PRINT (state, val) {
    state.terminalErrorPrint = val
  },
  SET_TERMINAL_CANCELLING (state, val) {
    state.terminalCancelling = val
  },

  SET_TERMINAL_REMAINING_TIME (state, val) {
    state.terminalRemainingTime = val
    if (val === null && state.countdownInterval) {
      clearInterval(state.countdownInterval)
      state.countdownInterval = null
    }
  },
  SET_COUNTDOWN_INTERVAL (state, val) {
    if (state.countdownInterval) {
      clearInterval(state.countdownInterval)
    }
    state.countdownInterval = val
  },
  SET_IS_LOADING_ASSIGN_WORKSTATION (state, { terminalId, val }) {
    if (val) {
      const i = state.isLoadingAssignWorkstationTerminalIds.findIndex(id => id === terminalId)
      if (i < 0) {
        state.isLoadingAssignWorkstationTerminalIds.push(terminalId)
      }
    } else {
      const i = state.isLoadingAssignWorkstationTerminalIds.findIndex(id => id === terminalId)
      state.isLoadingAssignWorkstationTerminalIds.splice(i, 1)
    }
  },
  SET_IS_LOADING_UNASSIGN_WORKSTATION (state, { terminalId, val }) {
    if (val) {
      const i = state.isLoadingUnassignWorkstationTerminalIds.findIndex(id => id === terminalId)
      if (i < 0) {
        state.isLoadingUnassignWorkstationTerminalIds.push(terminalId)
      }
    } else {
      const i = state.isLoadingUnassignWorkstationTerminalIds.findIndex(id => id === terminalId)
      state.isLoadingUnassignWorkstationTerminalIds.splice(i, 1)
    }
  }
}

export const actions = {
  initialise ({ dispatch }) {
    // REVIEW: Denna körs aldrig vad jag kan se?
    dispatch('fetchRegister')
  },
  fetchUnits ({ commit }) {
    commit('SET_IS_FETCHING_UNITS', true)
    commit('SET_UNITS', [])

    TerminalService.getTerminals()
      .then(({ data }) => {
        if (data.status === 'success') {
          commit('SET_UNITS', data.data.terminals)
        }
      })
      .finally(() => {
        commit('SET_IS_FETCHING_UNITS', false)
      })
  },
  startTerminalTimeout ({ commit, state, dispatch }, { timeout, receiptId, isCustomerCreditPayment, isInvoicePayment, invoiceDraftId }) {
    if (state.terminalErrorMessage || state.terminalErrorPrint) {
      return
    }

    if (state.terminalRemainingTime !== null) {
      // Finns en pågående nedräkning och vi vill inte starta en ny utan explicit försök
      commit('SET_TERMINAL_ERROR_MESSAGE', 'Inget svar från terminalen efter timeout')
      commit('SET_TERMINAL_ERROR_PRINT', null)
      return
    }

    commit('SET_TERMINAL_REMAINING_TIME', timeout)
    const intervalId = setInterval(() => {
      if (state.terminalRemainingTime <= 1) {
        commit('SET_TERMINAL_REMAINING_TIME', 0)
        commit('SET_COUNTDOWN_INTERVAL', null)

        if (receiptId) {
          dispatch('posCheckout/getReceipt', receiptId, { root: true })
        } else if (isCustomerCreditPayment) {
          dispatch('snackbars/createSnackbar', {
            color: 'error',
            text: 'Ingen kontakt med terminal - Kontrollera saldo'
          }, { root: true })
          // Förbättringskapacitet, stämma av belopp och ha mer feedback, inte värt det nu då detta endast är fallback när pusher inte kommer fram
          commit('invoice/TRIGGER_CUSTOMER_CREDIT_UPDATED', null, { root: true })
          commit('customer/SET_IS_LOADING_CUSTOMER_CREDIT_PAYMENT', false, { root: true })
          dispatch('posTerminal/resetStatusDialog', null, { root: true })
        } else if (isInvoicePayment) {
          // Förbättringskapacitet, stämma av belopp och ha mer feedback, inte värt det nu då detta endast är fallback när pusher inte kommer fram
          commit('invoice/TRIGGER_INVOICE_UPDATED', null, { root: true })
          commit('invoice/TRIGGER_CUSTOMER_CREDIT_UPDATED', null, { root: true })
          dispatch('snackbars/createSnackbar', {
            color: 'error',
            text: 'Ingen kontakt med terminal - Kontrollera fakturan'
          }, { root: true })
          commit('invoice/SET_IS_LOADING_PAYMENT', false, { root: true })
          commit('invoice/SET_IS_LOADING_TERMINAL_PAYMENT', false, { root: true })
          dispatch('posTerminal/resetStatusDialog', null, { root: true })
        } else if (invoiceDraftId) {
          dispatch('invoice/getDraftStatus', invoiceDraftId, { root: true })
        }
      } else {
        commit('SET_TERMINAL_REMAINING_TIME', state.terminalRemainingTime - 1)
      }
    }, 1000)

    commit('SET_COUNTDOWN_INTERVAL', intervalId)
  },
  resetStatusDialog ({ commit }) {
    commit('SET_TERMINAL_DISPLAY_TEXT', null)
    commit('SET_TERMINAL_ERROR_PRINT', null)
    commit('SET_TERMINAL_ERROR_MESSAGE', null)
    commit('SET_TERMINAL_CANCELLING', false)
    commit('SET_TERMINAL_REMAINING_TIME', null)
  },
  cancelReceiptPayment ({ commit }, receiptId) {
    TerminalService.cancelReceiptPayment(receiptId)
      .then(({ data }) => {
        console.log('cancelReceiptPayment', data)
      })
  },
  cancelCustomerCreditPayment ({ commit }) {
    CustomerCreditService.cancelCustomerCreditPayment()
      .then(({ data }) => {
        console.log('cancelCustomerCreditPayment', data)
      })
  },
  cancelInvoicePayment ({ commit }) {
    InvoiceService.cancelInvoicePayment()
      .then(({ data }) => {
        console.log('cancelInvoicePayment', data)
      })
  },
  cancelInvoiceDraftPayment ({ commit }) {
    InvoiceService.cancelInvoiceDraftPayment()
      .then(({ data }) => {
        console.log('cancelInvoiceDraftPayment', data)
      })
  },
  assignThisWorkstationToTerminal ({ commit, dispatch, rootState }, { terminalId, softwareProvider }) {
    commit('SET_IS_LOADING_ASSIGN_WORKSTATION', { terminalId, val: true })
    const workstationId = rootState.workstation.id

    TerminalService.assignTerminal({ terminalId, workstationId, softwareProvider })
      .then(({ data }) => {
        if (data.status === 'success') {
          commit('SET_UNITS', data.data.terminals)
          // betalmetod för integrerad terminal läggs till automatiskt så hämtar om payment methods
          dispatch('posCheckout/fetchPaymentMethods', undefined, { root: true })
          // hämtar om kassaenheterna också, så de visar rätt terminal på sidan enheter
          dispatch('posRegister/fetchUnits', undefined, { root: true })
          dispatch('snackbars/createSnackbar', {
            color: 'success',
            text: 'Terminalen har tilldelats den här enheten'
          }, { root: true })
        }
      })
      .finally(() => {
        commit('SET_IS_LOADING_ASSIGN_WORKSTATION', { terminalId, val: false })
      })
  },
  unassignWorkstationFromTerminal ({ commit, dispatch }, { terminalId, softwareProvider }) {
    commit('SET_IS_LOADING_UNASSIGN_WORKSTATION', { terminalId, val: true })

    TerminalService.assignTerminal({ terminalId, workstationId: null, softwareProvider })
      .then(({ data }) => {
        if (data.status === 'success') {
          commit('SET_UNITS', data.data.terminals)
          // betalmetod för integrerad terminal tas bort automatiskt så hämtar om payment methods
          dispatch('posCheckout/fetchPaymentMethods', undefined, { root: true })
          // hämtar om kassaenheterna också, så de visar rätt terminal på sidan enheter
          dispatch('posRegister/fetchUnits', undefined, { root: true })
          dispatch('snackbars/createSnackbar', {
            color: 'success',
            text: 'Terminalen har kopplats bort från enheten'
          }, { root: true })
        }
      })
      .finally(() => {
        commit('SET_IS_LOADING_UNASSIGN_WORKSTATION', { terminalId, val: false })
      })
  }
}

export const getters = {
  terminalTimeout: (state) => {
    if (!state.terminalRemainingTime) return 0
    return state.terminalRemainingTime
  }
}
