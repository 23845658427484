import ReceiptService from '@/services/ReceiptService.js'

export const namespaced = true

export const state = {
  isLoadingPrintCopyReceiptIds: [],
  dialog: {
    show: false,
    receipt: null,
    isLoading: false,
    errorMessage: ''
  }
}

export const mutations = {
  SET_IS_LOADING_PRINT_COPY (state, payload) {
    if (payload.val) {
      const i = state.isLoadingPrintCopyReceiptIds.findIndex(id => id === payload.receiptId)
      if (i < 0) {
        state.isLoadingPrintCopyReceiptIds.push(payload.receiptId)
      }
    } else {
      const i = state.isLoadingPrintCopyReceiptIds.findIndex(id => id === payload.receiptId)
      state.isLoadingPrintCopyReceiptIds.splice(i, 1)
    }
  },
  SET_SHOW_DIALOG (state, val) {
    state.dialog.show = val
  },
  SET_DIALOG_IS_LOADING (state, val) {
    state.dialog.isLoading = val
  },
  SET_DIALOG_RECEIPT (state, val) {
    state.dialog.receipt = val
  },
  SET_DIALOG_ERROR_MESSAGE (state, val) {
    state.dialog.errorMessage = val
  }
}

export const actions = {
  openDialogById ({ commit }, { receiptId }) {
    commit('SET_DIALOG_IS_LOADING', true)
    commit('SET_DIALOG_RECEIPT', null)
    commit('SET_DIALOG_ERROR_MESSAGE', '')
    commit('SET_SHOW_DIALOG', true)

    ReceiptService.getReceipt(receiptId)
      .then(({ data }) => {
        if (data.status === 'success') {
          window.enrich.enrichReceipt(data.data.receipt)
          commit('SET_DIALOG_RECEIPT', data.data.receipt)
        } else {
          commit('SET_DIALOG_ERROR_MESSAGE', `Kunde inte hitta efterfrågat kvitto. Servern svarade med felkod ${data.errorCode}`)
        }
      })
      .finally(() => {
        commit('SET_DIALOG_IS_LOADING', false)
      })
  },
  async printReceipt ({ dispatch }, { printObject }) {
    dispatch('print/print', printObject, { root: true })
  },
  initCopy ({ rootState, commit, dispatch }, { receiptId }) {
    console.log('initCopy', receiptId)
    commit('SET_IS_LOADING_PRINT_COPY', { receiptId, val: true })
    dispatch('posErrors/checkCleanCash', null, { root: true })
      .then(() => {
        console.log('checkCleanCash then')
        if (rootState.posErrors.hasFatalError) {
          console.error('HAS FATAL ERROR - CAN NOT PROCESS PAYMENT')
          commit('SET_IS_LOADING_PRINT_COPY', { receiptId, val: false })
        } else {
          dispatch('initiateReceiptCopy', { receiptId })
        }
      })
      .catch((error) => {
        console.log('checkCleanCash error', error)
        commit('SET_IS_LOADING_PRINT_COPY', { receiptId, val: false })
      })
  },
  initiateReceiptCopy ({ rootState, commit, dispatch }, { receiptId }) {
    console.log('initiateReceiptCopy', receiptId)
    const payload = {
      registerId: rootState.posRegister.register.id,
      receiptId
    }

    ReceiptService.initiateReceiptCopy(payload)
      .then(({ data }) => {
        if (data.status === 'success') {
          dispatch('posCheckout/handleReceiptResponse', data.data.receipt, { root: true })
        }
      })
      .finally(() => {
        commit('SET_IS_LOADING_PRINT_COPY', { receiptId, val: false })
      })
  }
}
