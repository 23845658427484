<template>
  <v-card class="register-credit d-flex flex-column full-height px-4 justify-start overflow-y-auto" flat>
    <v-btn-toggle
      v-model="creditingMode"
      color="secondary"
      class="pt-1 pb-5 px-4 d-flex full-width"
      mandatory
    >
      <v-btn value="credit-custom" class="flex-1 btn-two-rows">
        Välj vad som ska krediteras
      </v-btn>
      <v-btn value="credit-full-amount" class="flex-1 btn-two-rows">
        Kreditera hela fakturan
      </v-btn>
      <v-btn value="credit-remaining-amount" :disabled="!hasRemainingAmount" class="flex-1 btn-two-rows">
        Kreditera återstående belopp
      </v-btn>
    </v-btn-toggle>

    <v-card class="mb-2">
      <v-simple-table fixed-header height="215">
        <thead>
          <tr>
            <th class="text-left pl-2 pr-0">
              Kreditera
            </th>
            <th class="text-left">
              Produktnamn
            </th>
            <th class="text-right">
              Antal
            </th>
            <th class="text-right">
              Pris
            </th>
            <th class="text-right">
              Summa
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="salesItem in creditSalesItems"
            :key="salesItem.id"
          >
            <td style="width: 60px;" class="pr-0">
              <v-checkbox
                v-model="salesItem.is_selected"
                :disabled="!isEditableSalesItems"
                hide-details
                class="mt-0 pt-0"
                color="secondary"
              ></v-checkbox>
            </td>
            <td style="max-width: 200px;">
              <div :class="{ 'pt-2': !!salesItem.caption }">
                <v-chip
                  :color="salesItem.product.gui_color"
                  dark
                >
                  <div style="text-overflow: ellipsis; width: 100%; overflow: hidden;">{{ salesItem.product.name }}</div>
                </v-chip>
              </div>
              <div v-show="!!salesItem.caption" class="pb-2 pt-1 pl-3 text-caption"><span>{{ salesItem.caption }}</span></div>
            </td>
            <td class="text-right" style="width: 80px; padding-left: 0;">
              {{ salesItem.quantity / 1000 }}
            </td>
            <td class="text-right" style="width: 110px;">
              {{ formatAmount(salesItem.price) }}
            </td>
            <td class="text-right">
              {{ formatAmount(salesItem.quantity / 1000 * salesItem.price) }}
              <!-- Visar hur mycket ursprungliga beloppet är om skiljer sig från valt belopp -->
              <template v-if="salesItem.price * salesItem.quantity != salesItem.original_price * salesItem.original_quantity">
                <div class="text-caption">av {{ formatAmount(salesItem.original_quantity / 1000 * salesItem.original_price) }}</div>
              </template>
            </td>
            <td class="text-right pl-2">
              <div v-if="isEditableSalesItems" class="d-flex">
                <v-btn
                  @click="editSalesItem(salesItem)"
                  :disabled="!isEditableSalesItems"
                  icon
                  small
                >
                  <v-icon small>mdi-pen</v-icon>
                </v-btn>
                <v-btn
                  v-if="(salesItem.price != salesItem.original_price) || (salesItem.quantity != salesItem.original_quantity)"
                  @click="resetSalesItem(salesItem)"
                  icon
                  small
                >
                  <v-icon small>mdi-restore</v-icon>
                </v-btn>
              </div>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-card>

    <v-alert type="info" text class="my-2 py-3">
      <div class="d-flex">
        <div>{{ previewDescription }}</div>
        <div class="ml-auto">
          <v-menu>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                large
                light
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>
                  mdi-magnify
                </v-icon>
              </v-btn>
            </template>
            <v-card class="d-flex">
              <div style="border-right: 1px solid #eee;">
                <div class="py-2 text-center text-subtitle-2">Före kreditering</div>
                <div class="d-flex">
                  <div style="border-right: 1px solid #eee;">
                    <v-list
                      subheader
                      three-line
                    >
                      <v-subheader class="pt-0 text-subtitle-2" style="height: 28px; padding-bottom: 8px;">Originalfaktura</v-subheader>
                      <v-list-item style="min-height: auto;">
                        <v-list-item-content class="pa-0">
                          <v-list-item-title>{{ originalInvoiceAmounts.total }}</v-list-item-title>
                          <v-list-item-subtitle>Belopp</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item style="min-height: auto;">
                        <v-list-item-content class="pa-0 mt-4">
                          <v-list-item-title>{{ originalInvoiceAmounts.paid }}</v-list-item-title>
                          <v-list-item-subtitle>Betalt</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item style="min-height: auto;">
                        <v-list-item-content class="pa-0 mt-4">
                          <v-list-item-title>{{ originalInvoiceAmounts.credited }}</v-list-item-title>
                          <v-list-item-subtitle>Krediterat</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item style="min-height: auto;">
                        <v-list-item-content class="pa-0 mt-4">
                          <v-list-item-title>{{ originalInvoiceAmounts.remaining }}</v-list-item-title>
                          <v-list-item-subtitle>Återstående</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </div>
                  <div>
                    <v-list
                      subheader
                      three-line
                    >
                      <v-subheader class="pt-0 text-subtitle-2" style="height: 28px; padding-bottom: 8px;">Ny kreditfaktura</v-subheader>
                      <v-list-item style="min-height: auto;">
                        <v-list-item-content class="pa-0">
                          <v-list-item-title>{{ creditInvoiceAmounts.total }}</v-list-item-title>
                          <v-list-item-subtitle>Belopp</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item style="min-height: auto;">
                        <v-list-item-content class="pa-0 mt-4">
                          <v-list-item-title>{{ creditInvoiceAmounts.paid }}</v-list-item-title>
                          <v-list-item-subtitle>Betalt</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item style="min-height: auto;">
                        <v-list-item-content class="pa-0 mt-4">
                          <v-list-item-title>{{ creditInvoiceAmounts.credited }}</v-list-item-title>
                          <v-list-item-subtitle>Krediterat</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item style="min-height: auto;">
                        <v-list-item-content class="pa-0 mt-4">
                          <v-list-item-title>{{ creditInvoiceAmounts.remaining }}</v-list-item-title>
                          <v-list-item-subtitle>Återstående</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </div>
                </div>
              </div>

              <div>
                <div class="py-2 text-center text-subtitle-2">Efter kreditering</div>
                <div class="d-flex">
                  <div style="border-right: 1px solid #eee;">
                    <v-list
                      subheader
                      three-line
                    >
                      <v-subheader class="pt-0 text-subtitle-2" style="height: 28px; padding-bottom: 8px;">Originalfaktura</v-subheader>
                      <v-list-item style="min-height: auto;">
                        <v-list-item-content class="pa-0">
                          <v-list-item-title>{{ postCreditOriginalInvoiceAmounts.total }}</v-list-item-title>
                          <v-list-item-subtitle>Belopp</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item style="min-height: auto;">
                        <v-list-item-content class="pa-0 mt-4">
                          <v-list-item-title>{{ postCreditOriginalInvoiceAmounts.paid }}</v-list-item-title>
                          <v-list-item-subtitle>Betalt</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item style="min-height: auto;">
                        <v-list-item-content class="pa-0 mt-4">
                          <v-list-item-title>{{ postCreditOriginalInvoiceAmounts.credited }}</v-list-item-title>
                          <v-list-item-subtitle>Krediterat</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item style="min-height: auto;">
                        <v-list-item-content class="pa-0 mt-4">
                          <v-list-item-title>{{ postCreditOriginalInvoiceAmounts.remaining }}</v-list-item-title>
                          <v-list-item-subtitle>Återstående</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </div>
                  <div>
                    <v-list
                      subheader
                      three-line
                    >
                      <v-subheader class="pt-0 text-subtitle-2" style="height: 28px; padding-bottom: 8px;">Ny kreditfaktura</v-subheader>
                      <v-list-item style="min-height: auto;">
                        <v-list-item-content class="pa-0">
                          <v-list-item-title>{{ postCreditCreditInvoiceAmounts.total }}</v-list-item-title>
                          <v-list-item-subtitle>Belopp</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item style="min-height: auto;">
                        <v-list-item-content class="pa-0 mt-4">
                          <v-list-item-title>{{ postCreditCreditInvoiceAmounts.paid }}</v-list-item-title>
                          <v-list-item-subtitle>Betalt</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item style="min-height: auto;">
                        <v-list-item-content class="pa-0 mt-4">
                          <v-list-item-title>{{ postCreditCreditInvoiceAmounts.credited }}</v-list-item-title>
                          <v-list-item-subtitle>Krediterat</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item style="min-height: auto;">
                        <v-list-item-content class="pa-0 mt-4">
                          <v-list-item-title>{{ postCreditCreditInvoiceAmounts.remaining }}</v-list-item-title>
                          <v-list-item-subtitle>Återstående</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </div>
                </div>
              </div>
            </v-card>
          </v-menu>
        </div>
      </div>
    </v-alert>
    <!-- Före/Efter expansion panel -->
    <!-- TODO: sätt detta i en menu istället, ändra förhandsgranskningen till en förklarande text -->

    <v-card-actions class="mt-auto flex-grow-0 px-0 pb-4">
      <v-spacer></v-spacer>
      <v-btn
        color="success"
        @click="createCreditInvoice"
        :loading="isLoading"
      >
        Skapa kreditfaktura
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'RegisterCredit',
  components: {},
  props: {
    isOpen: Boolean,
    originalInvoice: Object
  },
  data: () => ({
    isStrongValidation: false,
    creditSalesItems: [],
    creditingMode: 'credit-custom'
  }),
  computed: {
    ...mapState(
      {
        isLoadingRegisterCreditInvoiceIds: state => state.invoice.isLoadingRegisterCreditInvoiceIds
      }
    ),
    isLoading: function () {
      return this.originalInvoice && this.isLoadingRegisterCreditInvoiceIds.includes(this.originalInvoice.id)
    },
    invoiceName: function () {
      if (this.isIncomingPayment) {
        // fakturor eller kontantfaktura
        return `Faktura ${this.invoice.invoice_number}`
      } else {
        return `Kreditfaktura ${this.invoice.invoice_number}`
      }
    },
    isEditableSalesItems: function () {
      return this.creditingMode === 'credit-custom'
    },
    hasRemainingAmount: function () {
      return this.originalInvoice && this.originalInvoice.remaining_amount > 0
    },
    creditAmount: function () {
      const amount = this.creditSalesItems.reduce((accumulatedAmount, salesItem) => {
        if (!salesItem.is_selected) {
          return accumulatedAmount
        }
        accumulatedAmount += Math.round(salesItem.quantity / 1000 * salesItem.price)
        return accumulatedAmount
      }, 0)

      // Alltid matchande belopp när hela fakturan krediteras
      if (this.originalInvoice && (amount + this.originalInvoice.rounding) === this.originalInvoice.total_amount) {
        return (-amount - this.originalInvoice.rounding)
      }

      const applicableAmount = this.originalInvoice?.remaining_amount || 0

      if (applicableAmount >= amount) {
        // Om fakturan delkrediteras och kvittas så kvittas exakt belopp om det går
        return -amount
      } else {
        // Kreditfakturan kommer få öresavrundning om den ej kvittas, detta ska inte påverka ursprungsfakturans belopp
        return -(Math.round(amount / 100) * 100)
      }

      // Om beloppet är under 1kr så avrundas det bort i alla fall
    },
    originalInvoiceAmounts: function () {
      if (!this.originalInvoice) {
        return {}
      }
      return {
        total: this.formatAmount(this.originalInvoice.total_amount),
        paid: this.formatAmount(this.originalInvoice.paid_amount),
        credited: this.formatAmount(this.originalInvoice.credited_amount),
        remaining: this.formatAmount(this.originalInvoice.remaining_amount)
      }
    },
    creditInvoiceAmounts: function () {
      return {
        total: this.formatAmount(this.creditAmount),
        paid: this.formatAmount(0),
        credited: this.formatAmount(0),
        remaining: this.formatAmount(this.creditAmount)
      }
    },
    appliedCreditAmount: function () {
      if (!this.originalInvoice) {
        return 0
      }
      // Belopp som kommer krediteras ursprungsfaktura, det lägsta (i absoluta tal) av det krediterade belopp och hur mycket som återstår på fakturan
      return Math.min(Math.abs(this.creditAmount), this.originalInvoice.remaining_amount)
    },
    remainingAmountOriginalInvoiceAfterCredit: function () {
      if (!this.originalInvoice) {
        return 0
      }
      let amount = (this.originalInvoice.remaining_amount - this.appliedCreditAmount)
      if (Math.abs(amount) < 100) {
        amount = 0
      }
      return amount
    },
    postCreditOriginalInvoiceAmounts: function () {
      if (!this.originalInvoice) {
        return {}
      }

      return {
        total: this.formatAmount(this.originalInvoice.total_amount),
        paid: this.formatAmount(this.originalInvoice.paid_amount),
        credited: this.formatAmount(this.originalInvoice.credited_amount + this.appliedCreditAmount),
        remaining: this.formatAmount(this.remainingAmountOriginalInvoiceAfterCredit)
      }
    },
    remainingAmountCreditInvoiceAfterCredit: function () {
      let amount = (this.creditAmount + this.appliedCreditAmount)
      if (Math.abs(amount) < 100) {
        amount = 0
      }
      return amount
    },
    postCreditCreditInvoiceAmounts: function () {
      if (!this.originalInvoice) {
        return {}
      }
      return {
        total: this.formatAmount(this.creditAmount),
        paid: this.formatAmount(0),
        credited: this.formatAmount(-this.appliedCreditAmount),
        remaining: this.formatAmount(this.remainingAmountCreditInvoiceAfterCredit)
      }
    },
    previewDescription: function () {
      if (this.creditAmount === 0) {
        return 'Välj rader att kreditera'
      }
      if (this.creditAmount > 0) {
        return 'Kreditfaktura kan inte ha ett positivt belopp'
      }

      let str = `En kreditfaktura på ${this.formatAmount(Math.abs(this.creditAmount))} kommer att skapas`

      if (this.originalInvoice && this.originalInvoice.remaining_amount > 0) {
        str += ' och kvittas mot ursprungsfakturan.'
      } else {
        str += '.'
      }

      str += ` ${this.formatAmount(this.remainingAmountOriginalInvoiceAfterCredit)} kommer att kvarstå att betala på ursprungsfakturan.`

      str += ` ${this.formatAmount(-this.remainingAmountCreditInvoiceAfterCredit)} kommer att kvarstå som tillgodo på kreditfakturan.`

      return str
    }
  },
  methods: {
    formatAmount: function (amount) {
      return window.conversion.toHumanReadableAmount(amount / 100)
    },
    editSalesItem: function (salesItem) {
      this.editedSalesItem = salesItem
      this.$store.commit('SET_OPEN_GENERIC_INPUT_DIALOG', {
        header: 'Belopp att kreditera',
        prefilledValue: '',
        saveFunction: this.saveSalesItemAmount
      })
    },
    resetSalesItem: function (salesItem) {
      salesItem.quantity = salesItem.original_quantity
      salesItem.price = salesItem.original_price
    },
    resetAllSalesItems: function () {
      this.creditSalesItems.forEach(salesItem => {
        salesItem.quantity = salesItem.original_quantity
        salesItem.price = salesItem.original_price
      })
    },
    saveSalesItemAmount: function (val) {
      if (!val) {
        return false
      }
      // Blir onödigt komplicerat att tillåta dem att redigera både antal och pris, så vi låter dem redigera beloppet och sätter alltid antal till 1 eller -1 istället
      val = val.replace(',', '.')
      const numberVal = Number.parseFloat(val)
      if (Number.isNaN(numberVal)) {
        return false
      }
      this.editedSalesItem.quantity = numberVal < 0 ? -1000 : 1000
      this.editedSalesItem.price = Math.round(Math.abs(numberVal) * 100)
      this.editedSalesItem.is_selected = true
    },
    createCreditSalesItems: function () {
      this.creditSalesItems = []
      if (!this.originalInvoice) {
        return false
      }
      this.originalInvoice.sales_items.forEach(originalSalesItem => {
        this.creditSalesItems.push({
          id: originalSalesItem.id,
          is_selected: false,
          is_accommodation_charge: originalSalesItem.is_accommodation_charge,
          product: Object.assign({}, originalSalesItem.product),
          original_quantity: originalSalesItem.quantity,
          quantity: originalSalesItem.quantity,
          original_price: originalSalesItem.price,
          price: originalSalesItem.price,
          caption: originalSalesItem.caption,
          vat_rate: originalSalesItem.vat_rate
        })
      })
    },
    close: function () {
      this.$emit('close')
    },
    resetForm: function () {
      this.isStrongValidation = false
      this.creditingMode = 'credit-custom'
    },
    validate: function () {
      if (!this.creditSalesItems.some(salesItem => salesItem.is_selected)) {
        this.$store.dispatch('snackbars/createSnackbar', {
          color: 'error',
          text: 'Välj vilka rader på fakturan du vill kreditera'
        })
        return false
      }
      if (this.creditAmount >= 0) {
        this.$store.dispatch('snackbars/createSnackbar', {
          color: 'error',
          text: 'Den resulterande kreditfakturan måste ha ett negativt belopp'
        })
        return false
      }
      if (this.creditSalesItems.some(salesItem => salesItem.price <= 0)) {
        this.$store.dispatch('snackbars/createSnackbar', {
          color: 'error',
          text: 'Negativa priser eller 0 är inte tillåtet'
        })
        return false
      }
      return true
    },
    createCreditInvoice: function () {
      this.isStrongValidation = true
      const isValid = this.validate()
      if (!isValid) {
        return false
      }

      const originalInvoiceId = this.originalInvoice.id
      const creditSalesItems = this.creditSalesItems.filter(salesItem => salesItem.is_selected).map(salesItem => {
        return {
          product_id: salesItem.product.id,
          quantity: salesItem.quantity,
          price: salesItem.price,
          is_accommodation_charge: salesItem.is_accommodation_charge
        }
      })

      this.$store.dispatch('invoice/createCreditInvoice', {
        creditSalesItems,
        originalInvoiceId
      })
        .then(() => {
          this.close()
          this.resetForm()
        })
    },
    selectSalesItemsRemainingAmount: function () {
      // Unselectar alla först
      this.creditSalesItems.forEach(salesItem => {
        salesItem.is_selected = false
      })

      const remainingAmount = this.originalInvoice.remaining_amount
      if (remainingAmount <= 0) {
        // Fakturan fullbetalt, inget återstående att kreditera
        return true
      }

      // I första hand, om finns en sales item med samma belopp som återstående så väljer vi att kreditera den, sannolikt den som inte är betald
      const salesItemWithRemainingAmount = this.creditSalesItems.find(salesItem => {
        return Math.round(salesItem.quantity / 1000 * salesItem.price) === remainingAmount
      })
      if (salesItemWithRemainingAmount) {
        salesItemWithRemainingAmount.is_selected = true
        return true
      }

      // I andra hand så fördelar vi återstående belopp att kreditera jämnt på alla sales items
      let remainingAmountToDistribute = remainingAmount
      const distributionPercent = remainingAmount / this.originalInvoice.total_amount
      for (let i = 0; i < this.creditSalesItems.length; i++) {
        const salesItem = this.creditSalesItems[i]
        const salesItemAmount = Math.round(salesItem.quantity / 1000 * salesItem.price)
        const salesItemAmountToDistribute = Math.round(salesItemAmount * distributionPercent)
        remainingAmountToDistribute -= salesItemAmountToDistribute
        salesItem.is_selected = true
        salesItem.price = Math.abs(salesItemAmountToDistribute)
        salesItem.quantity = salesItemAmountToDistribute >= 0 ? 1000 : -1000
        if (i === this.creditSalesItems.length - 1) {
          // Sista sales item får resterande belopp
          salesItem.price += remainingAmountToDistribute
        }
      }
    }
  },
  watch: {
    creditingMode: function (newVal, oldVal) {
      this.resetAllSalesItems() // Återställer kvantitet och pris på alla sales items
      if (newVal === 'credit-full-amount') {
        this.creditSalesItems.forEach(salesItem => {
          salesItem.is_selected = true
        })
      } else if (newVal === 'credit-remaining-amount') {
        this.selectSalesItemsRemainingAmount() // Väljer sales items som motsvarar återstående belopp
      } else if (newVal === 'credit-custom') {
        this.creditSalesItems.forEach(salesItem => {
          salesItem.is_selected = false
        })
      }
    },
    isOpen: function () {
      if (this.isOpen) {
        // Skapar creditSalesItems utifrån originalInvoices sales_items
        this.createCreditSalesItems()
      } else {
        // återställer formuläret när componenten stängs
        this.resetForm()
      }
    }
  }
}
</script>
