<template>
  <v-dialog
    v-model="show"
    max-width="420"
  >
    <v-card>
      <v-card-title v-show="!!genericInputDialog.header" class="text-h5" style="word-break: keep-all;">
        {{ genericInputDialog.header }}
      </v-card-title>
      <v-card-text>
        <v-form @submit.prevent="save">
          <v-text-field
            v-model="inputValue"
            outlined
            hide-details
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="error"
          text
          @click="show = false"
        >
          Avbryt
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="success"
          text
          @click="save"
        >
          <span>
            Spara
          </span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'GenericInputDialog',
  data: () => ({
    inputValue: ''
  }),
  computed: {
    ...mapState(
      {
        genericInputDialog: state => state.genericInputDialog
      }
    ),
    show: {
      get () {
        return this.genericInputDialog.show
      },
      set (value) {
        if (!value) {
          this.$store.commit('SET_CLOSE_GENERIC_INPUT_DIALOG')
        }
      }
    }
  },
  methods: {
    save: function () {
      if ((typeof this.genericInputDialog.saveFunction) === 'function') {
        this.genericInputDialog.saveFunction(this.inputValue)
        this.show = false
      } else {
        this.$store.dispatch(this.genericInputDialog.saveFunction, this.inputValue)
        this.show = false
      }
    }
  },
  watch: {
    show: function () {
      if (this.show) {
        this.inputValue = this.genericInputDialog.prefilledValue
      }
    }
  }
}
</script>
