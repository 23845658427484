<template>
<div>
  <v-data-table
    :headers="headers"
    :items="tableRows"
    item-key="client_id"
    fixed-header
    disable-pagination
    hide-default-footer
    :disable-sort="isMobile || disableSort"
    class="charges-table"
  >
    <template v-slot:no-data>
      <template v-if="loading">
        <v-simple-table class="skeleton-loader-table">
          <tr v-for="i in 2" :key="i">
            <td v-for="(header, x) in headers" :key="x" :class="{ 'text-left': header.align === 'start', 'text-right': header.align === 'end' }">
              <v-skeleton-loader
                style=""
                type="table-cell"
                class=""
              ></v-skeleton-loader>
            </td>
          </tr>
        </v-simple-table>
      </template>
      <template v-else>
        <v-alert
          dense
          text
          color="info"
          class="ma-10"
        >
          Det finns inga avgifter att visa
        </v-alert>
      </template>
    </template>

    <template v-slot:[`item.move_left`]="{ item }">
      <div class="d-flex align-center justify-start">
        <v-btn
          @click="moveCharge({ chargeId: item.id, direction: 'left', quantity: 'all' })"
          fab
          small
          color="error"
          class="mr-2"
          elevation="3"
        >
          <v-icon>mdi-transfer-left</v-icon>
        </v-btn>
        <v-btn
          @click="moveCharge({ chargeId: item.id, direction: 'left', quantity: 'one' })"
          fab
          small
          color="error"
          class="mr-2"
          elevation="3"
        >
          <v-icon>coi-one-left</v-icon>
        </v-btn>
        <v-btn
          @click="moveCharge({ chargeId: item.id, direction: 'left', quantity: 'x' })"
          fab
          small
          color="error"
          elevation="3"
        >
          <v-icon>coi-x-left</v-icon>
        </v-btn>
      </div>
    </template>

    <template v-slot:[`item.name`]="{ item }">
      <div :class="{ 'pt-2': !!item.note }">
        <v-chip
          :color="item.guiColor"
          dark
        >
          <v-icon v-if="item.isTallyCharge" class="mr-2">coi-access-card</v-icon>
          {{ item.name }}
        </v-chip>
      </div>
      <div v-show="!!item.note" class="pb-2 pt-1 pl-3 text-caption"><span>{{ item.note }}</span></div>
    </template>

    <template v-slot:[`item.price`]="{ item }">
      <template v-if="item.discountType === null || item.original_price === 0">
        {{ item.priceFormatted }}
      </template>
      <template v-else-if="item.discountType === 'fixed'">
        <div>
          <span class="text-decoration-line-through">
            {{ item.originalPriceFormatted }}
          </span>
          <span class="red--text ml-2">
            {{ item.priceFormatted }}
          </span>
        </div>
        <div>
          <i>Rabatt: {{ item.fixedPriceDiscountValueFormatted }}</i>
        </div>
      </template>
      <template v-else-if="item.discountType === 'flat'">
        <div>
          <span class="text-decoration-line-through">
            {{ item.originalPriceFormatted }}
          </span>
          <span class="red--text ml-2">
            {{ item.priceFormatted }}
          </span>
        </div>
        <div>
          <i>Rabatt: {{ item.flatPriceDiscountValueFormatted }}</i>
        </div>
      </template>
      <template v-else-if="item.discountType === 'percent'">
        <div>
          <span class="text-decoration-line-through">
            {{ item.originalPriceFormatted }}
          </span>
          <span class="red--text ml-2">
            {{ item.priceFormatted }}
          </span>
        </div>
        <div>
          <i>Rabatt: {{ item.discountValue / 100 }}%</i>
        </div>
      </template>
    </template>

    <template v-slot:[`item.status`]="{ item }">
      <div class="d-flex align-center justify-end">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <v-icon v-show="item.status === 'paid'" color="success">mdi-cash-check</v-icon>
              <v-icon v-show="item.status === 'invoiced'" color="success">mdi-file-document-check</v-icon>
              <v-icon v-show="item.status === 'parked'" color="primary">mdi-cart-outline</v-icon>
            </div>
          </template>
          <div>{{ item.statusFormatted }}</div>
        </v-tooltip>
      </div>
    </template>

    <template v-slot:[`item.move_right`]="{ item }">
      <div class="d-flex align-center justify-end">
        <v-btn
          @click="moveCharge({ chargeId: item.id, direction: 'right', quantity: 'x' })"
          fab
          small
          color="success"
          class="mr-2"
          elevation="3"
        >
          <v-icon>coi-x-right</v-icon>
        </v-btn>
        <v-btn
          @click="moveCharge({ chargeId: item.id, direction: 'right', quantity: 'one' })"
          fab
          small
          color="success"
          class="mr-2"
          elevation="3"
        >
          <v-icon>coi-one-right</v-icon>
        </v-btn>
        <v-btn
          @click="moveCharge({ chargeId: item.id, direction: 'right', quantity: 'all' })"
          fab
          small
          color="success"
          elevation="3"
        >
          <v-icon>mdi-transfer-right</v-icon>
        </v-btn>
      </div>
    </template>
  </v-data-table>
</div>
</template>

<script>
export default {
  name: 'ChargesTable',
  props: {
    loading: Boolean,
    charges: Array,
    disableSort: Boolean,
    displayedColumns: {
      type: Array,
      default: () => [
        'created_at',
        'product_name',
        'quantity',
        'price',
        'sum',
        'status'
      ]
    }
  },
  data: () => ({}),
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mdAndDown
    },
    tableRows: function () {
      const arr = []
      this.charges.forEach(c => {
        arr.push({
          id: c.id,
          client_id: c.client_id,
          name: c.product.name,
          note: c.note,
          productId: c.product.id,
          discountType: c.discount_type,
          discountValue: c.discount_value,
          fixedPriceDiscountValue: c.discount_type === 'fixed' ? c.original_price - c.price : null,
          fixedPriceDiscountValueFormatted: c.discount_type === 'fixed' ? window.conversion.toHumanReadableAmount((c.original_price - c.price) / 100) : null,
          flatPriceDiscountValue: c.discount_type === 'flat' ? c.discount_value : null,
          flatPriceDiscountValueFormatted: c.discount_type === 'flat' ? window.conversion.toHumanReadableAmount(c.discount_value / 100) : null,
          guiColor: c.product.gui_color,
          quantity: c.quantity / 1000,
          price: c.price / 100,
          priceFormatted: window.conversion.toHumanReadableAmount(c.price / 100),
          original_price: c.original_price / 100,
          originalPriceFormatted: window.conversion.toHumanReadableAmount(c.original_price / 100),
          sum: (c.quantity * c.price) / (1000 * 100),
          sumFormatted: window.conversion.toHumanReadableAmount((c.quantity * c.price) / (1000 * 100)),
          createdAt: c.created_at ? c.created_at.toHumanReadableDate() : 'NY',
          isTallyCharge: c.is_tally_charge,
          status: c.status,
          statusFormatted: c.status === 'invoiced' ? 'Fakturerad' : c.status === 'paid' ? 'Betald' : c.status === 'parked' ? 'I varukorgen' : ''
        })
      })
      return arr
    },
    headers: function () {
      const hasSavedCharges = this.charges.filter(c => { return c.id !== 0 }).length > 0

      const headers = []
      if (this.displayedColumns.includes('move_left')) {
        headers.push({ text: 'Flytta', value: 'move_left', align: 'start' })
      }
      if (this.displayedColumns.includes('created_at') && hasSavedCharges) {
        headers.push({ text: 'Sparad', value: 'createdAt', align: 'start' })
      }
      if (this.displayedColumns.includes('product_name')) {
        headers.push({ text: 'Vara', value: 'name', align: 'start' })
      }
      if (this.displayedColumns.includes('quantity')) {
        headers.push({ text: 'Antal', value: 'quantity', align: 'end' })
      }
      if (this.displayedColumns.includes('price')) {
        headers.push({ text: 'Pris', value: 'price', align: 'end' })
      }
      if (this.displayedColumns.includes('sum')) {
        headers.push({ text: 'Summa', value: 'sumFormatted', align: 'end' })
      }
      if (this.displayedColumns.includes('status')) {
        headers.push({ text: 'Status', value: 'status', align: 'end' })
      }
      if (this.displayedColumns.includes('move_right')) {
        headers.push({ text: 'Flytta', value: 'move_right', align: 'end' })
      }

      return headers
    }
  },
  methods: {
    moveCharge: function ({ chargeId, direction, quantity }) {
      this.$emit('move-charge', { chargeId, direction, quantity })
    }
  },
  watch: {}
}
</script>
