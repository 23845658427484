<template>
  <v-dialog
    max-width="450"
    v-model="show"
    @click:outside="handleAction('archive')"
  >
    <v-card>
      <v-card-title class="text-h5">
        <span class="text-h5">Kvittoleverans</span>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text style="min-height: 300px;">
        <template v-if="!isProcessingDelivery">
        <div class="d-flex justify-center mb-6">
          <v-btn
            class="mx-4 elevation-2"
            height="100"
            width="100"
            color="white"
            @click="handleAction('print')"
          >
            <div class="d-flex flex-column align-center">
              <v-icon size="48" class="mb-2" color="grey darken-1">mdi-printer</v-icon>
              <span>Skriv ut</span>
            </div>
          </v-btn>
          <v-btn
            class="mx-4 elevation-2"
            height="100"
            width="100"
            color="white"
            @click="handleAction('archive')"
          >
            <div class="d-flex flex-column align-center">
              <v-icon size="48" class="mb-2" color="grey darken-1">mdi-archive</v-icon>
              <span>Arkivera</span>
            </div>
          </v-btn>
        </div>

        <v-divider class="my-4"></v-divider>

        <div class="text-subtitle-1 mb-4 text-center">eller skicka till</div>

        <v-row align="center" no-gutters>
          <v-col>
            <v-text-field
              v-model="emailAddress"
              label="E-postadress"
              :rules="emailRules"
              prepend-icon="mdi-at"
              clearable
              hide-details="auto"
              style="min-height: 80px;"
            ></v-text-field>
          </v-col>
          <v-col cols="auto" class="ml-2">
            <v-btn
              color="primary"
              icon
              :disabled="!isEmailValid"
              @click="handleAction('digital')"
            >
              <v-icon>mdi-send</v-icon>
            </v-btn>
          </v-col>
          </v-row>
        </template>
        <div v-else class="d-flex align-center justify-center" style="height: 300px;">
          <v-progress-circular
            indeterminate
            size="90"
            color="primary"
          ></v-progress-circular>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ReceiptDeliveryDialog',
  data: () => ({
    option: null,
    emailAddress: null,
    emailRules: [
      v => /.+@.+\..+/.test(v) || 'Ogiltig e-postadress'
    ]
  }),
  computed: {
    ...mapState(
      {
        activeReceipt: state => state.posCheckout.activeReceipt,
        isProcessingDelivery: state => state.posCheckout.isProcessingDelivery
      }
    ),
    show: {
      get () {
        return this.$store.state.posCheckout.showReceiptDeliveryDialog
      },
      set (value) {
        this.$store.commit('posCheckout/SET_SHOW_RECEIPT_DELIVERY_DIALOG', value)
      }
    },
    isEmailValid () {
      return this.emailRules.every(rule => rule(this.emailAddress) === true)
    }
  },
  mounted () {},
  methods: {
    handleAction (option) {
      this.option = option
      if (option === 'digital' && !this.isEmailValid) return
      this.$store.dispatch('posCheckout/confirmDelivery', {
        receiptForm: option,
        email: option === 'digital' ? this.emailAddress : null
      })
    }
  },
  watch: {
    activeReceipt (val) {
      if (val?.suggested_destination) {
        this.emailAddress = val.suggested_destination
      }
    }
  }

}
</script>
