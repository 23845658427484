<template>
  <v-dialog
    v-model="show"
    :width="isMobile ? '' : isShowMap ? `${mapCardWidth + 700}px` : '900px'"
    :fullscreen="isMobile"
    transition="slide-y-transition"
    :persistent="isShowCustomerForm"
  >
    <div class="booking-form d-flex align-stretch">
      <div style="position: relative;" class="booking-form-inner-container">
        <v-card style="overflow-x: hidden;" class="d-flex flex-column height-100" :width="isMobile ? '' : isShowMap ? '700px' : '900px'" tile>
          <v-toolbar class="flex-grow-0" flat>
            <v-toolbar-title class="headline">{{ dialogHeader }}</v-toolbar-title>
            <v-spacer></v-spacer>

            <template v-if="(!isExistingBooking || !!booking.confirmed_at) && !isSeasonBooking">
              <v-btn text color="primary" small class="mr-4" style="margin-top: 2px;" @click="setBookingType('season')">Säsongsbokning?</v-btn>
            </template>
            <template v-else-if="(!isExistingBooking || !!booking.confirmed_at) && isSeasonBooking">
              <v-btn text color="primary" small class="mr-4" style="margin-top: 2px;" @click="setBookingType('regular')">Vanlig bokning?</v-btn>
            </template>

            <v-menu
              v-model="isShowSettingsMenu"
              :close-on-content-click="false"
              offset-x
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>
                    mdi-tune
                  </v-icon>
                </v-btn>
              </template>
              <v-card class="py-2" max-width="400">
                <v-list>
                  <v-list-item :disabled="isMobile">
                    <v-list-item-action>
                      <v-switch v-model="settingAlwaysShowMap"></v-switch>
                    </v-list-item-action>
                    <v-list-item-title style="white-space: unset;">Visa kartan alltid</v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-action>
                      <v-switch v-model="settingAlwaysSendConfirmationEmail"></v-switch>
                    </v-list-item-action>
                    <v-list-item-title style="white-space: unset;">Skicka bokningsbekräftelse automatiskt på bokningar som inte checkar in idag</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-menu>
            <v-btn
              icon
              class="d-flex d-lg-none"
              @click="show = false"
            >
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
          </v-toolbar>

          <v-card-text class="flex-grow-1 pb-0">
            <v-container>
              <v-row class="">
                <v-col cols="12" lg="8">
                  <customer-selector
                    @set-customer="setCustomer"
                    :preselected-customer="preselectedCustomer"
                    :validation="customerValidation"
                    outlined
                    :is-mobile="isMobile"
                    @open-customer-form="openCustomerForm"
                  ></customer-selector>
                </v-col>
                <v-col cols="12" lg="4">
                  <guest-picker
                    :max-guests="maxGuests"
                    :max-adults="maxAdults"
                    :max-children="maxChildren"
                    :adults="booking && booking.adults"
                    :children="booking && booking.children"
                    :dogs="booking && booking.dogs"
                    @set-adults="setAdults"
                    @set-children="setChildren"
                    @set-dogs="setDogs"
                  ></guest-picker>
                </v-col>
              </v-row>

              <v-row class="">
                <v-col v-if="isSeasonBooking" cols="12" lg="4" class="">
                  <season-picker
                    outlined
                    :selected-season-id="booking.season?.id"
                    :from-year="booking.checkIn"
                    @set-season="setSeason"
                  ></season-picker>
                </v-col>
                <v-col cols="12" lg="8">
                  <date-range-picker
                    title="In- och utcheckningsdatum"
                    prompt-title-first-date="Välj incheckningsdatum"
                    prompt-title-second-date="Välj utcheckningsdatum"
                    :first-date="booking && booking.checkIn"
                    :second-date="booking && booking.checkOut"
                    :is-mobile="isMobile"
                    :validation="{ hasError: false, errorText: '' }"
                    allow-all-dates
                    :availability="[]"
                    :months-loading="[]"
                    @set-first-date="setFirstDate"
                    @set-second-date="setSecondDate"
                  ></date-range-picker>
                  <common-dates-selection
                    v-show="!isSeasonBooking"
                    :check-in-date="booking && booking.checkIn"
                    :check-out-date="booking && booking.checkOut"
                    @set-dates="setDates"
                  ></common-dates-selection>
                </v-col>
                <v-col v-show="!isSeasonBooking" cols="12" lg="4" class="">
                  <arrival-time-picker
                    @set-time="setArrivalTime"
                    :selected-time="booking && booking.expected_arrival_time"
                  ></arrival-time-picker>
                </v-col>
              </v-row>

              <v-row class="pt-5">
                <v-col cols="12">
                  <category-picker
                    :selected-category="categoryPickerValue"
                    @set-category="setCategory"
                  ></category-picker>
                </v-col>
                <v-col cols="12" :lg="showLodgingTypePicker ? 8 : 12" class="pt-0">
                  <position-picker
                    outlined
                    :mandatory="isSeasonBooking"
                    :show-map-icon="showMapButton"
                    :map-is-open="isShowMap"
                    @map-icon-clicked="toggleMap"
                    :selected-position="booking && booking.position"
                    :selected-category="categoryPickerValue"
                    :check-in="booking && booking.checkIn"
                    :check-out="booking && booking.checkOut"
                    :occupancy="booking && (booking.adults + booking.children)"
                    :dogs="booking && booking.dogs"
                    :existing-booking-id="booking && booking.id"
                    @set-position="setPositionAndZoom"
                    @set-validation="setPositionValidation"
                  ></position-picker>
                </v-col>
                <v-col v-if="showLodgingTypePicker" cols="12" lg="4" class="pt-0">
                  <lodging-type-picker
                    outlined
                    :selected-lodging-type="booking.lodgingType"
                    :lodging-types="booking.category.lodgingTypes"
                    @set-lodging-type="setLodgingType"
                  ></lodging-type-picker>
                </v-col>
              </v-row>
              <v-row v-show="showCancellationPolicyPicker" class="">
                <v-col class="pt-0">
                  <cancellation-policy-picker
                    :policies="availableCancellationPolicies"
                    :selected-policy="booking?.cancellationPolicy"
                    :check-in-date="booking?.checkIn"
                    :booking-id="isExistingBooking ? null: booking?.id"
                    :disabled="isExistingBooking"
                    :is-open="show"
                    @set-policy="setCancellationPolicy"
                  ></cancellation-policy-picker><!-- När redigerar bokning så ska inte policy-pickern veta booking id, så den utgår från policy och checkInDate istället för bookingId i timeline -->
                </v-col>
              </v-row>
              <v-row v-show="isSeasonBooking" class="">
                <v-col class="pt-0">
                  <accommodation-price-input
                    v-if="!!booking"
                    v-model="booking.accommodation_price"
                    @input="accommodationPriceIsEditedByUser = true"
                  ></accommodation-price-input>
                </v-col>
              </v-row>
              <v-row v-if="showBookingFeeSwitch || showPositionReservationFeeSwitch">
                <v-col class="pb-0 pt-2">
                  <div>
                    <span class="subheading ml-3">Avgifter</span>
                    <div class="d-flex">
                      <template v-if="showBookingFeeSwitch">
                        <v-switch
                          @change="selectedBookingFee"
                          v-model="bookingFee.isSelected"
                          :label="`${bookingFee.name} (${bookingFee.price / 100}kr)`"
                          hide-details
                          class="mt-1 mr-6 booking-fee-switch flex-grow-0 flex-shrink-1"
                          color="secondary"
                          style="width: auto;"
                        ></v-switch>
                      </template>
                      <template v-if="showPositionReservationFeeSwitch">
                        <v-switch
                          @change="selectedPositionReservationFee"
                          v-model="positionReservationFee.isSelected"
                          :label="`${positionReservationFee.name} (${positionReservationFee.price / 100}kr)`"
                          hide-details
                          class="mt-1 booking-fee-switch flex-grow-0 flex-shrink-1"
                          color="secondary"
                          style="width: auto;"
                        ></v-switch>
                      </template>
                    </div>
                  </div>
                </v-col>
              </v-row>
              <v-row v-if="showElectricityFeeSwitch">
                <v-col class="pb-0">
                  <div>
                    <v-switch
                      v-model="hasSelectedElectricity"
                      label="Lägg till elektricitet"
                      hide-details
                      class="mt-1 booking-fee-switch flex-grow-0 flex-shrink-1"
                      color="secondary"
                      style="width: auto;"
                    ></v-switch>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions class="flex-grow-0 mt-auto" :class="{ 'd-block': isMobile }">
            <booking-form-price
              v-if="!isSeasonBooking"
              :selected-rebooking-option="selectedRebookingOption"
              @select-rebooking-option="setSelectedRebookingOption"
              :is-existing-booking="isExistingBooking"
              :loading="isFetchingCharges || isFetchingRebookingPreview"
              :charges="booking?.charges"
              :rebooking-preview="rebookingPreview"
              :is-mobile="isMobile"
              :has-error="hasRebookingPreviewError"
            ></booking-form-price>
            <v-spacer></v-spacer>
            <v-btn
              v-if="isDropInBooking && !isMobile"
              text
              color="success"
              :large="isMobile"
              @click="saveBooking(true)"
              :loading="isSaving && shouldCheckinAfterSave"
              :disabled="(positionValidation && positionValidation.hasError) || isFetchingCharges || isFetchingRebookingPreview || (isSaving && !shouldCheckinAfterSave)"
            >
              Spara och checka in
            </v-btn>
            <v-btn
              color="success"
              :class="{ 'ma-2': !isMobile, 'ma-0 mt-4': isMobile }"
              :block="isMobile"
              :large="isMobile"
              @click="saveBooking(false)"
              :loading="isSaving && !shouldCheckinAfterSave"
              :disabled="(positionValidation && positionValidation.hasError) || isFetchingCharges || isFetchingRebookingPreview || (isSaving && shouldCheckinAfterSave)"
            >
              {{ saveBookingButtonText }}
            </v-btn>
          </v-card-actions>
        </v-card>

        <div v-if="editedCustomer" class="d-flex flex-column overflow-hidden" style="position: absolute; height: 100%; width: 100%; top: 0; left: 0; pointer-events: none; z-index: 2;">
          <v-fade-transition>
            <div @click="closeCustomerForm" :class="{ 'd-none': true, 'd-md-block': isShowCustomerForm }" style="height: 64px; opacity: 0.23; background-color: rgb(33, 33, 33); pointer-events: auto;"></div>
          </v-fade-transition>
          <v-scroll-y-reverse-transition>
            <div v-show="isShowCustomerForm" class="flex-grow-1">
              <v-card class="customer-form-card full-height d-flex flex-column" :width="isShowMap ? '700px' : '900px'" light style="pointer-events: auto;">
                <v-toolbar class="flex-shrink-1 flex-grow-0" flat>
                  <div class="d-flex">
                    <v-toolbar-title class="headline">{{ customerFormTitle }}</v-toolbar-title>
                  </div>
                  <div v-show="showPrintRegistrationCardButton" class="ml-4">
                    <print-registration-card :customer="editedCustomer"></print-registration-card>
                  </div>

                  <v-spacer></v-spacer>

                  <template v-if="editedCustomer.id === 0 && !editedCustomer.is_org">
                    <v-btn text color="primary" small class="mr-4" style="margin-top: 2px;" @click="editedCustomer.is_org = true">Företagskund?</v-btn>
                  </template>
                  <template v-else-if="editedCustomer.id === 0 && editedCustomer.is_org">
                    <v-btn text color="primary" small class="mr-4" style="margin-top: 2px;" @click="editedCustomer.is_org = false">Privatperson?</v-btn>
                  </template>
                  <v-btn icon @click="closeCustomerForm">
                    <v-icon>
                      mdi-close
                    </v-icon>
                  </v-btn>
                </v-toolbar>
                <div class="flex-grow-1 px-7 pt-3">
                  <customer-form
                    v-model="editedCustomer"
                    savable
                    @customer-saved="customerSaved"
                    @close="closeCustomerForm"
                    @set-validation="setCustomerValidation"
                    :force-validation="forceCustomerValidation"
                    :force-reset-validation="forceResetCustomerValidation"
                    :is-mobile="isMobile"
                  ></customer-form>
                </div>
              </v-card>
            </div>
          </v-scroll-y-reverse-transition>
        </div>
      </div>
      <v-card
        v-if="!!booking && (settingAlwaysShowMap || isShowMap)"
        tile
        :width="mapCardWidth"
        style="overflow: hidden;"
        height="100%"
      >
        <v-btn
          v-if="!settingAlwaysShowMap"
          color="rgba(100, 100, 100, 0.3)"
          elevation="0"
          fab
          absolute
          style="right: 0;"
          @click="closeMap"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <position-map
          ref="bookingMap"
          height="730px"
          position-text-option="position-name"
          :selected-category-id="categoryPickerValue?.id"
          :selected-position="booking.position"
          :check-in="booking.checkIn"
          :check-out="booking.checkOut"
          :occupancy="(booking.adults + booking.children)"
          :dogs="booking.dogs"
          always-show-category-availability
          show-availability
          :booking-id="booking.id"
          :is-loading-availability="isLoadingAvailability"
          :has-availability-error="hasAvailabilityError"
          @select-position="setPosition"
          @unselect-position="setPosition(null)"
          @set-map-image-aspect-ratio="setMapImageAspectRatio"
        ></position-map>
      </v-card>
    </div>
  </v-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import CustomerSelector from '@/components/customer/Selector.vue'
import CustomerForm from '@/components/customer/Form.vue'
import PrintRegistrationCard from '@/components/customer/PrintRegistrationCard.vue'
import DateRangePicker from '@/components/common/DateRangePicker.vue'
import CommonDatesSelection from '@/components/common/CommonDatesSelection.vue'
import CategoryPicker from '@/components/position/CategoryPicker.vue'
import PositionPicker from '@/components/position/PositionPicker.vue'
import LodgingTypePicker from '@/components/position/LodgingTypePicker.vue'
import GuestPicker from '@/components/booking/GuestPicker.vue'
import ArrivalTimePicker from '@/components/booking/ArrivalTimePicker.vue'
import CancellationPolicyPicker from '@/components/cancellation-policy/CancellationPolicyPicker.vue'
import PositionMap from '@/components/position/Map.vue'
import SeasonPicker from '@/components/booking/SeasonPicker.vue'
import AccommodationPriceInput from '@/components/booking/AccommodationPriceInput.vue'
import BookingFormPrice from '@/components/booking/BookingFormPrice.vue'

import BookingService from '@/services/BookingService.js'
import RateService from '@/services/RateService.js'

export default {
  name: 'BookingForm',
  components: {
    CustomerSelector,
    CustomerForm,
    PrintRegistrationCard,
    DateRangePicker,
    CommonDatesSelection,
    CategoryPicker,
    PositionPicker,
    LodgingTypePicker,
    GuestPicker,
    ArrivalTimePicker,
    CancellationPolicyPicker,
    PositionMap,
    SeasonPicker,
    AccommodationPriceInput,
    BookingFormPrice
  },
  props: {},
  data: () => ({
    isShowMap: false,
    mapImageAspectRatio: 1,
    isFetchingCharges: false,
    isFetchingRebookingPreview: false,
    isShowSettingsMenu: false,
    positionValidation: null,
    customerValidation: {
      hasChanges: false,
      isLoading: false,
      isValidated: false,
      hasError: false,
      hasWarning: false,
      errorMessage: ''
    },
    isShowCustomerForm: false,
    forceCustomerValidation: 0,
    forceResetCustomerValidation: 0,
    editedCustomer: null,
    isSaving: false,
    shouldCheckinAfterSave: false,
    bookingFee: null,
    positionReservationFee: null,
    hasSelectedElectricity: false,
    categoryPickerValue: null,
    accommodationPriceIsEditedByUser: false,
    hasRebookingPreviewError: false,
    rebookingPreview: null,
    selectedRebookingOption: null
  }),
  computed: {
    ...mapState(
      {
        fetchingAvailabilities: state => state.position.fetchingAvailabilities,
        availabilitiesErrors: state => state.position.availabilitiesErrors,
        defaultPrinter: state => state.workstation.default_printer
      }
    ),
    ...mapGetters(
      {
        customerTemplate: 'customer/customerTemplate',
        activeLodgingTypes: 'position/activeLodgingTypes',
        activeCategories: 'position/activeCategories'
      }
    ),
    booking: {
      get: function () {
        return this.$store.state.booking.draft
      },
      set: function (booking) {
        this.$store.commit('booking/SET_DRAFT', booking)
      }
    },
    show: {
      get: function () {
        return this.$store.state.booking.formDialog.show
      },
      set: function (value) {
        this.$store.commit('booking/SET_SHOW_FORM_DIALOG', value)
      }
    },
    settingAlwaysShowMap: {
      get: function () {
        return this.$store.state.localSettings.bookingFormAlwaysShowMap
      },
      set: function (val) {
        if (val) {
          this.isShowMap = true
        }
        this.$store.commit('localSettings/SET_BOOKING_FORM_ALWAYS_SHOW_MAP', val)
      }
    },
    settingAlwaysSendConfirmationEmail: {
      get: function () {
        return this.$store.state.localSettings.bookingFormAlwaysSendConfirmationEmail
      },
      set: function (val) {
        this.$store.commit('localSettings/SET_BOOKING_FORM_ALWAYS_SEND_CONFIRMATION_EMAIL', val)
      }
    },
    isDropInBooking: function () {
      const today = window.dayjs.today()
      return this.booking && this.booking.checkIn.isSameOrBefore(today) && !this.isExistingBooking && !this.isSeasonBooking
    },
    shouldSendBookingConfirmation: function () {
      return this.settingAlwaysSendConfirmationEmail && !this.isExistingBooking && !this.isDropInBooking && !this.isSeasonBooking
    },
    saveBookingButtonText: function () {
      if (this.shouldSendBookingConfirmation) {
        return 'Spara & bekräfta'
      } else {
        return 'Spara bokning'
      }
    },
    mapCardWidth: function () {
      const windowWidth = window.innerWidth
      const minimumCardWidth = 564
      const maxCardWidth = windowWidth - 700
      const mapCardHeight = 730
      const optimalWidth = mapCardHeight / this.mapImageAspectRatio
      const width = Math.min(Math.max(minimumCardWidth, optimalWidth), maxCardWidth)
      return width
    },
    availableCancellationPolicies: function () {
      if (!this.booking || !this.booking.category) {
        return []
      }
      if (this.booking.type === 'season') {
        return this.booking.category.cancellationPolicies.filter(cp => cp.available_on.includes('reception_season'))
      } else {
        return this.booking.category.cancellationPolicies.filter(cp => cp.available_on.includes('reception_standard'))
      }
    },
    showMapButton: function () {
      return !this.isMobile && !this.settingAlwaysShowMap
    },
    showLodgingTypePicker: function () {
      return this.booking && this.booking.category && this.booking.category.lodgingTypes.length > 1
    },
    showCancellationPolicyPicker: function () {
      return this.booking && this.booking.category && this.availableCancellationPolicies.length > 1
    },
    showBookingFeeSwitch: function () {
      return !!this.bookingFee && !this.isExistingBooking && this.booking && this.booking.type === 'regular'
    },
    showPositionReservationFeeSwitch: function () {
      return !!this.positionReservationFee && !this.isExistingBooking && this.booking && this.booking.type === 'regular'
    },
    showElectricityFeeSwitch: function () {
      return !this.isExistingBooking && this.booking && this.booking.type === 'regular' && this.booking.category && this.booking.category.electricity.daily_price
    },
    electricityDailyPrice: function () {
      if (!this.showElectricityFeeSwitch) {
        return 0
      }
      return this.booking.category.electricity.daily_price
    },
    isMobile: function () {
      return this.$vuetify.breakpoint.mdAndDown
    },
    maxGuests: function () {
      return (this.booking && this.booking.category) ? this.booking.category.max_guests : 99
    },
    maxAdults: function () {
      return (this.booking && this.booking.category) ? this.booking.category.max_adults : 99
    },
    maxChildren: function () {
      return (this.booking && this.booking.category) ? this.booking.category.max_children : 99
    },
    chargeTotal: function () {
      if (!this.booking) {
        return 0
      }
      const total = this.booking.charges.reduce((acc, c) => acc + (c.total), 0) + this.booking.accommodation_price
      return total
    },
    isLoadingAvailability: function () {
      return !!this.fetchingAvailabilities.find(ra => ra.checkIn.isSame(this.booking.checkIn) && ra.checkOut.isSame(this.booking.checkOut) && ra.bookingId === (this.booking.id ?? null) && ra.occupancy === (this.booking.adults + this.booking.children) && ra.dogs === this.booking.dogs)
    },
    hasAvailabilityError: function () {
      return !!this.availabilitiesErrors.find(ra => ra.checkIn.isSame(this.booking.checkIn) && ra.checkOut.isSame(this.booking.checkOut) && ra.bookingId === (this.booking.id ?? null) && ra.occupancy === (this.booking.adults + this.booking.children) && ra.dogs === this.booking.dogs)
    },
    isSavedCustomer: function () {
      return this.editedCustomer && this.editedCustomer.id !== 0
    },
    preselectedCustomer: function () {
      if (this.booking && this.booking.customer) {
        return this.booking.customer
      }
      return null
    },
    customerFormTitle: function () {
      if (this.isSavedCustomer) {
        return 'Redigera Kund'
      } else {
        if (this.editedCustomer.is_org) {
          return 'Ny företagskund'
        } else {
          return 'Ny kund'
        }
      }
    },
    isExistingBooking: function () {
      return !!(this.booking && this.booking.id)
    },
    isSeasonBooking: function () {
      return this.booking && this.booking.type === 'season'
    },
    dialogHeader: function () {
      if (this.isExistingBooking) {
        if (this.isSeasonBooking) {
          return 'Redigera säsongsbokning'
        }
        return 'Redigera bokning'
      } else {
        if (this.isSeasonBooking) {
          return 'Ny säsongsbokning'
        }
        return 'Ny bokning'
      }
    },
    nights: function () {
      if (!this.booking || !this.booking.checkIn || !this.booking.checkOut) {
        return 0
      }
      return this.booking.checkOut.diff(this.booking.checkIn, 'days')
    },
    showPrintRegistrationCardButton: function () {
      return this.editedCustomer && !this.editedCustomer.is_org && this.defaultPrinter
    }
  },
  watch: {
    'booking.checkIn': function () {
      const bookingId = this.booking.id ?? 0
      this.$store.dispatch('position/fetchAvailability', { checkIn: this.booking.checkIn, checkOut: this.booking.checkOut, bookingId, occupancy: this.booking.adults + this.booking.children, dogs: this.booking.dogs })
      if (this.bookingFee && !this.bookingFee.isManualSelection) {
        // lägger till bokningsavgift automatiskt om det inte är incheckning idag
        this.bookingFee.isSelected = !this.booking.checkIn.isToday()
      }
      if (this.positionReservationFee && !this.positionReservationFee.isManualSelection) {
        // lägger till platsreservationsavgift automatiskt om det inte är incheckning idag och valt en specifik position
        this.positionReservationFee.isSelected = !this.booking.checkIn.isToday() && this.booking.position.id !== 0
      }
    },
    'booking.checkOut': function () {
      const bookingId = this.booking.id ?? 0
      this.$store.dispatch('position/fetchAvailability', { checkIn: this.booking.checkIn, checkOut: this.booking.checkOut, bookingId, occupancy: this.booking.adults + this.booking.children, dogs: this.booking.dogs })
    },
    'booking.position': function () {
      if (this.positionReservationFee && !this.positionReservationFee.isManualSelection) {
        // lägger till platsreservationsavgift automatiskt om det inte är incheckning idag och valt en specifik position
        this.positionReservationFee.isSelected = !this.booking.checkIn.isToday() && this.booking.position.id !== 0
      }
    },
    showElectricityFeeSwitch: function () {
      if (!this.showElectricityFeeSwitch) {
        this.hasSelectedElectricity = false
      }
    },
    nights: function () {
      // uppdaterar kvantitet elektricitet
      if (this.hasSelectedElectricity && this.showElectricityFeeSwitch) {
        const electricityCharge = this.booking.charges.find(charge => charge.isElectricityFeeCharge)
        electricityCharge.quantity = this.nights * 1000
        electricityCharge.total = this.electricityDailyPrice * this.nights
      }
    },
    'booking.adults': function () {
      const bookingId = this.booking.id ?? 0
      this.$store.dispatch('position/fetchAvailability', { checkIn: this.booking.checkIn, checkOut: this.booking.checkOut, bookingId, occupancy: this.booking.adults + this.booking.children, dogs: this.booking.dogs })
    },
    'booking.children': function () {
      const bookingId = this.booking.id ?? 0
      this.$store.dispatch('position/fetchAvailability', { checkIn: this.booking.checkIn, checkOut: this.booking.checkOut, bookingId, occupancy: this.booking.adults + this.booking.children, dogs: this.booking.dogs })
    },
    'booking.dogs': function () {
      const bookingId = this.booking.id ?? 0
      this.$store.dispatch('position/fetchAvailability', { checkIn: this.booking.checkIn, checkOut: this.booking.checkOut, bookingId, occupancy: this.booking.adults + this.booking.children, dogs: this.booking.dogs })
    },
    isMobile: function () {
      if (this.isMobile) {
        this.isShowMap = false
      }
    },
    show: function () {
      if (!this.show) {
        // dialogen har stängts
        if (this.bookingFee) {
          this.bookingFee.isManualSelection = false
        }
        if (this.positionReservationFee) {
          this.positionReservationFee.isManualSelection = false
        }
        this.hasSelectedElectricity = false
        this.accommodationPriceIsEditedByUser = false
        this.categoryPickerValue = null
        this.rebookingPreview = null
      } else {
        if (this.isExistingBooking) {
          this.getCharges()
        }
      }
    },
    isSeasonBooking: function () {
      if (this.isSeasonBooking && this.bookingFee) {
        this.bookingFee.isSelected = false
      }
      if (this.isSeasonBooking && this.positionReservationFee) {
        this.positionReservationFee.isSelected = false
      }
    },
    'bookingFee.isSelected': function () {
      if (!this.booking) {
        return false
      }
      if (this.bookingFee.isSelected) {
        this.booking.charges.push({
          isBookingFeeCharge: true,
          price: this.bookingFee.price,
          quantity: 1000,
          total: this.bookingFee.price,
          title: this.bookingFee.name,
          color: ''
        })
      } else {
        this.booking.charges = this.booking.charges.filter(charge => !charge.isBookingFeeCharge)
      }
    },
    'positionReservationFee.isSelected': function () {
      if (!this.booking) {
        return false
      }
      if (this.positionReservationFee.isSelected) {
        this.booking.charges.push({
          isPositionReservationFeeCharge: true,
          price: this.positionReservationFee.price,
          quantity: 1000,
          total: this.positionReservationFee.price,
          title: this.positionReservationFee.name,
          color: ''
        })
      } else {
        this.booking.charges = this.booking.charges.filter(charge => !charge.isPositionReservationFeeCharge)
      }
    },
    hasSelectedElectricity: function () {
      if (this.hasSelectedElectricity) {
        this.booking.charges.push({
          isElectricityFeeCharge: true,
          price: this.electricityDailyPrice,
          quantity: this.nights * 1000,
          total: this.electricityDailyPrice * this.nights,
          title: 'Elavgift',
          color: ''
        })
      } else {
        this.booking.charges = this.booking.charges.filter(charge => !charge.isElectricityFeeCharge)
      }
    }
  },
  methods: {
    setPositionValidation: function (validation) {
      this.positionValidation = validation
    },
    setCustomerValidation: function (validation) {
      this.customerValidation = validation
    },
    setMapImageAspectRatio: function (val) {
      this.mapImageAspectRatio = val
    },
    setCustomer: function (val) {
      this.booking.customer = val
    },
    setCategory: function (newCategory) {
      if (this.categoryPickerValue?.id === newCategory?.id) {
        // ingen förändring
        return false
      }

      this.categoryPickerValue = newCategory
      if (newCategory) {
        this.booking.category = newCategory
        this.booking.lodgingType = newCategory.defaultLodgingType
      } else {
        this.booking.category = this.activeCategories[0]
        this.booking.lodgingType = this.activeLodgingTypes[0]
      }
      this.booking.position = null
      if (this.booking.cancellationPolicy) {
        const policy = this.booking.category.cancellation_policies.find(p => p.id === this.booking.cancellationPolicy.id)
        if (!policy) {
          // Vald policy är inte tillgänglig i nya category
          this.booking.cancellationPolicy = this.booking.category.cancellation_policies[0]
        }
      }
      if (this.isShowMap && newCategory) {
        this.$refs.bookingMap.zoomToCategory(newCategory.id)
      }
      this.getCharges()
      this.updateAccommodationPrice()
    },
    setLodgingType: function (val) {
      this.booking.lodgingType = val
    },
    setPosition: function (val, setCategory = true) {
      if (!val) {
        this.categoryPickerValue = null
        this.booking.category = null
        this.booking.position = null
      } else {
        if (setCategory) {
          this.categoryPickerValue = val.category
        }
        if (!this.booking.category || this.booking.category.id !== val.category.id) {
          this.booking.category = val.category
          this.booking.lodgingType = this.booking.category.defaultLodgingType
          if (this.booking.cancellationPolicy) {
            const policy = this.booking.category.cancellation_policies.find(p => p.id === this.booking.cancellationPolicy.id)
            if (!policy) {
            // Vald policy är inte tillgänglig i nya category
              this.booking.cancellationPolicy = this.booking.category.cancellation_policies[0]
            }
          }
          this.getCharges()
        }
        this.booking.position = val
        this.updateAccommodationPrice()
      }
    },
    setPositionAndZoom: function (val) {
      this.setPosition(val, false)
      if (this.isShowMap && this.booking.position && this.booking.position.id !== 0) {
        const coordinates = {
          x: this.booking.position.map_x,
          y: this.booking.position.map_y
        }
        this.$refs.bookingMap.zoomToPosition(coordinates)
      }
    },
    setDates: function ({ checkin, checkout }) {
      this.booking.checkIn = checkin
      this.booking.checkOut = checkout
      this.getCharges()
    },
    setFirstDate: function (dt) {
      this.booking.checkIn = dt
      this.getCharges()
    },
    setSecondDate: function (dt) {
      this.booking.checkOut = dt
      this.getCharges()
    },
    setAdults (val) {
      this.booking.adults = val
      this.getCharges()
    },
    setChildren (val) {
      this.booking.children = val
      this.getCharges()
    },
    setDogs (val) {
      this.booking.dogs = val
      this.getCharges()
    },
    setBookingType (val) {
      this.booking.type = val
      if (this.booking.type !== 'season') {
        this.booking.season = null
        this.booking.accommodation_price = 0
      } else {
        this.booking.charges = []
        this.rebookingPreview = null
        this.isFetchingCharges = false
        this.isFetchingRebookingPreview = false
      }
      this.getCharges()
    },
    setArrivalTime: function (val) {
      this.booking.expected_arrival_time = val
    },
    setCancellationPolicy: function (val) {
      this.booking.cancellationPolicy = val
      this.getCharges()
    },
    setSeason: function (val) {
      if (!val) {
        return false
      }
      this.booking.season = val
      this.setDates({
        checkin: val.start,
        checkout: val.end
      })

      this.updateAccommodationPrice()
    },
    setSelectedRebookingOption: function (val) {
      this.selectedRebookingOption = val
    },
    updateAccommodationPrice: function () {
      if (this.accommodationPriceIsEditedByUser) {
        return false
      }
      if (!this.isSeasonBooking || !this.booking.season) {
        this.booking.accommodation_price = 0
        return false
      }

      // Letar pris i season
      let price = null
      if (this.booking.position && this.booking.position.id !== 0) {
        const positionPrice = this.booking.season.custom_season_prices.find(csp => csp.position_ids.includes(this.booking.position.id))
        if (positionPrice) {
          price = positionPrice.price
        }
      }

      if (price === null && this.booking.category && this.booking.category.id !== 0) {
        const categoryPrice = this.booking.season.custom_season_prices.find(csp => csp.category_ids.includes(this.booking.category.id))
        if (categoryPrice) {
          price = categoryPrice.price
        }
      }

      if (price === null) {
        price = this.booking.season.default_season_price
      }

      this.booking.accommodation_price = price
    },
    resetCustomerValidation: function () {
      this.customerValidation.hasChanges = false
      this.customerValidation.isLoading = false
      this.customerValidation.isValidated = false
      this.customerValidation.hasError = false
      this.customerValidation.hasWarning = false
      this.customerValidation.errorMessage = ''
    },
    toggleMap: function () {
      this.isShowMap ? this.closeMap() : this.showMap()
    },
    showMap: function () {
      this.isShowMap = true
      this.$nextTick(function () {
        if (this.booking && this.booking.position && this.booking.position.id !== 0) {
          const coordinates = {
            x: this.booking.position.map_x,
            y: this.booking.position.map_y
          }
          this.$refs.bookingMap.zoomToPosition(coordinates)
        } else if (this.booking && this.booking.category) {
          this.$refs.bookingMap.zoomToCategory(this.booking.category.id)
        }
      })
    },
    closeMap: function () {
      this.isShowMap = false
    },
    customerSaved: function (savedCustomer) {
      // åberopas från customer form när customer har sparats till server
      this.booking.customer = savedCustomer
      this.editedCustomer = null
      this.isShowCustomerForm = false
    },
    selectedBookingFee: function () {
      this.bookingFee.isManualSelection = true
    },
    selectedPositionReservationFee: function () {
      this.positionReservationFee.isManualSelection = true
    },
    saveBooking: function (withCheckin = false) {
      this.shouldCheckinAfterSave = withCheckin
      if (this.booking.customer === null) {
        this.$store.dispatch('snackbars/createSnackbar', {
          color: 'error',
          text: 'Kan inte gå vidare utan att välja kund'
        })
        return false
      }
      if (this.booking.type === 'season' && this.booking.position === null) {
        this.$store.dispatch('snackbars/createSnackbar', {
          color: 'error',
          text: 'Kan inte gå vidare utan att välja en plats'
        })
        return false
      }

      this.isSaving = true
      if (this.isExistingBooking) {
        BookingService.updateBooking({ booking: this.booking, rebookingOption: this.selectedRebookingOption })
          .then(({ data }) => {
            if (data.status === 'success') {
              this.$store.commit('booking/SET_SHOW_FORM_DIALOG', false)
              this.$store.commit('season/TRIGGER_RELOAD_SEASON_BOOKINGS') // ladda om season bookings (sker bara om är på den sidan)

              // Om bokningen är bekräftad, öppna dialog och lägg till i booking.bookings - vill inte göra detta för sparade drafts
              if (data.data.booking.confirmed_at) {
                const booking = data.data.booking
                window.enrich.enrichBooking(booking)
                this.$store.commit('booking/ADD_BOOKING', booking) // Lägger till bokningen i vuex direkt istället för vänta på pusher (pusher kan komma först, då ersätts den av detta)

                // öppnar booking dialog (bokningen hämtas om här för att få rätt data)
                this.$store.dispatch('booking/openDialogById', { bookingId: booking.id, refresh: true })
              }

              const params = {
                checkIn: this.booking.checkIn,
                checkOut: this.booking.checkOut,
                bookingId: this.booking.id,
                occupancy: this.booking.adults + this.booking.children,
                dogs: this.booking.dogs
              }
              this.$store.commit('position/DELETE_FETCHED_CATEGORY_AVAILABILITY', params)
              this.$store.commit('position/DELETE_CATEGORY_AVAILABILITY_ERROR', params)
              this.$store.commit('position/DELETE_FETCHED_AVAILABILITY', params)
              this.$store.commit('position/DELETE_AVAILABILITY_ERROR', params)
            }
          })
          .finally(() => {
            this.isSaving = false
            this.$store.commit('booking/TRIGGER_BOOKING_UPDATED')
          })
      } else {
        const sendBookingConfirmation = this.shouldSendBookingConfirmation
        BookingService.saveBooking({ booking: this.booking, total: this.chargeTotal, isApplyBookingFee: (this.bookingFee && this.bookingFee.isSelected), isApplyPositionReservationFee: (this.positionReservationFee && this.positionReservationFee.isSelected), isApplyElectricityFee: this.hasSelectedElectricity })
          .then(({ data }) => {
            if (data.status === 'success') {
              this.$store.commit('booking/SET_SHOW_FORM_DIALOG', false)
              this.$store.commit('season/TRIGGER_RELOAD_SEASON_BOOKINGS') // ladda om season bookings (sker bara om är på den sidan)

              // Om bokningen är bekräftad, öppna dialog och lägg till i booking.bookings - vill inte göra detta för sparade drafts
              if (data.data.booking.confirmed_at) {
                const booking = data.data.booking
                window.enrich.enrichBooking(booking)
                this.$store.commit('booking/ADD_BOOKING', booking) // Lägger till bokningen i vuex direkt istället för vänta på pusher (pusher kan komma först, då ersätts den av detta)

                if (this.shouldCheckinAfterSave) {
                  // har klickat på "spara och checka in"
                  this.$store.dispatch('booking/checkIn', booking.id)
                } else {
                  // öppnar booking dialog (bokningen hämtas om här för att få rätt data)
                  this.$store.dispatch('booking/openDialogById', { bookingId: booking.id })
                    .then(() => {
                      // Om bokningsbekräftelse ska skickas automatiskt, så gör vi detta efter dialogen öppnats
                      if (sendBookingConfirmation) {
                        this.$store.dispatch('booking/sendBookingConfirmation', { bookingId: booking.id })
                      }
                    })
                }
              }
            }
          })
          .finally(() => {
            this.isSaving = false
            this.$store.commit('position/DELETE_FETCHED_CATEGORY_AVAILABILITY', {
              checkIn: this.booking.checkIn,
              checkOut: this.booking.checkOut,
              bookingId: this.booking.id,
              occupancy: this.booking.adults + this.booking.children,
              dogs: this.booking.dogs
            })
            this.$store.commit('position/DELETE_CATEGORY_AVAILABILITY_ERROR', {
              checkIn: this.booking.checkIn,
              checkOut: this.booking.checkOut,
              bookingId: this.booking.id,
              occupancy: this.booking.adults + this.booking.children,
              dogs: this.booking.dogs
            })
            this.$store.commit('position/DELETE_FETCHED_AVAILABILITY', {
              checkIn: this.booking.checkIn,
              checkOut: this.booking.checkOut,
              bookingId: this.booking.id,
              occupancy: this.booking.adults + this.booking.children,
              dogs: this.booking.dogs
            })
            this.$store.commit('position/DELETE_AVAILABILITY_ERROR', {
              checkIn: this.booking.checkIn,
              checkOut: this.booking.checkOut,
              bookingId: this.booking.id,
              occupancy: this.booking.adults + this.booking.children,
              dogs: this.booking.dogs
            })
            this.$store.commit('booking/TRIGGER_BOOKING_UPDATED')
          })
      }
    },
    openCustomerForm: function () {
      if (this.booking.customer === null) {
        this.editedCustomer = Object.assign({}, this.customerTemplate) // Kopierar customerTemplate (country customer.country är fortfarande reference)
      } else {
        this.editedCustomer = Object.assign({}, this.booking.customer)
      }
      this.isShowCustomerForm = true
    },
    closeCustomerForm: function () {
      if ((this.editedCustomer.is_org && this.editedCustomer.org_name === '') || (!this.editedCustomer.is_org && this.editedCustomer.given_name === '' && this.editedCustomer.surname === '')) {
        // Tar bort kund från booking om saknar både förnamn och efternamn (privatperson) eller org_name (företagskund)
        this.booking.customer = null
        this.editedCustomer = null
        this.resetCustomerValidation()
      } else {
        if (this.customerValidation === null) {
          this.forceCustomerValidation++
        }
        // Sätter attribut på customer som behövs när den ska visas i customerSelector
        window.enrich.enrichCustomer(this.editedCustomer) // behöver shortAddress attribut som sätts i enrichCustomer metoden

        // behöver full_name som normalt beräknas från servern
        if (this.editedCustomer.is_org) {
          this.editedCustomer.full_name = this.editedCustomer.org_name
        } else {
          this.editedCustomer.full_name = `${this.editedCustomer.given_name} ${this.editedCustomer.surname}`
        }

        this.booking.customer = Object.assign({}, this.editedCustomer)
      }
      this.isShowCustomerForm = false
    },
    getCharges: function () {
      if (this.isSeasonBooking) {
        // pris för säsongsbokningar beräknas lokalt
        return false
      }

      const checkIn = this.booking.checkIn
      const checkOut = this.booking.checkOut
      const cancellationPolicyId = this.booking.cancellationPolicy.id
      const categoryId = this.booking.category.id
      const adults = this.booking.adults
      const children = this.booking.children
      const dogs = this.booking.dogs

      this.hasRebookingPreviewError = false
      this.rebookingPreview = null
      if (this.isExistingBooking) {
        // hämtar rebooking preview på redigerad bokning
        const bookingId = this.booking.id
        this.isFetchingRebookingPreview = true
        RateService.getRebookingPreview({ bookingId, checkIn, checkOut, cancellationPolicyId, categoryId, adults, children, dogs })
          .then(({ data }) => {
            if (
              data.status === 'success' &&
              checkIn.isSame(this.booking.checkIn) &&
              checkOut.isSame(this.booking.checkOut) &&
              cancellationPolicyId === this.booking.cancellationPolicy.id &&
              categoryId === this.booking.category.id &&
              this.booking.type !== 'season'
            ) {
              const defaultRebookingOption = data.data.preview.rebooking_options.find(ro => ro.is_default) ?? data.data.preview.rebooking_options[0]
              this.selectedRebookingOption = defaultRebookingOption
              this.rebookingPreview = data.data.preview
            } else if (data.status !== 'success') {
              this.hasRebookingPreviewError = true
            }
          })
          .catch(() => {
            this.hasRebookingPreviewError = true
          })
          .finally(() => {
            if (
              checkIn.isSame(this.booking.checkIn) &&
              checkOut.isSame(this.booking.checkOut) &&
              cancellationPolicyId === this.booking.cancellationPolicy.id &&
              categoryId === this.booking.category.id &&
              this.booking.type !== 'season'
            ) {
              this.isFetchingRebookingPreview = false
            }
          })
      } else {
        // hämtar charges på ny bokning
        this.isFetchingCharges = true
        RateService.getAccommodationCharges({ checkIn, checkOut, cancellationPolicyId, categoryId, adults, children, dogs })
          .then(({ data }) => {
            let charges = []
            if (data.status === 'success') {
              charges = data.data.charges
            }
            if (
              checkIn.isSame(this.booking.checkIn) &&
            checkOut.isSame(this.booking.checkOut) &&
            cancellationPolicyId === this.booking.cancellationPolicy.id &&
            categoryId === this.booking.category.id &&
            this.booking.type !== 'season'
            ) {
              if (this.bookingFee && this.bookingFee.isSelected) {
                charges.push({
                  isBookingFeeCharge: true,
                  price: this.bookingFee.price,
                  quantity: 1000,
                  total: this.bookingFee.price,
                  title: this.bookingFee.name,
                  color: ''
                })
              }
              if (this.positionReservationFee && this.positionReservationFee.isSelected) {
                charges.push({
                  isPositionReservationFeeCharge: true,
                  price: this.positionReservationFee.price,
                  quantity: 1000,
                  total: this.positionReservationFee.price,
                  title: this.positionReservationFee.name,
                  color: ''
                })
              }
              if (this.hasSelectedElectricity) {
                charges.push({
                  isElectricityFeeCharge: true,
                  price: this.electricityDailyPrice,
                  quantity: this.nights * 1000,
                  total: this.electricityDailyPrice * this.nights,
                  title: 'Elavgift',
                  color: ''
                })
              }
              this.booking.charges = charges
            }
          })
          .catch(() => {
          // Tar bort charges om request misslyckas
            if (
              checkIn.isSame(this.booking.checkIn) &&
            checkOut.isSame(this.booking.checkOut) &&
            cancellationPolicyId === this.booking.cancellationPolicy.id &&
            categoryId === this.booking.category.id
            ) {
              this.booking.charges = []
            }
          })
          .finally(() => {
            if (
              checkIn.isSame(this.booking.checkIn) &&
            checkOut.isSame(this.booking.checkOut) &&
            cancellationPolicyId === this.booking.cancellationPolicy.id &&
            categoryId === this.booking.category.id
            ) {
              this.isFetchingCharges = false
            }
          })
      }
    }
  },
  created () {
    if (this.settingAlwaysShowMap && !this.isMobile) {
      this.isShowMap = true
    }
  },
  mounted () {
    // När vi flyttar detta till server tycker jag vi ska visa avgifterna i formuläret även när man redigerar bokning, och synka med produkter som sparats på bokningen (med product_category). OBS räcker inte med en "position_reservation_fee_product_id" i databasen, eftersom det finns behov av att ha olika priser olika säsonger (efterfrågat av borstahusen). Kan nog lägga till "ändringsavgift" också (Skrea tar "ibland" 100kr vid bokningsändringar), kanske "administrativ avgift ombokning" typ? har ju vi i våra egna bokningsvillkor också. Bokningsavgiften har ju också egenskapen att en del campingar (böda hamn) lägger på den på onlinebokningar också. Kanske ska hanteras i rates, men att det finns ett system som täcker de vanligaste behoven där rates bara hämtar product_id från databasen och tar priset från den oavsett säsong och category
    const namespace = this.$store.state.user.domain.namespace
    // TODO: [CO-2856] Platsreservationsavgift
    if (namespace === 'skrea') {
      this.positionReservationFee = {
        isSelected: false,
        isManualSelection: false,
        price: 19500,
        name: 'Platsreservationsavgift'
      }
    } else if (namespace === 'habo') {
      this.positionReservationFee = {
        isSelected: false,
        isManualSelection: false,
        price: 7500,
        name: 'Platsreservationsavgift'
      }
    }

    // TODO: [CO-2856] Bokningsavgift
    const bookingFeePrice = {
      skrea: 15000,
      habo: 7500,
      rabocka: 7500,
      lerberget: 7500,
      bodahamn: 8000,
      vallsnas: 8000,
      edsken: 15000
    }[namespace] ?? null

    if (bookingFeePrice) {
      this.bookingFee = {
        isSelected: false,
        isManualSelection: false,
        price: bookingFeePrice,
        name: 'Bokningsavgift'
      }
    }
  }
}
</script>
