<template>
  <v-list dense>
    <v-list-item-group v-model="item" color="secondary darken-2">
      <div
        v-for="(item, i) in mainMenuItems"
        :key="i"
      >
        <template v-if="item.isGroup">
          <v-list-group
            v-if="!menuFolded"
            :value="false"
            no-action
            :prepend-icon="item.icon"
          >
            <template v-slot:activator>
              <v-list-item-content class="">
                <v-list-item-title v-text="item.text"></v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="(subItem, j) in item.subItems"
              :key="j"
              :to="subItem.path"
              exact
              :disabled="subItem.disabled"
            >
              <v-list-item-title v-text="subItem.text"></v-list-item-title>
              <v-list-item-icon>
                <v-icon v-text="subItem.icon"></v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list-group>

          <template v-else>
            <v-menu
              top
              offset-x
              transition="slide-x-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-list-item
                  v-bind="attrs"
                  v-on="on"
                  :disabled="item.disabled"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item-icon
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon v-text="item.icon"></v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title v-text="item.text"></v-list-item-title>
                      </v-list-item-content>
                    </template>
                    <span>{{ item.text }} (meny)</span>
                  </v-tooltip>
                </v-list-item>
              </template>
              <div style="background-color: white;" class="d-flex">
                <div
                  v-for="(subItem, j) in item.subItems"
                  :key="j"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        :to="subItem.path"
                        exact
                        class="ma-2"
                        icon
                      >
                        <v-icon color="primary">
                          {{ subItem.icon }}
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>{{ subItem.text }}</span>
                  </v-tooltip>
                </div>
              </div>
            </v-menu>
          </template>

        </template>

        <template v-else>
          <v-tooltip :disabled="!menuFolded" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-list-item
                :to="item.path"
                exact
                v-bind="attrs"
                v-on="on"
                :disabled="item.disabled"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.text"></v-list-item-title>
                </v-list-item-content>
                <v-avatar
                  v-if="item.badgeContent"
                  color="secondary"
                  size="20"
                >
                  <span class="white--text" style="font-size: 14px;">{{ item.badgeContent }}</span>
                </v-avatar>
              </v-list-item>
            </template>
            <span>{{ item.text }}</span>
          </v-tooltip>
        </template>

      </div>
    </v-list-item-group>
  </v-list>
</template>

<script>

export default {
  name: 'MainMenu',
  props: {
    menuFolded: Boolean
  },
  data: () => ({
    item: 0
  }),
  computed: {
    user: function () {
      return this.$store.state.user.user
    },
    mainMenuItems: function () {
      const items = []
      items.push(
        { isGroup: false, icon: 'mdi-speedometer', text: 'Dashboard', path: '/', disabled: false },
        { isGroup: false, icon: 'mdi-format-list-bulleted-square', text: 'In- och utcheckningar', path: '/actions', disabled: false },
        { isGroup: false, icon: 'mdi-map', text: 'Karta', path: '/map', disabled: false },
        { isGroup: false, icon: 'coi-bookings-calendar', text: 'Bokningskalender', path: '/calendar', disabled: false },
        { isGroup: false, icon: 'mdi-book-marker-outline', text: 'Bokningar', path: '/bookings', disabled: false },
        {
          isGroup: true,
          icon: 'mdi-cash-register',
          text: 'Kassa',
          subItems: [
            { isGroup: false, icon: 'mdi-point-of-sale', text: 'Försäljning', path: '/register', disabled: false },
            { isGroup: false, icon: 'coi-parked-orders', text: 'Parkerade notor', path: '/register/orders', disabled: false },
            { isGroup: false, icon: 'mdi-receipt-text', text: 'Kvitton', path: '/receipts', disabled: false },
            { isGroup: false, icon: 'mdi-desktop-tower-monitor', text: 'Enheter', path: '/register/units', disabled: false }
          ]
        },
        {
          isGroup: true,
          icon: 'mdi-laptop',
          text: 'Administration',
          subItems: [
            { isGroup: false, icon: 'coi-bankgirot', text: 'Bankgiro', path: '/bankgiro', disabled: false },
            { isGroup: false, icon: 'coi-accounting', text: 'Bokföring', path: '/accounting', disabled: false },
            { isGroup: false, icon: 'mdi-barcode', text: 'Produkter', path: '/products', disabled: false },
            { isGroup: false, icon: 'mdi-file-document', text: 'Fakturor', path: '/invoices', disabled: false },
            { isGroup: false, icon: 'mdi-account-star', text: 'Säsongsbokningar', path: '/seasons', disabled: false },
            { isGroup: false, icon: 'mdi-text-box-remove-outline', text: 'Avbokningar', path: '/cancellations', disabled: false }
          ]
        },
        {
          isGroup: true,
          icon: 'mdi-chart-timeline-variant',
          text: 'Statistik',
          subItems: [
            { isGroup: false, icon: 'mdi-chart-box-outline', text: 'Nyckeltal & grafer', path: '/statistics', disabled: false },
            /* { isGroup: false, icon: 'mdi-file-chart', text: 'Rapporter', path: '/reports', disabled: false }, */
            { isGroup: false, icon: 'coi-sales-report', text: 'Försäljningsrapporter', path: '/sales-reports', disabled: false },
            { isGroup: false, icon: 'coi-tillvaxtverket-alt', text: 'Inkvarteringsstatistik', path: '/scb', disabled: false }
          ]
        }
      )

      return items
    },
    orderNotesCount: function () {
      return this.$store.state.posOrder.orders.length
    }
  },
  methods: {},
  watch: {}
}
</script>
