export default (o, c, d) => {
  c.prototype.toHumanReadableDate = function ({ longFormat = false, capitalized = true } = {}) {
    const monthCode = longFormat ? 'MMMM' : 'MMM'
    const dayCode = longFormat ? 'dddd' : 'ddd'
    let str = ''
    if (this.isSame(this.startOf('day'))) {
    // Klockslag 00:00:00.000 - är date istället för datetime = vill inte visa klockslaget
      if (this.isToday()) {
        str = 'idag'
      } else if (this.isYesterday()) {
        str = 'igår'
      } else if (this.isTomorrow()) {
        str = 'imorgon'
      } else {
        if (this.isSame(window.dayjs.today(), 'year')) {
          str = this.format(`${dayCode} D ${monthCode}`)
        } else {
          str = this.format(`${dayCode} D ${monthCode} YYYY`)
        }
      }
    } else {
      if (this.isToday()) {
        str = `idag kl ${this.format('HH:mm')}`
      } else if (this.isYesterday()) {
        str = `igår kl ${this.format('HH:mm')}`
      } else if (this.isTomorrow()) {
        str = `imorgon kl ${this.format('HH:mm')}`
      } else {
        if (this.isSame(window.dayjs.today(), 'year')) {
          str = this.format(`${dayCode} D ${monthCode} HH:mm`)
        } else {
          str = this.format(`${dayCode} D ${monthCode} YYYY`)
        }
      }
    }
    if (capitalized) {
      return str.charAt(0).toUpperCase() + str.slice(1)
    } else {
      return str
    }
  }
}
