<template>
  <div class="tally-card-list d-flex flex-column">
    <template v-if="isLoading">
      <v-simple-table class="">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">
                Kort id
              </th>
              <th class="text-left">
                Tilldelad
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="i in 2" :key="i">
              <td>
                <v-skeleton-loader
                  style=""
                  type="table-cell"
                  class=""
                ></v-skeleton-loader>
              </td>
              <td>
                <v-skeleton-loader
                  style=""
                  type="table-cell"
                  class=""
                ></v-skeleton-loader>
              </td>
              <td class="text-right">
                <v-btn icon disabled class="error-on-hover">
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </template>

    <template v-else>
      <v-alert
        v-if="tallyCards.length === 0 && !isAddingCard"
        dense
        text
        color="warning"
        class="ma-4 text-center"
      >
        Inga kort har lagts till
      </v-alert>
      <v-simple-table v-else class="">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">
                Kort id
              </th>
              <th class="text-left">
                Tilldelad
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="card in tallyCards"
              :key="card.card_uid"
              :class="{ 'highlight-tally-card': recentlyScannedUids.includes(card.card_uid) }"
            >
              <td v-text="card.card_uid"></td>
              <template v-if="card.created_at === null">
                <td>
                  <v-chip
                    class=""
                    small
                    color="warning"
                    text-color="white"
                  >
                    Köad
                  </v-chip>
                </td>
              </template>
              <template v-else>
                <td v-text="formatCreatedAt(card.created_at)"></td>
              </template>
              <td class="text-right">
                <v-btn :loading="isRemovingCard(card)" icon @click="deleteCard(card)" class="error-on-hover">
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </td>
            </tr>
            <template v-if="isAddingCard">
              <tr
                v-for="i in numberOfCardsBeingAdded"
                :key="i"
              >
                <td>
                  <v-skeleton-loader type="table-cell"></v-skeleton-loader>
                </td>
                <td>
                  <v-skeleton-loader type="table-cell"></v-skeleton-loader>
                </td>
                <td class="text-right">
                  <v-btn icon disabled class="error-on-hover">
                    <v-icon>
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </tbody>
        </template>
      </v-simple-table>
    </template>

    <v-card-actions class="mt-auto">
      <span v-if="isReadyForScanning" class="success--text pl-2 text-body-2">Skanna ett kort för att lägga till det</span>
      <v-spacer></v-spacer>
      <v-btn v-if="!isReadyForScanning" @click="startAddNewCard" :disabled="isLoading" color="success" text>Lägg till kort</v-btn>
      <v-btn v-else @click="abort" color="error" text class="">Avbryt</v-btn>
    </v-card-actions>

  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'TallyCardList',
  components: {},
  props: {
    booking: Object,
    isOpen: Boolean,
    isLoading: Boolean,
    isReadyForScanning: Boolean
  },
  data: () => ({}),
  computed: {
    ...mapState(
      {
        isAddingCardBookingIds: state => state.tally.isAddingCardBookingIds, // kan innehålla samma booking id flera gånger, om man lägger till flera kort på samma booking innan tidigare requests avslutats
        isRemovingCardUids: state => state.tally.isRemovingCardUids,
        recentlyScannedUids: state => state.tally.recentlyScannedUids
      }
    ),
    isAddingCard: function () {
      return this.booking && this.isAddingCardBookingIds.includes(this.booking.id)
    },
    numberOfCardsBeingAdded: function () {
      if (!this.isAddingCard) {
        return 0
      }
      return this.isAddingCardBookingIds.filter(id => id === this.booking.id).length
    },
    tallyCards: function () {
      if (!this.booking || !this.booking.tally_resource) {
        return []
      }
      return this.booking.tally_resource.cards
    }
  },
  methods: {
    startAddNewCard: function () {
      this.$emit('start-add-new-card')
    },
    abort: function () {
      this.$emit('abort-add-new-card')
    },
    formatCreatedAt: function (strDateTime) {
      const dt = window.dayjs.parseServerDateTime(strDateTime)
      return dt.toHumanReadableDate()
    },
    deleteCard: function (card) {
      this.$store.dispatch('tally/removeCard', { booking: this.booking, cardUid: card.card_uid })
    },
    isRemovingCard: function (card) {
      return this.isRemovingCardUids.includes(card.card_uid)
    }
  },
  watch: {
    isOpen: function () {
      if (!this.isOpen) {
        this.abort()
      }
    }
  },
  destroyed () {
    this.abort()
  }
}
</script>
