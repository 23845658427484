<template>
  <div :class="{ 'is-mobile': isMobile }">
    <v-app v-if="isLoggedIn && !isFetchingData">
      <app-navigation></app-navigation>
      <speed-dial></speed-dial>
      <router-view />
      <snackbar-collection></snackbar-collection>
      <connection-status></connection-status>
      <common-confirmation-dialog></common-confirmation-dialog>
      <generic-input-dialog></generic-input-dialog>
      <customer-dialog v-if="hasPmsModule"></customer-dialog>
      <booking-dialog v-if="hasPmsModule"></booking-dialog>
      <booking-cancellation-dialog v-if="hasPmsModule"></booking-cancellation-dialog>
      <checkin-dialog v-if="hasPmsModule"></checkin-dialog>
      <late-checkout-dialog v-if="hasPmsModule"></late-checkout-dialog>
      <invoice-dialog v-if="hasPmsModule"></invoice-dialog>
      <receipt-dialog></receipt-dialog>
      <product-form-dialog></product-form-dialog>
      <position-block-dialog v-if="hasPmsModule"></position-block-dialog>
      <print-preview-dialog></print-preview-dialog>
      <terminal-status-dialog></terminal-status-dialog>
      <receipt-delivery-dialog></receipt-delivery-dialog>
      <booking-form-dialog></booking-form-dialog>
      <email-preview-dialog></email-preview-dialog>
    </v-app>

    <v-app v-else>
      <login-component></login-component>
    </v-app>

    <page-loader></page-loader>
    <update-dialog></update-dialog>
    <pwa-prompt></pwa-prompt>
  </div>
</template>

<style lang="scss">
@import './sass/app.scss';
</style>

<script>
import PageLoader from '@/components/layout/PageLoader.vue'
import LoginComponent from './views/Login.vue'
import AppNavigation from '@/components/layout/AppNavigation.vue'
import SnackbarCollection from '@/components/common/SnackbarCollection.vue'
import ConnectionStatus from '@/components/common/ConnectionStatus.vue'
import UpdateDialog from '@/components/common/UpdateDialog.vue'
import PwaPrompt from '@/components/common/PwaPrompt.vue'
import CommonConfirmationDialog from '@/components/common/ConfirmationDialog.vue'
import GenericInputDialog from '@/components/common/GenericInputDialog.vue'
import SpeedDial from '@/components/layout/SpeedDial.vue'

// Dialoger som behövs på flera sidor
import CustomerDialog from '@/components/customer/Dialog.vue'
import BookingDialog from '@/components/booking/Dialog.vue'
import BookingCancellationDialog from '@/components/cancellation-policy/BookingCancellationDialog.vue'
import InvoiceDialog from '@/components/invoice/Dialog.vue'
import ReceiptDialog from '@/components/receipt/Dialog.vue'
import CheckinDialog from '@/components/booking/CheckinDialog.vue'
import LateCheckoutDialog from '@/components/booking/LateCheckoutDialog.vue'
import ProductFormDialog from '@/components/product/ProductFormDialog.vue'
import PositionBlockDialog from '@/components/position/PositionBlockDialog.vue'
import PrintPreviewDialog from '@/components/common/PrintPreviewDialog.vue'
import EmailPreviewDialog from '@/components/common/EmailPreviewDialog.vue'
import BookingFormDialog from '@/components/booking/Form.vue'
import TerminalStatusDialog from '@/components/terminal/StatusDialog.vue'
import ReceiptDeliveryDialog from '@/components/register/ReceiptDeliveryDialog.vue'
export default {
  components: {
    PageLoader,
    AppNavigation,
    LoginComponent,
    SnackbarCollection,
    UpdateDialog,
    ConnectionStatus,
    CommonConfirmationDialog,
    GenericInputDialog,
    SpeedDial,
    CustomerDialog,
    BookingDialog,
    BookingCancellationDialog,
    InvoiceDialog,
    ReceiptDialog,
    CheckinDialog,
    LateCheckoutDialog,
    ProductFormDialog,
    PositionBlockDialog,
    PrintPreviewDialog,
    EmailPreviewDialog,
    BookingFormDialog,
    TerminalStatusDialog,
    PwaPrompt,
    ReceiptDeliveryDialog
  },
  data: () => ({}),
  computed: {
    hasPmsModule: function () {
      return this.user && this.user.modules && this.user.modules.includes('PMS')
    },
    user: function () {
      return this.$store.state.user.user
    },
    isFetchingData: function () {
      return this.$store.state.isFetchingData
    },
    isLoggedIn: function () {
      return this.$store.state.user.authenticated
    },
    isMobile: function () {
      return this.$vuetify.breakpoint.mdAndDown
    }
  },
  created () {
    /*
    * Koden nedan är till för att se till att höjden (100vh) på sidan blir rätt i mobil browsers som inkluderar höjden på browserns egna fält ovanför sidan i beräkningen, så att 100vh blir typ 50px högre än sidans faktiska höjd. T.ex. android chrome måste man scrolla ner för att se logga ut knappen som är längst ner i menyn
    * https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
    **/
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    const vh = window.innerHeight * 0.01
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`)
    // We listen to the resize event
    window.addEventListener('resize', () => {
      // We execute the same script as before
      const vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    })
  }
}
</script>
