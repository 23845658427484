import API from './API.js'

export default {
  initiate ({ registerId, paymentMethodId, amount, accommodationChargesAmount, customerCreditAmount, charges, bookingId, parkedOrderId, shouldCheckOut }) {
    const items = charges.map((charge) => {
      const item = Object.assign({}, charge)
      item.position_id = charge.position ? charge.position.id : null
      item.product_id = charge.product.id
      item.position = null
      item.product = null
      return item
    })
    return API.httpClient.post('sales/initiate', {
      register_id: registerId,
      payment_method_id: paymentMethodId,
      amount,
      customer_credit_amount: customerCreditAmount,
      accommodation_charges_amount: accommodationChargesAmount,
      items,
      booking_id: bookingId,
      parked_order_id: parkedOrderId,
      should_check_out: shouldCheckOut
    })
  },

  retryPayment ({ receiptId }) {
    return API.httpClient.post('sales/retry-payment', {
      receipt_id: receiptId
    })
  },

  signReceipt ({ receiptId, controlData }) {
    return API.httpClient.post('sales/sign-receipt', {
      receipt_id: receiptId,
      control_data: controlData
    })
  },

  deliverReceipt ({ receiptId, receiptForm, email }) {
    return API.httpClient.post('sales/deliver-receipt', {
      receipt_id: receiptId,
      receipt_form: receiptForm,
      email
    })
  },

  getReceipt ({ receiptId }) {
    return API.httpClient.post('sales/get-receipt', {
      receipt_id: receiptId
    })
  }
}
