import API from './API.js'

export default {
  //
  // GET
  //
  getProductGroupSales ({ startDate, endDate, registerId }) {
    return API.httpClient.get('statistics/product-group-sales', {
      params: {
        register_id: registerId,
        start_date: startDate.format('YYYY-MM-DD'),
        end_date: endDate.format('YYYY-MM-DD')
      }
    })
  },
  getScbExports () {
    return API.httpClient.get('scb/exports')
  },
  getMonthlyCheckinsAndCheckouts (month) {
    return API.httpClient.get('statistics/monthly-checkins-and-checkouts', {
      params: {
        month: month.format('YYYY-MM-DD')
      }
    })
  },
  getCheckedInCustomers () {
    return API.httpClient.get('statistics/checked-in-customers')
  },
  getCustomerCounts () {
    return API.httpClient.get('statistics/customer-counts')
  },
  getCustomerNationalityNights () {
    return API.httpClient.get('statistics/customer-nationality-nights')
  },
  getRevenueNumbers () {
    return API.httpClient.get('statistics/revenue-numbers')
  },
  getCategoryOccupation () {
    return API.httpClient.get('statistics/category-occupation')
  },
  getAccommodationValue () {
    return API.httpClient.get('statistics/accommodation-value')
  },
  getWeatherForecast () {
    return API.httpClient.get('weather-forecast')
  },
  getHistoricalTurnoverFullAccrual () {
    return API.httpClient.get('statistics/historical-turnover-full-accrual')
  },
  getHistoricalTurnoverPartAccrual () {
    return API.httpClient.get('statistics/historical-turnover-part-accrual')
  },
  getOccupancyStats () {
    return API.httpClient.get('statistics/occupancy-stats')
  },

  //
  // POST
  //
  createScbExport (month) {
    return API.httpClient.post('scb/create', {
      month: month.format('YYYY-MM-DD')
    })
  },
  confirmScbExport (scbExportId) {
    return API.httpClient.post('scb/confirm', {
      scb_export_id: scbExportId
    })
  }
}
