import Echo from 'laravel-echo'
import Pusher from 'pusher-js'
import AuthService from '@/services/AuthService.js'
import router from '@/router'
import InvoiceService from '@/services/InvoiceService.js'

export const namespaced = true

export const state = {
  pusherState: 'disconnected',
  connectedAt: null,
  disconnectedAt: null,
  isResyncing: false
}

export const mutations = {
  SET_PUSHER_STATE (state, val) {
    state.pusherState = val
  },
  SET_CONNECTED_AT (state, val) {
    state.connectedAt = val
  },
  SET_DISCONNECTED_AT (state, val) {
    state.disconnectedAt = val
  },
  SET_IS_RESYNCING (state, val) {
    state.isResyncing = val
  }
}

export const actions = {
  initialise ({ dispatch }, { force }) {
    if (window.Echo === undefined || force) {
      dispatch('setupEcho')
      // Det mesta som syncas med pusher subscribar vi till ifrån fetch metoderna, t.ex. posOrder/fetchOrders subscribar till ParkedOrdersSaved och ParkedOrderRemoved efter hämtat orders
      // Här subscribar vi till channels som inte har egna fetch metoder
      dispatch('subscribeUpdateClient')
      dispatch('subscribeEmailSaved')
      dispatch('subscribeUpdateData')
      dispatch('subscribeReceiptUpdated')
      dispatch('subscribeTerminalUpdated')
      dispatch('subscribeCustomerCreditUpdated')
      dispatch('subscribeInvoiceUpdated')
      dispatch('subscribeInvoiceDraftUpdated')
    }
  },
  resync ({ commit, dispatch }) {
    // OBS: används inte just nu, vet inte om det är praktiskt möjligt att kunna synca om data utan loading screen, se kommentar längre ner om problemet
    // Hämtar om all data som syncas pusher, körs efter problem med anslutningen, istället för att köra om hela fetchData som även hämtas data som inte syncas
    commit('SET_IS_RESYNCING', true)
    Promise.allSettled([
      dispatch('posOrder/fetchOrders', undefined, { root: true }),
      dispatch('position/fetchPositions', undefined, { root: true }),
      dispatch('booking/fetchBookings', undefined, { root: true }),
      dispatch('tally/fetchCards', undefined, { root: true }),
      dispatch('stats/fetchDashboards', undefined, { root: true }),
      dispatch('stats/fetchDashboardCards', undefined, { root: true })
    ])
      .then(() => {
        console.log('all resync promises finished')
        Promise.allSettled([
          dispatch('position/enrichPositions', undefined, { root: true }),
          dispatch('booking/enrichBookings', undefined, { root: true }),
          dispatch('posOrder/enrichOrders', undefined, { root: true })
        ])
          .then(() => {
            console.log('all resync enriched finished')
          })
          .finally(() => {
            commit('SET_IS_RESYNCING', false)
          })
      })
  },
  setupEcho ({ rootState, commit, dispatch }) {
    console.log('setupEcho', rootState.user.domain)
    if (!rootState.user.domain) {
      console.log('setupEcho - domain undefined')
      return false
    }

    window.Pusher = Pusher
    const echoKey = process.env.NODE_ENV === 'production' ? '25ef9cc3651c47df3191' : 'd20d4e3d1495f21e4f9b'
    window.Echo = new Echo({
      broadcaster: 'pusher',
      key: echoKey,
      cluster: 'eu',
      forceTLS: true,
      authorizer: (channel, options) => {
        return {
          authorize: (socketId, callback) => {
            AuthService.authorizeSocket({
              channel_name: channel.name,
              socket_id: socketId
            })
              .then(response => {
                callback(null, response.data)
              })
              .catch(error => {
                callback(error)
              })
          }
        }
      }
    })

    const namespace = rootState.user.domain.namespace
    window.channel = window.Echo.private(namespace)
    window.channel.listenToAll((event, data) => {
      console.log('Pusher event', event, data)
    })

    window.Echo.connector.pusher.connection.bind('state_change', (states) => {
      // Dokumentation https://pusher.com/docs/channels/using_channels/connection/
      console.log('PUSHER state change: ', states.previous, states.current, window.dayjs.now().format('HH:mm:ss'))
      commit('SET_PUSHER_STATE', states.current)
      if (states.current === 'connected') {
        commit('SET_CONNECTED_AT', window.dayjs.now())
        commit('SET_DISCONNECTED_AT', null)
        if (!rootState.isFetchingData) {
          // Hämtar om endast data som är syncat med pusher, utan reload screen
          // dispatch('resync')
          // REVIEW: vet inte hur jag ska få resync att fungera
          // Jag kan inte köra enrich på objekten som hämtas förrän alla har hämtats, och jag kan inte ersätta objekten i store utan att köra enrich först eller ha en loading screen, för om de används på sidan så blir det en massa errors när objekten inte är berikade
          // exempel, hämtar om både bookings och positions, positions berikas med bookings och tvärtom så båda måste hämtats innan enrich körs. Så jag måste ersätta bookings och sen köra enrich när allting är klart, men t.ex. dagens incheckningar antar att booking.check_in är ett dayjs objekt och kastar errors när inte har berikats ännu
          dispatch('fetchData', { quickly: true }, { root: true })
        }
      } else {
        if (state.connectedAt) {
          commit('SET_CONNECTED_AT', null)
        }
        if (!state.disconnectedAt) {
          commit('SET_DISCONNECTED_AT', window.dayjs.now())
        }
      }
    })
  },
  subscribeUpdateClient ({ commit }) {
    window.channel.stopListening('UpdateClient').listen('UpdateClient', function (data) {
      console.log('versionCompare', window.helper.versionCompare(data.minimumVersion, process.env.VUE_APP_VERSION))
      if (window.helper.versionCompare(data.minimumVersion, process.env.VUE_APP_VERSION) === 1) {
        // minimumVersion > VUE_APP_VERSION
        commit('user/SET_HAS_VERSION_ERROR', true, { root: true })
        commit('SET_UPDATE_URL', data.url, { root: true })
        commit('SET_SHOW_UPDATE_DIALOG', true, { root: true })
      }
    })
  },
  subscribeEmailSaved ({ rootState, commit }) {
    window.channel.stopListening('EmailSaved').listen('EmailSaved', function (data) {
      if (data.booking_id) {
        commit('booking/SET_IS_AWAITING_EMAIL_SAVED_BOOKING_ID', { bookingId: data.booking_id, val: false }, { root: true })
        if (rootState.booking.dialog.bookingId === data.booking_id) {
          window.enrich.enrichEmail(data.email)
          commit('booking/ADD_DIALOG_BOOKING_EMAIL', data.email, { root: true })
        }
        if (rootState.customer.dialog.bookingOverlay.bookingId === data.booking_id) {
          window.enrich.enrichEmail(data.email)
          commit('customer/ADD_BOOKING_OVERLAY_BOOKING_EMAIL', data.email, { root: true })
        }
      }
      if (data.invoice_id) {
        commit('invoice/SET_IS_AWAITING_EMAIL_SAVED_INVOICE_ID', { invoiceId: data.invoice_id, val: false }, { root: true })
        if (rootState.invoice.dialog.invoice && rootState.invoice.dialog.invoice.id === data.invoice_id) {
          window.enrich.enrichEmail(data.email)
          commit('invoice/ADD_DIALOG_INVOICE_EMAIL', data.email, { root: true })
        }
      }
    })
  },
  subscribeUpdateData () {
    window.channel.stopListening('UpdateData').listen('UpdateData', function (data) {
      router.go(router.currentRoute) // Laddar om sidan för att återställa store, och hämta om data
    })
  },
  subscribeReceiptUpdated ({ dispatch }) {
    window.channel.stopListening('ReceiptUpdated').listen('ReceiptUpdated', function (data) {
      dispatch('posCheckout/getReceipt', data.receipt_id, { root: true })
    })
  },
  subscribeTerminalUpdated ({ commit, rootState, dispatch }) {
    window.channel.stopListening('TerminalUpdated').listen('TerminalUpdated', function (data) {
      console.log('TerminalUpdated - workstation_id:', data.workstation_id, 'message:', data.message, 'error:', data.error)
      if (rootState.workstation.id === data.workstation_id) {
        console.log('TerminalUpdated - Rätt workstation')
        commit('posTerminal/SET_TERMINAL_DISPLAY_TEXT', data.message, { root: true })
        if (data.error) {
          console.log('TerminalUpdated - Error', data.error)
          commit('posTerminal/SET_TERMINAL_REMAINING_TIME', null, { root: true })
          if (rootState.posTerminal.terminalCancelling) {
            // Specialfall där det är avbrutet av kassör, behöver inte visa felutskrift utan avbryter direkt
            commit('posCheckout/SET_ACTIVE_RECEIPT', null, { root: true }) // Kommer inte jobba vidare med detta kvitto
            commit('posTerminal/SET_TERMINAL_REMAINING_TIME', null, { root: true })
            commit('posCheckout/SET_IS_LOADING_PAYMENT', false, { root: true })
            commit('customer/SET_IS_LOADING_CUSTOMER_CREDIT_PAYMENT', false, { root: true })
            commit('invoice/SET_IS_LOADING_PAYMENT', false, { root: true })
            commit('invoice/SET_IS_LOADING_TERMINAL_PAYMENT', false, { root: true })
            commit('invoice/SET_IS_CREATING_INVOICE', false, { root: true })
            dispatch('posTerminal/resetStatusDialog', null, { root: true })
          } else {
            // Normalfallet där terminalen har returnerat ett felmeddelande
            // Innehåller display och print(content och object)
            commit('posTerminal/SET_TERMINAL_ERROR_MESSAGE', data.error.display, { root: true })
            commit('posTerminal/SET_TERMINAL_ERROR_PRINT', data.error.print, { root: true })
          }
          commit('posTerminal/SET_TERMINAL_CANCELLING', false, { root: true })
        }
      }
    })
  },
  subscribeCustomerCreditUpdated ({ commit, rootState }) {
    window.channel.stopListening('CustomerCreditUpdated').listen('CustomerCreditUpdated', function (data) {
      console.log('CustomerCreditUpdated - workstation_id:', data.workstation_id)
      if (data.workstation_id === rootState.workstation.id) {
        commit('customer/SET_IS_LOADING_CUSTOMER_CREDIT_PAYMENT', false, { root: true })
        commit('invoice/TRIGGER_CUSTOMER_CREDIT_UPDATED', null, { root: true })
      }
    })
  },
  subscribeInvoiceUpdated ({ dispatch, commit, rootState }) {
    window.channel.stopListening('InvoiceUpdated').listen('InvoiceUpdated', function (data) {
      console.log('InvoiceUpdated - invoice_id:', data.invoice_id)
      console.log('InvoiceUpdated - dialog invoice id:', rootState.invoice.dialog.invoice.id)
      if (data.invoice_id === rootState.invoice.dialog.invoice.id) {
        InvoiceService.getInvoice(data.invoice_id)
          .then(({ data }) => {
            window.enrich.enrichInvoice(data.data.invoice)
            commit('invoice/SET_DIALOG_INVOICE', data.data.invoice, { root: true })
          })
        commit('invoice/SET_IS_LOADING_PAYMENT', false, { root: true })
        commit('invoice/SET_IS_LOADING_TERMINAL_PAYMENT', false, { root: true })
        commit('invoice/TRIGGER_INVOICE_UPDATED', null, { root: true })
        commit('invoice/TRIGGER_CUSTOMER_CREDIT_UPDATED', null, { root: true })
        dispatch('posTerminal/resetStatusDialog', null, { root: true })
      }
    })
  },
  subscribeInvoiceDraftUpdated ({ dispatch, commit, rootState }) {
    window.channel.stopListening('InvoiceDraftUpdated').listen('InvoiceDraftUpdated', function (data) {
      console.log('InvoiceDraftUpdated - invoice_id:', data.invoice_draft_id)
      console.log('InvoiceDraftUpdated - dialog invoice id:', rootState.invoice.invoiceDraftId)
      if (data.invoice_draft_id === rootState.invoice.invoiceDraftId) {
        if (router.history.current.name === 'PosRegister') {
          // resettar varukorgen och kunden i kassan om fakturerat från kassan
          dispatch('posCart/reset', null, { root: true })
          commit('posRegister/SET_CUSTOMER', null, { root: true })
        }
        // öppnar customer eller booking dialog med faktura-tabben öppen
        if (rootState.invoice.shouldCheckOut) {
          router.push({ name: 'ActionsItems' })
        } else if (data.booking_id) {
          dispatch('booking/openDialogById', { bookingId: data.booking_id, startTab: 'invoices' }, { root: true })
        } else {
          dispatch('customer/openDialogById', { customerId: data.customer_id, startTab: 'invoices' }, { root: true })
        }
        commit('posCheckout/SET_IS_LOADING_PAYMENT', false, { root: true })
        commit('invoice/SET_IS_LOADING_TERMINAL_PAYMENT', false, { root: true })
        commit('invoice/TRIGGER_INVOICE_UPDATED', null, { root: true })
        commit('invoice/TRIGGER_CUSTOMER_CREDIT_UPDATED', null, { root: true })
        commit('invoice/SET_IS_CREATING_INVOICE', false, { root: true })
        dispatch('posTerminal/resetStatusDialog', null, { root: true })
      }
    })
  }
}
