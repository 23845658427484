<template>
  <div class="editing-record-timeline">
    <!-- Inga editingRecords meddelande -->
    <template v-if="!loading && editingRecords.length === 0">
      <div class="d-flex flex-column full-width">
        <div class="mx-auto mt-6">
          <v-icon large>mdi-ghost-outline</v-icon>
        </div>
        <p class="text-caption-2 text--secondary my-auto mx-auto py-6">
          Här var det tomt
        </p>
      </div>
    </template>

    <v-timeline
      v-else
      dense
      class="overflow-hidden"
      align-top
    >
      <template v-if="loading">
        <!-- Skeleton loaders -->
        <v-timeline-item
          color="secondary"
          fill-dot
          class="skeleton-loaders-timeline-item"
        >
          <template v-slot:icon>
            <v-skeleton-loader type="avatar"></v-skeleton-loader>
          </template>
          <v-row>
            <v-col class="">
              <v-skeleton-loader type="card-heading"></v-skeleton-loader>
            </v-col>
          </v-row>
        </v-timeline-item>
      </template>

      <template v-else>
        <!-- editingRecords -->
        <div
          v-for="timeGroups in editingRecords"
          :key="timeGroups.date"
        >
          <v-timeline-item
            class="pb-0"
            hide-dot
          >
            <span class="text-h6">{{ toHumanReadableDate(timeGroups.date) }}</span>
          </v-timeline-item>

          <v-timeline-item
            v-for="editingRecordGroup in timeGroups.editing_record_groups"
            :key="editingRecordGroup.time"
            fill-dot
            color="secondary"
          >
            <template v-slot:icon>
              <span class="white--text" style="font-size: .75em;">{{ editingRecordGroup.time }}</span>
            </template>
            <div class="pt-2">
              <ul>
                <li
                  v-for="editingRecord in editingRecordGroup.editing_records"
                  :key="editingRecord.id"
                  class=""
                >
                  <div v-html="editingRecord.description"></div>
                </li>
              </ul>
            </div>
          </v-timeline-item>
        </div>
      </template>

      <slot name="timeline-actions"></slot>

    </v-timeline>
  </div>
</template>

<script>

export default {
  name: 'EditingRecordTimeline',
  props: {
    editingRecords: {
      type: Array,
      default: () => []
    },
    loading: Boolean
  },
  data: () => ({}),
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mdAndDown
    }
  },
  methods: {
    toHumanReadableDate: function (dt) {
      return window.dayjs.parseServerDate(dt).toHumanReadableDate()
    }
  },
  watch: {}
}
</script>
