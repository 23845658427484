<template>
  <v-list-item>
    <customer-avatar
      :customer="booking.customer"
      large
      list-item
      clickable
      @open-customer="openCustomer"
    ></customer-avatar>

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-list-item-content
          @click="bookingClicked"
          v-bind="attrs"
          v-on="on"
          class="cursor-pointer parent-light-highlight-on-hover"
        >
          <v-list-item-title v-html="title"></v-list-item-title>
          <v-list-item-subtitle :class="{ 'highlighted-position-id': isHighlighted }" v-html="subtitle"></v-list-item-subtitle>
          <v-list-item-subtitle v-html="secondarySubtitle"></v-list-item-subtitle>
        </v-list-item-content>
      </template>
      <span>Visa bokning</span>
    </v-tooltip>

    <v-list-item-icon v-show="view === 'checkouts' && booking.auto_checkout_at !== null" class="ml-1 my-auto">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            v-bind="attrs"
            v-on="on"
          >
            coi-auto-checkout
          </v-icon>
        </template>
        <div class="pa-1">
          {{ autoCheckoutText }}
        </div>
      </v-tooltip>
    </v-list-item-icon>

    <v-list-item-icon v-show="view === 'checkins' && booking.expected_arrival_time !== null" class="ml-1 my-auto">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            v-bind="attrs"
            v-on="on"
          >
            mdi-clock-outline
          </v-icon>
        </template>
        <div class="pa-1">
          Förväntad ankomsttid {{ booking.expected_arrival_time }}
        </div>
      </v-tooltip>
    </v-list-item-icon>

    <v-list-item-icon v-show="hasCollision && !hideCollision" class="ml-1 my-auto">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            aria-hidden="false"
            color="warning"
            @click="clickedCollisionIcon"
            v-bind="attrs"
            v-on="on"
          >
            mdi-map-marker-alert-outline
          </v-icon>
        </template>
        <div class="pa-1">
          {{ view === 'checkins' ? 'Platsen är inte ledig än, klicka för att se bokningen som är incheckad på platsen nu' : 'En annan bokning ska checka in på platsen, klicka för att se bokningen som ska checka in' }}
        </div>
      </v-tooltip>
    </v-list-item-icon>

    <v-list-item-icon v-show="!!booking.requests" class="ml-1 my-auto">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            aria-hidden="false"
            class="d-flex justify-align"
            v-bind="attrs"
            v-on="on"
          >
            mdi-message-text-outline
          </v-icon>
        </template>
        <div class="pa-1">
          <h3>Meddelande från kunden:</h3>
          <i>{{ booking.requests }}</i>
        </div>
      </v-tooltip>
    </v-list-item-icon>

    <v-list-item-icon v-show="!!booking.remarks" class="ml-1 my-auto">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            aria-hidden="false"
            class="d-flex justify-align"
            v-bind="attrs"
            v-on="on"
          >
            mdi-tag-text-outline
          </v-icon>
        </template>
        <div class="pa-1">
          <h3>Bokningsanteckningar:</h3>
          <i>{{ booking.remarks }}</i>
        </div>
      </v-tooltip>
    </v-list-item-icon>

    <v-list-item-icon v-show="isDelayed" class="ml-1 my-auto">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            aria-hidden="false"
            class="d-flex justify-align"
            v-bind="attrs"
            v-on="on"
            color="error"
          >
            mdi-calendar-alert
          </v-icon>
        </template>
        <div class="pa-1">
          <span>{{ delayedText }}</span>
        </div>
      </v-tooltip>
    </v-list-item-icon>

    <v-list-item-icon v-if="!isMobile && (view === 'checkins' || view === 'checkouts')" class="ml-2 action-items">
      <div v-if="shouldCheckIn && view === 'checkins'">
        <v-btn
          ripple
          color="secondary darken-2"
          @click.stop="checkIn"
          dark
        >
          Checka In
        </v-btn>
      </div>
      <div v-else-if="shouldCheckOut && view === 'checkouts'">
        <v-btn
          ripple
          color="secondary darken-2"
          dark
          @click.stop="startCheckOut"
          class="btn-two-rows"
        >
          Påbörja utcheckning
        </v-btn>
      </div>
      <div v-else-if="shouldCheckOutTomorrow && view === 'checkouts'">
        <v-alert
          dense
          text
          color="info"
          class="my-0 text-subtitle-2 px-2"
        >
          Checkar ut imorgon
        </v-alert>
      </div>
      <div v-else-if="shouldCheckInTomorrow && view === 'checkins'">
        <v-alert
          dense
          text
          color="info"
          class="my-0 text-subtitle-2 px-2"
        >
          Checkar in imorgon
        </v-alert>
      </div>
      <div v-else-if="isCheckedIn">
        <v-alert
          dense
          text
          type="success"
          class="my-0 text-subtitle-2 px-2"
        >
          Incheckad
        </v-alert>
      </div>
      <div v-else-if="isCheckedOut">
        <v-alert
          dense
          text
          type="success"
          class="my-0 text-subtitle-2 px-2"
        >
          Utcheckad
        </v-alert>
      </div>

    </v-list-item-icon>
  </v-list-item>
</template>

<script>
import CustomerAvatar from '@/components/customer/Avatar.vue'

export default {
  name: 'BookingsListRow',
  components: {
    CustomerAvatar
  },
  props: {
    booking: Object,
    title: String,
    subtitle: String,
    secondarySubtitle: String,
    view: String,
    hasCollision: Boolean,
    highlightedPositionIds: Array
  },
  data: () => ({}),
  computed: {
    isCheckedIn: function () {
      return this.booking.checked_in_at !== null && this.booking.checked_out_at === null
    },
    isCheckedOut: function () {
      return this.booking.checked_out_at !== null
    },
    shouldCheckIn: function () {
      const today = window.dayjs.today()
      return this.booking.checked_in_at === null && this.booking.check_in.isSameOrBefore(today)
    },
    shouldCheckOut: function () {
      const today = window.dayjs.today()
      return this.isCheckedIn && this.booking.check_out.isSameOrBefore(today)
    },
    shouldCheckInTomorrow: function () {
      const tomorrow = window.dayjs.tomorrow()
      return this.booking.checked_in_at === null && this.booking.check_in.isSame(tomorrow)
    },
    shouldCheckOutTomorrow: function () {
      const tomorrow = window.dayjs.tomorrow()
      return !this.isCheckedOut && this.booking.check_out.isSame(tomorrow)
    },
    hideCollision: function () {
      return (this.view === 'checkins' && !this.shouldCheckIn) || (this.view === 'checkouts' && !this.shouldCheckOut)
    },
    isHighlighted: function () {
      return !this.hideCollision && this.highlightedPositionIds && this.highlightedPositionIds.includes(this.booking.position_id)
    },
    isDelayed: function () {
      if (this.isCheckedIn) {
        return this.booking.check_out.isBefore(window.dayjs.today(), 'date')
      } else {
        return this.booking.check_in.isBefore(window.dayjs.today(), 'date')
      }
    },
    delayedText: function () {
      if (this.isCheckedIn && this.booking.check_out.isBefore(window.dayjs.today(), 'date')) {
        if (this.booking.check_out.isYesterday()) {
          return 'Denna bokning skulle checkat ut igår'
        } else {
          return 'Denna bokning skulle checkat ut ' + this.booking.check_out.format('ddd, D MMM, YYYY')
        }
      } else if (this.booking.check_in.isBefore(window.dayjs.today(), 'date')) {
        if (this.booking.check_in.isYesterday()) {
          return 'Denna bokning skulle checkat in igår'
        } else {
          return 'Denna bokning skulle checkat in ' + this.booking.check_in.format('ddd, D MMM, YYYY')
        }
      } else {
        return ''
      }
    },
    autoCheckoutText: function () {
      if (this.booking.auto_checkout_at === null) {
        return ''
      }
      const time = this.booking.auto_checkout_at.format('HH:mm')
      return `Checkas ut automatiskt kl. ${time}`
    },
    isMobile: function () {
      return this.$vuetify.breakpoint.mdAndDown
    }
  },
  methods: {
    startCheckOut: function () {
      this.$store.dispatch('booking/startCheckOut', { booking: this.booking })
    },
    checkIn: function () {
      this.$store.dispatch('booking/checkIn', this.booking.id)
    },
    bookingClicked: function () {
      this.$store.dispatch('booking/openDialogById', { bookingId: this.booking.id })
    },
    openCustomer: function (customer) {
      this.$store.dispatch('customer/openDialogById', { customerId: customer.id })
    },
    clickedCollisionIcon: function () {
      const view = this.view === 'checkins' ? 'checkouts' : 'checkins'
      const positionId = this.booking.position.id
      this.$emit('highlight-position-id', positionId)
      const collidingElement = document.getElementById(`bookings-list-${view}-${positionId}`)
      collidingElement.scrollIntoView({ behavior: 'smooth' })
    }
  }
}
</script>
