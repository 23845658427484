<template>
  <v-card class="customer-credit-payment d-flex flex-column full-height px-4 justify-start" flat tile>
    <div class="mt-2" style="min-height: 120px;">
      <v-form
        @submit.prevent
        ref="formAmount"
      >
        <v-text-field
          v-model="inputAmount"
          :label="amountLabel"
          append-icon="coi-currency-sek"
          outlined
          type="number"
          :hide-details="!amountValidation.hasWarning && !amountValidation.hasError"
          :error-messages="amountValidation.hasError || amountValidation.hasWarning ? [amountValidation.errorMessage] : []"
          :class="{ 'sub-errors-for-warning': amountValidation.hasWarning, 'mb-10': isIncomingCustomerCreditPayment }"
        ></v-text-field>
      </v-form>
      <v-chip
        v-if="!isIncomingCustomerCreditPayment"
        :outlined="!isFullAmount"
        :class="{ 'mt-2': !amountValidation.hasWarning && !amountValidation.hasError }"
        color="secondary"
        @click="selectFullAmount"
      >
        Återbetala hela tillgodo
      </v-chip>
    </div>
    <div class="mt-6 py-4">
      <payment-methods
        v-model="selectedPaymentMethod"
        :buttons-shown="2"
        :is-loading="isLoadingPayment"
        @payment-method-selected="startPayment"
      ></payment-methods>
    </div>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'

import PaymentMethods from '@/components/register/PaymentMethods.vue'

export default {
  name: 'CustomerCreditPayment',
  components: {
    PaymentMethods
  },
  props: {
    customer: Object,
    customerCreditAmount: Number,
    isIncomingCustomerCreditPayment: Boolean,
    isOpen: Boolean
  },
  data: () => ({
    selectedPaymentMethod: null,
    selectedAmount: 0,
    isStrongValidation: false,
    amountValidation: {
      hasError: false,
      hasWarning: false,
      errorMessage: ''
    }
  }),
  computed: {
    ...mapState(
      {
        isLoadingPayment: state => state.customer.isLoadingCustomerCreditPayment,
        triggerRetryCustomerCreditPayment: state => state.customer.triggerRetryCustomerCreditPayment,
        terminalErrorMessage: state => state.posTerminal.terminalErrorMessage,
        triggerCustomerCreditUpdated: state => state.invoice.triggerCustomerCreditUpdated
      }
    ),
    inputAmount: {
      get: function () {
        return this.selectedAmount / 100
      },
      set: function (val) {
        let amount = Math.round(val * 100)
        if (Number.isNaN(amount)) {
          amount = 0
        }
        // amount = Math.sign(amount) * Math.round(Math.abs(amount / 100)) * 100
        this.selectedAmount = amount
      }
    },
    amountLabel: function () {
      if (this.isIncomingCustomerCreditPayment) {
        // fakturor eller kontantfaktura
        return 'Att betala'
      } else {
        return 'Att återbetala'
      }
    },
    isFullAmount: function () {
      return this.customerCreditAmount === this.selectedAmount
    }
  },
  methods: {
    close: function () {
      this.$emit('close')
    },
    selectFullAmount: function () {
      this.selectedAmount = this.customerCreditAmount
    },
    startPayment: function () {
      this.isStrongValidation = true
      if (!this.validateAmount()) {
        return false
      }

      if (this.selectedPaymentMethod && (this.selectedPaymentMethod.gateway === 'invoicing' || this.selectedPaymentMethod.gateway === 'customer_credit')) {
        // Ska inte kunna fakturera härifrån, betalmetoden visas inte, men för en extra kontroll
        return false
      }

      let amount = this.selectedAmount
      if (!this.isIncomingCustomerCreditPayment) {
        // skickar negativ amount om utbetalning
        amount = amount * -1
      }
      this.$store.dispatch('customer/initCustomerCreditPayment', {
        customer: this.customer,
        paymentMethod: this.selectedPaymentMethod,
        amount
      })
    },
    resetForm: function () {
      this.isStrongValidation = false
      this.selectedAmount = 0
      this.selectedPaymentMethod = null
      this.validateAmount()
    },
    validateAmount: function () {
      const amount = this.selectedAmount
      this.amountValidation.hasError = false
      this.amountValidation.hasWarning = false

      if (amount === '' || amount === 0) {
        if (this.isStrongValidation) {
          this.amountValidation.errorMessage = 'Fyll i ett belopp'
          this.amountValidation.hasError = true
          return false
        }
        return true
      }
      const n = Number.parseFloat(amount)
      if (Number.isNaN(n)) {
        this.amountValidation.errorMessage = 'Beloppet måste vara ett tal'
        this.amountValidation.hasError = true
        return false
      }
      if (amount <= 0) {
        this.amountValidation.errorMessage = 'Beloppet måste vara positivt'
        this.amountValidation.hasError = true
        return false
      }
      if (amount % 100 !== 0 && amount !== this.customerCreditAmount) {
        // Släpper igenom ojämnt ifall det är hela kundkreditbeloppet, för känns som det är bra att de kan rensa hela tillgodon
        // innebär att de kan göra in- och utbetalningar av tillgodo med ören kontant också, men vi släpper det
        this.amountValidation.errorMessage = 'Beloppet måste vara hela kronor'
        this.amountValidation.hasError = true
        return false
      }
      if (!this.isIncomingCustomerCreditPayment && amount > this.customerCreditAmount) {
        // utbetalning av kundkredit och beloppet överstiger tillgodo
        this.amountValidation.errorMessage = 'Utbetalningen kan inte vara större än det tillgängliga beloppet tillgodo'
        this.amountValidation.hasError = true
        return false
      }
      return true
    }
  },
  watch: {
    isOpen: function () {
      if (!this.isOpen) {
        // återställer formuläret när componenten stängs
        this.resetForm()
      }
    },
    selectedAmount: function () {
      this.validateAmount()
    },
    isIncomingCustomerCreditPayment: function () {
      this.validateAmount()
    },
    triggerRetryCustomerCreditPayment: function () {
      this.startPayment()
    },
    triggerCustomerCreditUpdated: function () {
      this.close()
    }
  }
}
</script>
