import Vue from 'vue'
import store from '@/store'

function toArray (val) {
  let arr = []
  if ((typeof val) === 'object') {
    if (Array.isArray(val)) {
      arr = val
    } else {
      arr.push(val)
    }
  } else {
    console.error('toArray: Unexpected datatype')
  }
  return arr
}

const enrichBooking = function (val, appends) {
  const bookings = toArray(val)
  window.conversion.convertDates('Booking', bookings)
  bookings.forEach(booking => {
    booking.title = booking.type === 'season' ? `Säsongsbokning ${booking.booking_number}` : `Bokning ${booking.booking_number}`
    booking.daysFormatted = window.conversion.diffInDays(booking.check_in, booking.check_out)
    if (booking.season_id) {
      booking.season = store.state.season.seasons.find(season => season.id === booking.season_id)
    }
    if (booking.season) {
      booking.subtitle = `${booking.season.full_name}, ${booking.daysFormatted}`
    } else {
      booking.subtitle = booking.daysFormatted
    }
    booking.position = store.state.position.positions.find(pos => pos.id === booking.position_id)
    booking.category = store.state.position.categories.find(cat => cat.id === booking.category_id)
    if (booking.cancellation_policy_id) {
      booking.cancellationPolicy = store.state.cancellationPolicy.cancellationPolicies.find(policy => policy.id === booking.cancellation_policy_id)
    }
    booking.lodgingType = store.state.position.lodgingTypes.find(type => type.id === booking.lodging_type_id)
    if (booking.customer) {
      enrichCustomer(booking.customer)
    } else if (appends && appends.customer) {
      booking.customer = appends.customer
    }
    if (booking.expected_arrival_time !== null) {
      booking.expected_arrival_time = booking.expected_arrival_time.slice(0, 5)
    }
    booking.isEnriched = true
  })
}

const enrichInvoice = function (val) {
  const invoices = toArray(val)
  window.conversion.convertDates('Invoice', invoices)
  invoices.forEach(invoice => {
    if (invoice.payment_term_id) {
      invoice.paymentTerm = store.state.invoice.paymentTerms.find(pt => pt.id === invoice.payment_term_id)
    }
    if (invoice.customer) {
      enrichCustomer(invoice.customer)
    }
    if (invoice.emails) {
      enrichEmail(invoice.emails)
    }

    const today = window.dayjs.today()
    invoice.isOverdue = !invoice.is_credit_invoice && invoice.status !== 'closed' && invoice.due_date.isBefore(today)
    invoice.isOverdueDeposit = !invoice.is_credit_invoice && invoice.status !== 'closed' && invoice.deposit_amount && invoice.paid_amount < invoice.deposit_amount && invoice.deposit_due_date.isBefore(today)

    if (invoice.isOverdue) {
      invoice.statusText = 'Förfallen'
      invoice.statusColor = 'error'
    } else if (invoice.isOverdueDeposit) {
      invoice.statusText = 'Förfallen handpenning'
      invoice.statusColor = 'error'
    } else if (invoice.status === 'open') {
      invoice.statusText = 'Obetald'
      invoice.statusColor = 'info'
    } else if (invoice.status === 'partial') {
      invoice.statusText = 'Delbetald'
      invoice.statusColor = 'info'
    } else if (invoice.status === 'closed') {
      invoice.statusText = 'Betald'
      invoice.statusColor = 'success'
    }

    invoice.isEnriched = true
  })
}

const enrichCountry = function (val) {
  const countries = toArray(val)
  countries.forEach(country => {
    country.searchableString = `${country.dialling_code}${country.name_en.toLowerCase()}${country.name_sv.toLowerCase()}`
    country.isEnriched = true
  })
}

const enrichCustomerCreditTimelineItem = function (val) {
  const customerCreditTimelineItems = toArray(val)
  customerCreditTimelineItems.forEach(customerCreditTimelineItem => {
    customerCreditTimelineItem.paymentMethod = store.state.posCheckout.paymentMethods.find(pm => pm.id === customerCreditTimelineItem.payment_method_id)
    customerCreditTimelineItem.date = window.dayjs.parseServerDateTime(customerCreditTimelineItem.date)
    customerCreditTimelineItem.isEnriched = true
  })
}

const enrichPosition = function (val) {
  const positions = toArray(val)
  positions.forEach(position => {
    position.category = store.state.position.categories.find(cat => cat.id === position.category_id)
    position.map_x = parseFloat(position.map_x)
    position.map_y = parseFloat(position.map_y)
    position.width_percent = `${(position.width * 100)}%`
    position.height_percent = `${(position.height * 100)}%`
    position.border_radius_percent = (position.border_radius * 100) + '%'
    position.svg_rect_id = `O-rect-${position.id}`
    position.svg_rect_rx = (position.border_radius * position.width * 100) + '%'
    position.svg_rect_clip_path_id = `O-clip-${position.id}`
    position.svg_rect_clip_path_href = `#O-rect-${position.id}`
    position.svg_clip_path_id = `O-inner-clip-${position.id}`
    position.svg_text_x = (position.width * 50) + '%'
    position.svg_text_y = (position.height * 53) + '%'
    position.svg_text_font_size = fontSize(position.map_name.length, position.width)
    position.svg_text_clip_path = `url(#O-clip-${position.id})`
    position.svg_polygon_clip_path = `url(#O-inner-clip-${position.id})`
    position.isEnriched = true
  })
}

const fontSize = function (textLength, containerWidth) {
  if (textLength === 0) {
    return '0'
  }
  if (textLength === 1) {
    textLength = 1.3 // texten är ofta lite för stor när det bara är ett tecken, så gör fontsize lite mindre specifikt när det är ett tecken
  }
  let size = containerWidth * 100 / textLength * 12
  size = Math.max(Math.min(size, 14), 3)
  return `${size}px`
}

const enrichBankgiroImport = function (val) {
  const bankgiroImports = toArray(val)
  window.conversion.convertDates('BankgiroImport', bankgiroImports)
  bankgiroImports.forEach(bankgiroImport => {
    bankgiroImport.bankgiro_payments.forEach(bankgiroPayment => {
      this.enrichCustomer(bankgiroPayment.suggested_customers)
      if (bankgiroPayment.payment) {
        this.enrichCustomer(bankgiroPayment.payment.customer)
        bankgiroPayment.payment.payment_applications.forEach(paymentApplication => {
          this.enrichInvoice(paymentApplication.applicable)
        })
      }
    })
    bankgiroImport.isEnriched = true
  })
}

const enrichCategory = function (val) {
  const categories = toArray(val)
  categories.forEach(category => {
    category.lodgingTypes = store.state.position.lodgingTypes.filter(type => category.lodging_type_ids.includes(type.id))
    category.cancellationPolicies = store.state.cancellationPolicy.cancellationPolicies.filter(policy => category.cancellation_policy_ids.includes(policy.id))
    category.positions = store.state.position.positions.filter(pos => pos.category_id === category.id)
    category.defaultLodgingType = store.state.position.lodgingTypes.find(type => type.id === category.default_lodging_type_id)
    category.isEnriched = true
  })
}

const enrichSeason = function (val) {
  const seasons = toArray(val)
  window.conversion.convertDates('Season', seasons)
  seasons.forEach(season => {
    season.custom_season_prices.forEach((customSeasonPrice) => {
      if (customSeasonPrice.category_ids) {
        customSeasonPrice.category_ids = JSON.parse(customSeasonPrice.category_ids)
      } else {
        customSeasonPrice.category_ids = []
      }
      if (customSeasonPrice.position_ids) {
        customSeasonPrice.position_ids = JSON.parse(customSeasonPrice.position_ids)
      } else {
        customSeasonPrice.position_ids = []
      }
    })
    const diffInDays = window.conversion.diffInDays(season.start, season.end)
    season.subtitle = `${season.start.format('D MMM YYYY')} - ${season.end.format('D MMM YYYY')} (${diffInDays})`
    season.isEnriched = true
  })
}

const enrichCustomer = function (val) {
  const customers = toArray(val)
  customers.forEach(customer => {
    const country = store.state.country.countries.find(country => country.id === customer.country_id)
    Vue.set(customer, 'country', country)
    const diallingCodeCountry = store.state.country.countries.find(country => country.id === customer.dialling_code_country_id)
    Vue.set(customer, 'diallingCodeCountry', diallingCodeCountry)

    if (customer.diallingCodeCountry && customer.phone_number) {
      Vue.set(customer, 'fullPhoneNumber', `${customer.diallingCodeCountry.dialling_code} ${customer.phone_number}`)
    } else {
      Vue.set(customer, 'fullPhoneNumber', '')
    }

    let shortAddress = ''
    if (customer.street_name) {
      shortAddress += customer.street_name
      if (customer.street_number) {
        shortAddress += ' ' + customer.street_number + ', '
      } else {
        shortAddress += ', '
      }
    }
    if (customer.city) {
      shortAddress += customer.city + ', '
    }
    if (customer.country) {
      shortAddress += customer.country.name
    }
    Vue.set(customer, 'shortAddress', shortAddress)

    Vue.set(customer, 'isEnriched', true)
  })
}

const enrichProduct = function (val) {
  const products = toArray(val)
  products.forEach(product => {
    const productGroup = store.state.product.productGroups.find(productGroup => productGroup.id === product.product_group_id)
    Vue.set(product, 'productGroup', productGroup)
    product.isEnriched = true
  })
}

const enrichProductGroup = function (val) {
  const productGroups = toArray(val)
  productGroups.forEach(productGroup => {
    const productGroupProducts = store.state.product.products.filter(product => product.product_group_id === productGroup.id)
    Vue.set(productGroup, 'products', productGroupProducts)
    productGroup.isEnriched = true
  })
}

const enrichProductLayoutTab = function (val) {
  const productLayoutTabs = toArray(val)
  productLayoutTabs.forEach(productLayoutTab => {
    let maxRow = 1
    productLayoutTab.product_layout_buttons.forEach(productLayoutButton => {
      productLayoutButton.products = store.state.product.products.filter(product => productLayoutButton.product_ids.includes(product.id))
      maxRow = Math.max(maxRow, productLayoutButton.row)
    })
    productLayoutTab.row_count = Math.max((maxRow + 1), 7) // row_count = sista rad med en knapp (+1), men minst 7, döpte den till row_count istället för rowCount eftersom objektet har attributet column_count från databasen
    Vue.set(productLayoutTab, 'matrix', {})
    for (let y = 1; y <= productLayoutTab.row_count; y++) {
      const column = {}
      for (let x = 1; x <= productLayoutTab.column_count; x++) {
        const button = productLayoutTab.product_layout_buttons.find(p => p.column === x && p.row === y)
        if (store.state.product.isLoadingSaveLayoutButtonButtons.find(btn => btn.product_layout_tab_id === productLayoutTab.id && btn.row === y && btn.column === x) != null) {
          column[x] = 'loading'
        } else if (!button) {
          column[x] = null
        } else if (button.type === 'button' || button.type === 'menu') {
          column[x] = button
        } else {
          // Ska normalt inte gå hit
          column[x] = null
        }
      }
      Vue.set(productLayoutTab.matrix, y, column) // För att göra det reaktivt
    }
    productLayoutTab.slug = window.conversion.slugify(`${productLayoutTab.id}-${productLayoutTab.title}`)
    productLayoutTab.isEnriched = true
  })
}

const enrichPaymentTerm = function (val) {
  const paymentTerms = toArray(val)
  paymentTerms.forEach(paymentTerm => {
    Object.assign(paymentTerm, require('@/mixins/paymentTerm.js').default)
  })
}

const enrichOrder = function (val) {
  const orders = toArray(val)
  window.conversion.convertDates('ParkedOrder', orders)
  orders.forEach(order => {
    Object.assign(order, require('@/mixins/order.js').default)
    enrichCharge(order.charges)
    order.total = order.getTotal()
    order.isEnriched = true
  })
}

const enrichCharge = function (val) {
  const charges = toArray(val)
  window.conversion.convertDates('Charge', charges)
  charges.forEach(charge => {
    // appendar product om charge inte redan har product, i regel borde det appendas på servern eftersom client bara har icke-borttagna produkter
    if (!charge.product) {
      charge.product = store.state.product.products.find(prod => prod.id === charge.product_id)
    }
    charge.position = store.state.position.positions.find(pos => pos.id === charge.position_id)
    charge.isEnriched = true
  })
}

const enrichReceipt = function (val) {
  const receipts = toArray(val)
  window.conversion.convertDates('Receipt', receipts)
  receipts.forEach(receipt => {
    // VIKTIGT: vi får anta att product på receipt kan vara raderad, client känner bara till icke raderade produkter, så server får appenda product på charges istället
    if (receipt.customer) {
      enrichCustomer(receipt.customer)
    }
    receipt.isEnriched = true
  })
}

const enrichEmail = function (val) {
  const emails = toArray(val)
  window.conversion.convertDates('Email', emails)
  emails.forEach(email => {
    email.is_opened = !!email.opened_at
    email.is_sent = !!email.sent_at
    email.isEnriched = true
  })
}

const enrichBookExport = function (val) {
  const bookExports = toArray(val)
  window.conversion.convertDates('BookExport', bookExports)
  bookExports.forEach(bookExport => {
    bookExport.isEnriched = true
  })
}

const enrichCalendarItem = function (val) {
  const calendarItems = toArray(val)
  window.conversion.convertDates('CalendarItem', calendarItems)
  calendarItems.forEach(calendarItem => {
    calendarItem.is_mid_booking_inclusive = calendarItem.is_mid_booking || calendarItem.is_checkin_day || calendarItem.is_checkout_day
    if (calendarItem.accommodation_charge) {
      calendarItem.accommodation_charge.position = store.state.position.positions.find(pos => pos.id === calendarItem.accommodation_charge.position_id) // OBS: platsen kan vara borttagen
      calendarItem.accommodation_charge.product = store.state.product.products.find(prod => prod.id === calendarItem.accommodation_charge.product_id) // OBS: produkten kan vara borttagen
      calendarItem.accommodation_charge.nights = calendarItem.accommodation_charge.end.diff(calendarItem.accommodation_charge.start, 'days')
    }
    if (calendarItem.suggested_accommodation_charge) {
      calendarItem.suggested_accommodation_charge.position = store.state.position.positions.find(pos => pos.id === calendarItem.suggested_accommodation_charge.position_id) // OBS: platsen kan vara borttagen
      calendarItem.suggested_accommodation_charge.product = store.state.product.products.find(prod => prod.id === calendarItem.suggested_accommodation_charge.product_id) // OBS: produkten kan vara borttagen
      calendarItem.suggested_accommodation_charge.nights = calendarItem.suggested_accommodation_charge.end.diff(calendarItem.suggested_accommodation_charge.start, 'days')

      if (calendarItem.suggested_accommodation_charge.nights === 1) {
        const startDate = calendarItem.suggested_accommodation_charge.start.format('D MMM YYYY')
        calendarItem.suggested_accommodation_charge.text_line_1 = `${calendarItem.suggested_accommodation_charge.price / 100}kr, ${startDate}`
      } else {
        const startDate = calendarItem.suggested_accommodation_charge.start.format('D MMM YYYY')
        const endDate = calendarItem.suggested_accommodation_charge.end.format('D MMM YYYY')
        calendarItem.suggested_accommodation_charge.text_line_1 = `${calendarItem.suggested_accommodation_charge.price / 100}kr, ${startDate} - ${endDate}`
      }

      if (calendarItem.suggested_accommodation_charge.position) {
        calendarItem.suggested_accommodation_charge.text_line_2 = `${calendarItem.suggested_accommodation_charge.note}, ${calendarItem.suggested_accommodation_charge.position.full_name}`
      } else {
        calendarItem.suggested_accommodation_charge.text_line_2 = `${calendarItem.suggested_accommodation_charge.note}`
      }
    }
    calendarItem.isEnriched = true
  })
}

const enrichPositionBlock = function (val) {
  const positionBlocks = toArray(val)
  window.conversion.convertDates('PositionBlock', positionBlocks)
  positionBlocks.forEach(positionBlock => {
    positionBlock.position = store.state.position.positions.find(pos => pos.id === positionBlock.position_id)
    positionBlock.isEnriched = true
  })
}

const enrichSalesReport = function (val) {
  const salesReports = toArray(val)
  window.conversion.convertDates('SalesReport', salesReports)
  salesReports.forEach(salesReport => {
    salesReport.title = salesReport.sales_source === 'total' ? 'Total försäljning' : salesReport.sales_source === 'invoicing' ? 'Fakturerad försäljning' : `Kassaregister ${salesReport.register_name}`
    salesReport.isEnriched = true
  })
}

export default {
  enrichBooking,
  enrichInvoice,
  enrichPosition,
  enrichCountry,
  enrichCategory,
  enrichCustomer,
  enrichEmail,
  enrichProduct,
  enrichProductGroup,
  enrichProductLayoutTab,
  enrichPaymentTerm,
  enrichOrder,
  enrichCharge,
  enrichReceipt,
  enrichCalendarItem,
  enrichPositionBlock,
  enrichSalesReport,
  enrichBankgiroImport,
  enrichBookExport,
  enrichCustomerCreditTimelineItem,
  enrichSeason
}
