<template>
  <div class="position-block-form full-height d-flex flex-column">
    <v-card-text class="flex-grow-1">
      <!-- closing date -->
      <div class="mb-6">
        <v-menu
          v-model="showClosingDatePickerMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="closingPickerDate"
              label="Stängd från"
              prepend-icon="mdi-map-marker-off"
              readonly
              v-bind="attrs"
              v-on="on"
              persistent-hint
              hint="Första dagen då platsen inte går att boka"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="closingPickerDate"
            :first-day-of-week="1"
            @input="showClosingDatePickerMenu = false"
          ></v-date-picker>
        </v-menu>
      </div>

      <!-- opening date -->
      <div class="mb-6">
        <v-menu
          v-model="showOpeningDatePickerMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="openingPickerDate"
              label="Öppnar igen"
              prepend-icon="mdi-map-marker-check"
              readonly
              v-bind="attrs"
              v-on="on"
              persistent-hint
              hint="Första dagen då platsen åter går att boka"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="openingPickerDate"
            :first-day-of-week="1"
            @input="showOpeningDatePickerMenu = false"
          ></v-date-picker>
        </v-menu>
      </div>

      <!-- reason -->
      <v-textarea
        v-model="positionBlock.reason"
        outlined
        label="Anteckning"
        hint="Denna anteckning visas inte i bokningsportalen"
        prepend-icon="mdi-text-box-edit"
        counter="255"
        auto-grow
        rows="3"
      ></v-textarea>

    </v-card-text>

    <v-card-actions class="pa-4 mt-auto">
      <v-spacer></v-spacer>
      <v-btn color="success" @click="save" :loading="isSaving">Spara</v-btn>
    </v-card-actions>
  </div>
</template>

<script>

export default {
  name: 'PositionBlockForm',
  components: {},
  props: {
    value: Object,
    isSaving: Boolean
  },
  data: () => ({
    showClosingDatePickerMenu: false,
    showOpeningDatePickerMenu: false
  }),
  computed: {
    positionBlock: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    subtitle: function () {
      return this.positionBlock.position.full_name
    },
    closingPickerDate: {
      get: function () {
        return this.positionBlock.closing_date.format('YYYY-MM-DD')
      },
      set: function (val) {
        const newDate = window.dayjs.parseServerDate(val)
        this.positionBlock.closing_date = newDate
        if (!this.positionBlock.opening_date.isAfter(newDate)) {
          // Uppdaterar stängningsdatum om det inte är före nya öppningsdatumet
          this.positionBlock.opening_date = newDate.add(1, 'days')
        }
      }
    },
    openingPickerDate: {
      get: function () {
        return this.positionBlock.opening_date.format('YYYY-MM-DD')
      },
      set: function (val) {
        const newDate = window.dayjs.parseServerDate(val)
        this.positionBlock.opening_date = newDate
        if (!this.positionBlock.closing_date.isBefore(newDate)) {
          // Uppdaterar stängningsdatum om det inte är före nya öppningsdatumet
          this.positionBlock.closing_date = newDate.add(-1, 'days')
        }
      }
    }
  },
  methods: {
    close: function () {
      this.$emit('close')
    },
    save: function () {
      this.$store.dispatch('position/savePositionBlock', { positionBlock: this.positionBlock })
    }
  }
}
</script>
