import StatisticsService from '@/services/StatisticsService.js'
import DashboardService from '@/services/DashboardService.js'
export const namespaced = true

export const state = {
  dashboards: [],
  isFetchingDashboards: false,
  dashboardCards: [],
  isFetchingDashboardCards: false,
  activeDashboard: null,

  scbExports: [],
  isFetchingScbExports: false,
  isConfirmingScbExportIds: [],
  isCreatingScbExportMonths: [],
  hasTvvKey: false,
  isFetchingCategoryOccupation: false,
  isFetchingCategoryOccupationSilently: false,
  fetchingCategoryOccupationHasError: false,
  categoryOccupationData: null
}

export const mutations = {
  SET_DASHBOARDS (state, val) {
    val.forEach(dashboard => {
      dashboard.dashboard_cards.forEach(dashboardCard => {
        if ((typeof dashboardCard.pivot.settings) === 'string') {
          dashboardCard.pivot.settings = JSON.parse(dashboardCard.pivot.settings)
        }
      })
    })
    state.dashboards = val
  },
  ADD_DASHBOARD (state, val) {
    val.dashboard_cards.forEach(dashboardCard => {
      if ((typeof dashboardCard.pivot.settings) === 'string') {
        dashboardCard.pivot.settings = JSON.parse(dashboardCard.pivot.settings)
      }
    })
    state.dashboards.push(val)
  },
  DELETE_DASHBOARD (state, val) {
    const i = state.dashboards.findIndex(dashboard => dashboard.id === val)
    state.dashboards.splice(i, 1)
  },
  SET_IS_FETCHING_DASHBOARDS (state, val) {
    state.isFetchingDashboards = val
  },
  SET_DASHBOARD_CARDS (state, val) {
    state.dashboardCards = val
  },
  SET_IS_FETCHING_DASHBOARD_CARDS (state, val) {
    state.isFetchingDashboardCards = val
  },
  SET_ACTIVE_DASHBOARD (state, val) {
    state.activeDashboard = val
    localStorage.setItem('LOCAL_SETTING_ACTIVE_DASHBOARD_ID', val.id ?? null)
  },
  SET_SCB_EXPORTS (state, val) {
    state.scbExports = val
  },
  SET_HAS_TVV_KEY (state, val) {
    state.hasTvvKey = val
  },
  REPLACE_SCB_EXPORT (state, val) {
    const i = state.scbExports.findIndex(month => month.export && month.export.id === val.id)
    const newMonth = state.scbExports[i]
    newMonth.export = val
    // state.scbExports.splice(i, 1, newMonth)
  },
  SET_IS_FETCHING_SCB_EXPORTS (state, val) {
    state.isFetchingScbExports = val
  },
  SET_IS_CONFIRMING_SCB_EXPORT_IDS (state, payload) {
    if (payload.val) {
      const i = state.isConfirmingScbExportIds.findIndex(id => id === payload.scbExportId)
      if (i < 0) {
        state.isConfirmingScbExportIds.push(payload.scbExportId)
      }
    } else {
      const i = state.isConfirmingScbExportIds.findIndex(id => id === payload.scbExportId)
      state.isConfirmingScbExportIds.splice(i, 1)
    }
  },
  SET_IS_CREATING_SCB_EXPORT_MONTHS (state, payload) {
    if (payload.val) {
      const i = state.isCreatingScbExportMonths.findIndex(month => month === payload.month.format('YYYY-MM'))
      if (i < 0) {
        state.isCreatingScbExportMonths.push(payload.month.format('YYYY-MM'))
      }
    } else {
      const i = state.isCreatingScbExportMonths.findIndex(month => month === payload.month.format('YYYY-MM'))
      state.isCreatingScbExportMonths.splice(i, 1)
    }
  },
  SET_IS_FETCHING_CATEGORY_OCCUPATION (state, val) {
    state.isFetchingCategoryOccupation = val
  },
  SET_IS_FETCHING_CATEGORY_OCCUPATION_SILENTLY (state, val) {
    state.isFetchingCategoryOccupationSilently = val
  },
  SET_CATEGORY_OCCUPATION_DATA (state, val) {
    state.categoryOccupationData = val
  },
  SET_FETCHING_CATEGORY_OCCUPATION_HAS_ERROR (state, val) {
    state.fetchingCategoryOccupationHasError = val
  }
}

export const actions = {
  fetchDashboards ({ commit, dispatch, rootState }) {
    commit('SET_IS_FETCHING_DASHBOARDS', true)
    return DashboardService.getDashboards()
      .then(({ data }) => {
        // sätter activeDashboard baserat på id sparat i localStorage
        const localStorageActiveDashboardId = localStorage.getItem('LOCAL_SETTING_ACTIVE_DASHBOARD_ID')
        const activeDashboardId = parseInt(localStorageActiveDashboardId, 10)
        let activeDashboard = null
        if (!isNaN(activeDashboardId)) {
          activeDashboard = data.data.dashboards.find(db => db.id === activeDashboardId)
        }
        if (!activeDashboard && data.data.dashboards.length > 0) {
          // om ingen id sparad i localstorage eller dashboard inte längre finns, sätt till första i dashboard arrayen
          activeDashboard = data.data.dashboards[0]
        }
        commit('SET_DASHBOARDS', data.data.dashboards)
        commit('SET_ACTIVE_DASHBOARD', activeDashboard)
      })
      .finally(() => {
        commit('SET_IS_FETCHING_DASHBOARDS', false)
        window.channel.stopListening('DashboardsUpdated').listen('DashboardsUpdated', function (data) {
          const updatingWorkstationId = parseInt(data.workstation_id, 10)
          if (updatingWorkstationId !== rootState.workstation.id) {
            dispatch('refetchDashboards')
          }
        })
      })
  },
  refetchDashboards ({ state, commit }) {
    commit('SET_IS_FETCHING_DASHBOARDS', true)
    return DashboardService.getDashboards()
      .then(({ data }) => {
        const activeDashboardId = state.activeDashboard?.id
        let activeDashboard = null
        if (activeDashboardId && data.data.dashboards.length > 0) {
          activeDashboard = data.data.dashboards.find(d => d.id === activeDashboardId)
        }
        if (!activeDashboard && data.data.dashboards.length > 0) {
          activeDashboard = data.data.dashboards[0]
        }
        commit('SET_ACTIVE_DASHBOARD', activeDashboard)
        commit('SET_DASHBOARDS', data.data.dashboards)
      })
      .finally(() => {
        commit('SET_IS_FETCHING_DASHBOARDS', false)
      })
  },
  fetchDashboardCards ({ commit }) {
    commit('SET_IS_FETCHING_DASHBOARD_CARDS', true)
    return DashboardService.getDashboardCards()
      .then(({ data }) => {
        commit('SET_DASHBOARD_CARDS', data.data.dashboard_cards)
      })
      .finally(() => {
        commit('SET_IS_FETCHING_DASHBOARD_CARDS', false)
      })
  },
  getScbExports ({ commit }) {
    commit('SET_SCB_EXPORTS', [])
    commit('SET_IS_FETCHING_SCB_EXPORTS', true)
    StatisticsService.getScbExports()
      .then(({ data }) => {
        if (data.status === 'success') {
          window.conversion.convertDates('ScbExportMonth', data.data.exports)
          commit('SET_SCB_EXPORTS', data.data.exports)
          commit('SET_HAS_TVV_KEY', data.data.has_automatic_reports)
        }
      })
      .finally(() => {
        commit('SET_IS_FETCHING_SCB_EXPORTS', false)
      })
  },
  confirmScbExport ({ commit }, scbExport) {
    commit('SET_IS_CONFIRMING_SCB_EXPORT_IDS', { scbExportId: scbExport.id, val: true })
    StatisticsService.confirmScbExport(scbExport.id)
      .then(({ data }) => {
        if (data.status === 'success') {
          window.conversion.convertDates('ScbExport', data.data)
          commit('REPLACE_SCB_EXPORT', data.data)
        }
      })
      .finally(() => {
        commit('SET_IS_CONFIRMING_SCB_EXPORT_IDS', { scbExportId: scbExport.id, val: false })
      })
  },
  createScbExport ({ commit }, month) {
    commit('SET_IS_CREATING_SCB_EXPORT_MONTHS', { month, val: true })
    StatisticsService.createScbExport(month)
      .then(({ data }) => {
        if (data.status === 'success') {
          window.conversion.convertDates('ScbExportMonth', data.data)
          commit('SET_SCB_EXPORTS', data.data)
        }
      })
      .finally(() => {
        commit('SET_IS_CREATING_SCB_EXPORT_MONTHS', { month, val: false })
      })
  },
  fetchCategoryOccupation ({ state, commit }, { updateSilently = false }) {
    if (state.isFetchingCategoryOccupation || state.isFetchingCategoryOccupationSilently) {
      return false // hämtar redan
    }
    if (updateSilently) {
      commit('SET_IS_FETCHING_CATEGORY_OCCUPATION_SILENTLY', true)
    } else {
      commit('SET_IS_FETCHING_CATEGORY_OCCUPATION', true)
      commit('SET_CATEGORY_OCCUPATION_DATA', null)
    }
    StatisticsService.getCategoryOccupation()
      .then(({ data }) => {
        if (data.status === 'success') {
          commit('SET_CATEGORY_OCCUPATION_DATA', data.data)
          commit('SET_FETCHING_CATEGORY_OCCUPATION_HAS_ERROR', false)
        } else {
          commit('SET_FETCHING_CATEGORY_OCCUPATION_HAS_ERROR', true)
        }
      })
      .catch(() => {
        commit('SET_FETCHING_CATEGORY_OCCUPATION_HAS_ERROR', true)
      })
      .finally(() => {
        commit('SET_IS_FETCHING_CATEGORY_OCCUPATION', false)
        commit('SET_IS_FETCHING_CATEGORY_OCCUPATION_SILENTLY', false)
      })
  }
}
