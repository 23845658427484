<template>
  <v-main class="dashboard-page" :class="{ 'is-edit-mode': isEditMode }">
    <v-app-bar
      flat
      color="rgba(0, 0, 0, 0)"
    >
      <v-toolbar-title class="text-h5 pl-0">
        Dashboard
      </v-toolbar-title>
      <div class="pl-6 d-flex">
        <div>
          <v-select
            style="max-width: 230px;"
            v-model="dashboardInputId"
            dense
            :items="dashboards"
            item-text="name"
            item-value="id"
            hide-details
          >
            <template v-slot:item="{ item }">
              <div>
                <span>{{ item.name }}</span>
              </div>
              <div class="ml-auto">
                <template v-if="item.is_editable === 0">
                  <v-icon style="margin-right: 6px;">
                    mdi-lock
                  </v-icon>
                </template>
                <template v-else>
                  <v-btn
                    @click.stop="deleteDashboard(item.id)"
                    icon
                    class="error-on-hover"
                    :loading="isDeletingDashboardIds.includes(item.id)"
                  >
                    <v-icon>
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </template>
              </div>
            </template>
            <template v-slot:append-item>
              <div class="mt-3 px-2 d-flex">
                <v-text-field
                  v-model="newDashboardName"
                  label="Ny dashboard"
                  :disabled="isSavingDashboard"
                  outlined
                  dense
                  :rules="[dashboardNameRule]"
                  :hide-details="dashboardNameError === ''"
                  :error-messages="dashboardNameError ? [dashboardNameError] : [] "
                  @keydown="dashboardNameKeydown"
                ></v-text-field>
                <v-btn
                  @click="saveNewDashboard"
                  :loading="isSavingDashboard"
                  color="success"
                  text
                  small
                  class="ml-2"
                  style="margin-top: 6px;"
                >Spara</v-btn>
              </div>
            </template>
          </v-select>
        </div>
        <div class="ml-4 d-none d-lg-block">
          <div v-if="!isEditMode">
            <div v-show="activeDashboard?.is_editable === 0">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                  >mdi-pencil-lock</v-icon>
                </template>
                <span>Den här dashboarden kan inte redigeras</span>
              </v-tooltip>
            </div>
            <div v-show="activeDashboard?.is_editable === 1">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    small
                    @click="startEditLayout"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-pen</v-icon>
                  </v-btn>
                </template>
                <span>Redigera layouten på den här dashboarden</span>
              </v-tooltip>
            </div>
          </div>
          <v-btn
            v-else
            small
            color="success"
            depressed
            @click="cancelEditLayout"
          >
            Avsluta redigering
          </v-btn>
        </div>
      </div>
      <v-spacer></v-spacer>
    </v-app-bar>
    <template v-if="dashboardHasNoCards && !isEditMode">
      <div class="d-flex flex-column justify-center full-width mt-12">
        <div class="mx-auto">
          <v-icon large>mdi-ghost-outline</v-icon>
        </div>
        <p class="text-caption-2 text--secondary mx-auto pt-6">
          Här var det tomt
        </p>
        <p class="text-caption-2 text--secondary mx-auto">
          Klicka på pennan högst upp på sidan för att redigera dashboarden
        </p>
      </div>
    </template>
    <v-row class="mx-2 my-0">
      <!-- första kolumnen -->
      <v-col cols="12" md="6" xl="4" class="d-flex flex-column pt-0">
        <template v-if="firstColumnCards.length === 0">
          <div class="add-new-card-button" @click="openDashboardCardPickerDialog({ column: 1, row: 1 })"></div>
        </template>
        <component
          :is="isEditMode ? 'vue-draggable' : 'div'"
          v-model="firstColumnCards"
          :group="{ name: 'activeCards' }"
          tag="div"
          class="dashboard-card-outer-container"
        >
          <div v-for="(card, i) in firstColumnCards" :key="card.pivot.id">
            <div class="dashboard-card-container">
              <dashboard-card
                :component-name="card.component_name"
                :dashboard-card-settings="card.pivot.settings"
                :pivot-id="card.pivot.id"
                @updated-dashboard-card-settings="updatedDashboardCardSettings"
              ></dashboard-card>
              <v-btn icon class="delete-card-button" color="error" @click="deleteCard(card)"><v-icon>mdi-delete</v-icon></v-btn>
            </div>
            <div class="add-new-card-button" @click="openDashboardCardPickerDialog({ column: 1, row: i + 2 })"></div>
          </div>
        </component>
      </v-col>

      <!-- andra kolumnen -->
      <v-col cols="12" md="6" xl="4" class="d-flex flex-column pt-0">
        <template v-if="secondColumnCards.length === 0">
          <div class="add-new-card-button" @click="openDashboardCardPickerDialog({ column: 2, row: 1 })"></div>
        </template>
        <component
          :is="isEditMode ? 'vue-draggable' : 'div'"
          v-model="secondColumnCards"
          :group="{ name: 'activeCards' }"
          tag="div"
          class="dashboard-card-outer-container"
        >
          <div v-for="(card, i) in secondColumnCards" :key="card.pivot.id">
            <div class="dashboard-card-container">
              <dashboard-card
                :component-name="card.component_name"
                :dashboard-card-settings="card.pivot.settings"
                :pivot-id="card.pivot.id"
                @updated-dashboard-card-settings="updatedDashboardCardSettings"
              ></dashboard-card>
              <v-btn icon class="delete-card-button" color="error" @click="deleteCard(card)"><v-icon>mdi-delete</v-icon></v-btn>
            </div>
            <div class="add-new-card-button" @click="openDashboardCardPickerDialog({ column: 2, row: i + 2 })"></div>
          </div>
        </component>
      </v-col>

      <!-- tredje kolumnen -->
      <!-- visas inte vid md eller lg (när bara visar två kolumner), istället fördelas korten i tredje kolumnen på första och andra kolumnen -->
      <v-col cols="12" md="6" xl="4" class="d-flex d-md-none d-xl-flex flex-column pt-0">
        <template v-if="thirdColumnCards.length === 0">
          <div class="add-new-card-button" @click="openDashboardCardPickerDialog({ column: 3, row: 1 })"></div>
        </template>
        <component
          :is="isEditMode ? 'vue-draggable' : 'div'"
          v-model="thirdColumnCards"
          :group="{ name: 'activeCards' }"
          tag="div"
          class="dashboard-card-outer-container"
        >
          <div v-for="(card, i) in thirdColumnCards" :key="card.pivot.id">
            <div class="dashboard-card-container">
              <dashboard-card
                :component-name="card.component_name"
                :dashboard-card-settings="card.pivot.settings"
                :pivot-id="card.pivot.id"
                @updated-dashboard-card-settings="updatedDashboardCardSettings"
              ></dashboard-card>
              <v-btn icon class="delete-card-button" color="error" @click="deleteCard(card)"><v-icon>mdi-delete</v-icon></v-btn>
            </div>
            <div class="add-new-card-button" @click="openDashboardCardPickerDialog({ column: 3, row: i + 2 })"></div>
          </div>
        </component>
      </v-col>
    </v-row>
    <dashboard-card-picker-dialog
      v-model="showDashboardCardPickerDialog"
      @selected-card="selectedCard"
    ></dashboard-card-picker-dialog>
  </v-main>
</template>

<script>
import { mapState } from 'vuex'

import DashboardService from '@/services/DashboardService.js'

import VueDraggable from 'vuedraggable'
import DashboardCard from '@/components/dashboard/DashboardCard'
import DashboardCardPickerDialog from '@/components/dashboard/DashboardCardPickerDialog'

export default {
  name: 'DashboardPage',
  components: {
    DashboardCard,
    DashboardCardPickerDialog,
    VueDraggable
  },
  data: () => ({
    isEditMode: false,
    showDashboardCardPickerDialog: false,
    newCardColumn: 0,
    newCardRow: 0,
    newDashboardName: '',
    dashboardNameError: '',
    isSavingDashboard: false,
    isDeletingDashboardIds: []
  }),
  computed: {
    ...mapState(
      {
        activeDashboard: state => state.stats.activeDashboard,
        dashboards: state => state.stats.dashboards
      }
    ),
    dashboardInputId: {
      get: function () {
        return this.activeDashboard?.id
      },
      set: function (val) {
        const newDashboard = this.dashboards.find(db => db.id === val)
        if (newDashboard) {
          this.$store.commit('stats/SET_ACTIVE_DASHBOARD', newDashboard)
        }
      }
    },
    dashboardHasNoCards: function () {
      return this.activeDashboard && this.activeDashboard.dashboard_cards.length === 0
    },
    firstColumnCards: {
      get: function () {
        if (!this.activeDashboard) {
          return []
        }
        let dashboardCards = this.activeDashboard.dashboard_cards.filter(dbc => dbc.pivot.column === 1).sort((a, b) => a.pivot.row - b.pivot.row)
        if (this.isTwoColumns) {
          // Om bara visar två kolumner (bredd md-lg) så fördelas korten från tredje kolumnen på första och tredje kolumnen istället
          const oddThirdColumnCards = this.activeDashboard.dashboard_cards.filter(dbc => dbc.pivot.column === 3).sort((a, b) => a.pivot.row - b.pivot.row).filter((dbs, i) => i % 2 === 0)
          dashboardCards = dashboardCards.concat(oddThirdColumnCards)
        }
        return dashboardCards
      },
      set: function (newColumnOrder) {
        const changes = []
        newColumnOrder.forEach((card, i) => {
          const newRow = i + 1
          const newColumn = 1
          if (card.pivot.row !== newRow || card.pivot.column !== newColumn) {
            // flyttar kortet lokalt
            card.pivot.row = newRow
            card.pivot.column = newColumn
            changes.push({ pivot_id: card.pivot.id, column: newColumn, row: newRow })
          }
        })
        this.persistCardPositions(changes)
      }
    },
    secondColumnCards: {
      get: function () {
        if (!this.activeDashboard) {
          return []
        }
        let dashboardCards = this.activeDashboard.dashboard_cards.filter(dbc => dbc.pivot.column === 2).sort((a, b) => a.pivot.row - b.pivot.row)
        if (this.isTwoColumns) {
          // Om bara visar två kolumner (bredd md-lg) så fördelas korten från tredje kolumnen på första och tredje kolumnen istället
          const evenThirdColumnCards = this.activeDashboard.dashboard_cards.filter(dbc => dbc.pivot.column === 3).sort((a, b) => a.pivot.row - b.pivot.row).filter((dbs, i) => i % 2 === 1)
          dashboardCards = dashboardCards.concat(evenThirdColumnCards)
        }
        return dashboardCards
      },
      set: function (newColumnOrder) {
        const changes = []
        newColumnOrder.forEach((card, i) => {
          const newRow = i + 1
          const newColumn = 2
          if (card.pivot.row !== newRow || card.pivot.column !== newColumn) {
            // flyttar kortet lokalt
            card.pivot.row = newRow
            card.pivot.column = newColumn
            changes.push({ pivot_id: card.pivot.id, column: newColumn, row: newRow })
          }
        })
        this.persistCardPositions(changes)
      }
    },
    thirdColumnCards: {
      get: function () {
        if (!this.activeDashboard || this.isTwoColumns) {
          return []
        }
        return this.activeDashboard.dashboard_cards.filter(dbc => dbc.pivot.column === 3).sort((a, b) => a.pivot.row - b.pivot.row)
      },
      set: function (newColumnOrder) {
        const changes = []
        newColumnOrder.forEach((card, i) => {
          const newRow = i + 1
          const newColumn = 3
          if (card.pivot.row !== newRow || card.pivot.column !== newColumn) {
            // flyttar kortet lokalt
            card.pivot.row = newRow
            card.pivot.column = newColumn
            changes.push({ pivot_id: card.pivot.id, column: newColumn, row: newRow })
          }
        })
        this.persistCardPositions(changes)
      }
    },
    isTwoColumns: function () {
      // cards delas in i 2 kolumner vid md och lg
      return this.$vuetify.breakpoint.mdOnly || this.$vuetify.breakpoint.lgOnly
    }
  },
  methods: {
    startEditLayout: function () {
      this.isEditMode = true
    },
    cancelEditLayout: function () {
      this.isEditMode = false
    },
    saveNewDashboard: function () {
      if (!this.validateDashboardName(true)) {
        return false
      }
      const name = this.newDashboardName
      this.isSavingDashboard = true
      DashboardService.createDashboard(name)
        .then(({ data }) => {
          if (data.status === 'success') {
            this.$store.commit('stats/ADD_DASHBOARD', data.data.dashboard)
            this.$store.commit('stats/SET_ACTIVE_DASHBOARD', data.data.dashboard)
          }
        })
        .finally(() => {
          this.isSavingDashboard = false
          this.newDashboardName = ''
          this.validateDashboardName()
        })
    },
    dashboardNameRule: function () {
      return this.validateDashboardName()
    },
    validateDashboardName: function (strongValidation = false) {
      console.log('validateDashboardName')
      if (!this.newDashboardName && strongValidation) {
        this.dashboardNameError = 'Ange ett namn'
        return false
      }
      if (this.newDashboardName.length > 40) {
        this.dashboardNameError = 'Max 40 tecken'
        return false
      }
      this.dashboardNameError = ''
      return true
    },
    deleteDashboard: function (dashboardId) {
      this.$store.commit('SET_OPEN_CONFIRMATION_DIALOG', {
        header: 'Ta bort dashboard',
        body: 'Är du säker på att du vill ta bort den här dashboarden? Denna åtgärd kan inte ångras.',
        confirmationFunction: this.confirmedDeleteDashboard,
        confirmationArgument: dashboardId
      })
    },
    confirmedDeleteDashboard: function (dashboardId) {
      this.isDeletingDashboardIds.push(dashboardId)
      DashboardService.deleteDashboard(dashboardId)
        .then(({ data }) => {
          if (data.status === 'success') {
            this.$store.commit('stats/SET_ACTIVE_DASHBOARD', this.dashboards[0])
            this.$store.commit('stats/DELETE_DASHBOARD', dashboardId)
          }
        })
        .finally(() => {
          const i = this.isDeletingDashboardIds.findIndex(id => id === dashboardId)
          this.isDeletingDashboardIds.splice(i, 1)
        })
    },
    dashboardNameKeydown: function (e) {
      if (e.key === 'Escape' || e.key === 'Esc') {
        this.newDashboardName = ''
      } else if (e.key === 'Return' || e.key === 'Enter') {
        this.saveNewDashboard()
      }
    },
    persistCardPositions: function (changes) {
      // changes en array med objekt i formatet { pivot_id, column, row }
      if (changes.length === 0) {
        return false
      }

      // sparar ny position på servern
      DashboardService.moveDashboardCards(changes)
        .then(({ data }) => {
          // card har uppdaterats lokalt redan innan request skickades
          // tror inte vi behöver loaders eller catch
        })
    },
    openDashboardCardPickerDialog: function ({ column, row }) {
      this.newCardColumn = column
      this.newCardRow = row
      this.showDashboardCardPickerDialog = true
    },
    selectedCard: function (card) {
      const dashboardId = this.activeDashboard.id
      const dashboardCardId = card.id
      const column = this.newCardColumn
      const row = this.newCardRow
      this.showDashboardCardPickerDialog = false
      this.$store.commit('SET_IS_LOADING_PAGE', true)
      DashboardService.addDashboardCardToDashboard({ dashboardId, dashboardCardId, column, row })
        .then(({ data }) => {
          if (data.status === 'success') {
            // hämtar om dashboards
            this.$store.dispatch('stats/fetchDashboards')
              .finally(() => {
                this.$store.commit('SET_IS_LOADING_PAGE', false)
              })
          } else {
            this.$store.commit('SET_IS_LOADING_PAGE', false)
          }
        })
        .catch(() => {
          this.$store.commit('SET_IS_LOADING_PAGE', false)
        })
    },
    deleteCard: function (card) {
      const pivotId = card.pivot.id
      this.$store.commit('SET_IS_LOADING_PAGE', true)
      DashboardService.removeDashboardCardFromDashboard(pivotId)
        .then(({ data }) => {
          if (data.status === 'success') {
            // hämtar om dashboards
            this.$store.dispatch('stats/fetchDashboards')
              .finally(() => {
                this.$store.commit('SET_IS_LOADING_PAGE', false)
              })
          } else {
            this.$store.commit('SET_IS_LOADING_PAGE', false)
          }
        })
        .catch(() => {
          this.$store.commit('SET_IS_LOADING_PAGE', false)
        })
    },
    updatedDashboardCardSettings: function ({ pivotId, settingName, settingValue }) {
      const dashboardCard = this.activeDashboard.dashboard_cards.find(dbc => dbc.pivot.id === pivotId)
      dashboardCard.pivot.settings[settingName] = settingValue
    }
  },
  watch: {
    activeDashboard: function () {
      if (this.activeDashboard?.is_editable === 0) {
        // Lämnar edit mode om man bytar till en dashboard som inte ska kunna redigeras
        this.isEditMode = false
      }
    }
  },
  mounted () {}
}
</script>
