<template>
  <div>
    <template v-if="componentName === 'weather-forecast'">
      <weather-forecast></weather-forecast>
    </template>
    <template v-else-if="componentName === 'product-group-sales-table'">
      <product-group-sales-table></product-group-sales-table>
    </template>
    <template v-else-if="componentName === 'checkinouts-calendar'">
      <checkinouts-calendar></checkinouts-calendar>
    </template>
    <template v-else-if="componentName === 'checked-in-customers'">
      <checked-in-customers :dashboard-card-settings="dashboardCardSettings" :pivot-id="pivotId" @updated-dashboard-card-settings="updatedDashboardCardSettings"></checked-in-customers>
    </template>
    <template v-else-if="componentName === 'active-customers'">
      <active-customers></active-customers>
    </template>
    <template v-else-if="componentName === 'customer-nationalities'">
      <customer-nationalities></customer-nationalities>
    </template>
    <template v-else-if="componentName === 'revenue-numbers'">
      <revenue-numbers></revenue-numbers>
    </template>
    <template v-else-if="componentName === 'category-occupation-list'">
      <category-occupation-list :dashboard-card-settings="dashboardCardSettings" :pivot-id="pivotId" @updated-dashboard-card-settings="updatedDashboardCardSettings"></category-occupation-list>
    </template>
    <template v-else-if="componentName === 'category-occupation-radar'">
      <category-occupation-radar :dashboard-card-settings="dashboardCardSettings" :pivot-id="pivotId" @updated-dashboard-card-settings="updatedDashboardCardSettings"></category-occupation-radar>
    </template>
    <template v-else-if="componentName === 'category-occupation-polar'">
      <category-occupation-polar :dashboard-card-settings="dashboardCardSettings" :pivot-id="pivotId" @updated-dashboard-card-settings="updatedDashboardCardSettings"></category-occupation-polar>
    </template>
    <template v-else-if="componentName === 'total-occupation'">
      <total-occupation :dashboard-card-settings="dashboardCardSettings" :pivot-id="pivotId" @updated-dashboard-card-settings="updatedDashboardCardSettings"></total-occupation>
    </template>
    <template v-else-if="componentName === 'accommodation-value'">
      <accommodation-value></accommodation-value>
    </template>
    <template v-else-if="componentName === 'historical-turnover-full-accrual'">
      <historical-turnover-full-accrual></historical-turnover-full-accrual>
    </template>
    <template v-else-if="componentName === 'historical-turnover-part-accrual'">
      <historical-turnover-part-accrual></historical-turnover-part-accrual>
    </template>
    <template v-else-if="componentName === 'occupancy-stats'">
      <occupancy-stats :dashboard-card-settings="dashboardCardSettings" :pivot-id="pivotId" @updated-dashboard-card-settings="updatedDashboardCardSettings"></occupancy-stats>
    </template>
    <template v-else>
      <v-alert type="error">Komponenten med namn "{{ componentName }}" kunde inte läsas in</v-alert>
    </template>

  </div>
</template>

<script>

export default {
  name: 'DashboardCard',
  components: {
    /* Endast komponenten som behövs laddas in */
    WeatherForecast: () => import('./WeatherForecast'),
    ProductGroupSalesTable: () => import('@/components/statistics/ProductGroupSalesTable'),
    CheckinoutsCalendar: () => import('./CheckinoutsCalendar'),
    CheckedInCustomers: () => import('@/components/statistics/CheckedInCustomers'),
    ActiveCustomers: () => import('@/components/statistics/ActiveCustomers'),
    CustomerNationalities: () => import('@/components/statistics/CustomerNationalities'),
    RevenueNumbers: () => import('@/components/statistics/RevenueNumbers'),
    CategoryOccupationList: () => import('@/components/statistics/CategoryOccupationList'),
    CategoryOccupationRadar: () => import('@/components/statistics/CategoryOccupationRadar'),
    CategoryOccupationPolar: () => import('@/components/statistics/CategoryOccupationPolar'),
    TotalOccupation: () => import('@/components/statistics/TotalOccupation'),
    AccommodationValue: () => import('@/components/statistics/AccommodationValue'),
    HistoricalTurnoverFullAccrual: () => import('@/components/statistics/HistoricalTurnoverFullAccrual'),
    HistoricalTurnoverPartAccrual: () => import('@/components/statistics/HistoricalTurnoverPartAccrual'),
    OccupancyStats: () => import('@/components/statistics/OccupancyStats')
  },
  props: {
    componentName: String,
    dashboardCardSettings: Object,
    pivotId: Number
  },
  data: () => ({}),
  computed: {},
  methods: {
    updatedDashboardCardSettings: function (param) {
      this.$emit('updated-dashboard-card-settings', param)
    }
  },
  mounted () {}
}
</script>
