<template>
  <div class="receipt-payments-timeline">
    <v-timeline
      dense
    >
      <v-timeline-item
        v-if="payments.length === 0"
        fill-dot
        class="align-center"
      >
        <template v-slot:icon>
          <v-icon color="white">mdi-currency-usd-off</v-icon>
        </template>
        <p class="text-body-2 my-auto">
          Inga betalningar har gjorts på det här kvittot
        </p>
      </v-timeline-item>
      <v-timeline-item
        v-for="timelineItem in timelineItems"
        :key="timelineItem.id"
        fill-dot
        :color="timelineItem.color"
      >
        <template v-slot:icon>
          <v-icon color="white">{{ timelineItem.icon }}</v-icon>
        </template>
        <v-row class="pt-1 mx-0">
          <v-col cols="3" class="d-flex flex-column">
            <div class="my-auto">{{ timelineItem.paidAt }}</div>
          </v-col>
          <v-col>
            <strong>{{ timelineItem.title }}</strong>
            <div class="text-caption">
              {{ timelineItem.subtitle }}
            </div>
          </v-col>
        </v-row>
      </v-timeline-item>
    </v-timeline>
  </div>
</template>

<script>

export default {
  name: 'ReceiptPaymentsTimeline',
  props: {
    payments: Array
  },
  data: () => ({}),
  computed: {
    timelineItems: function () {
      const receiptPayments = [...this.payments].sort((a, b) => {
        // b före a = 1, a före b = -1
        if (a.created_at.isBefore(b.created_at)) {
          // a betald för b
          return -1
        } else {
          return 1
        }
      })
      return receiptPayments.map(payment => {
        const paidAt = payment.created_at
        return {
          id: payment.id,
          paidAt: paidAt.toHumanReadableDate(),
          icon: payment.payment_method.icon_class_name,
          color: payment.payment_method.default_gui_color,
          title: `${payment.paid_amount / 100} kr SEK`,
          isRefund: payment.paid_amount < 0,
          subtitle: `Betalningsmetod: ${payment.payment_method.name}`
        }
      })
    }
  },
  methods: {},
  watch: {}
}
</script>
