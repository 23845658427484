import API from './API.js'

export default {
  //
  // GET
  //
  getStripeOnboardingLink () {
    return API.httpClient.get('onboarding/stripe-onboarding-link')
  },
  getStripeOnboardingDetails () {
    return API.httpClient.get('onboarding/stripe-onboarding-details')
  },
  getSurfboardOnboardingLink () {
    return API.httpClient.get('onboarding/surfboard-onboarding-link')
  },
  getSurfboardOnboardingDetails () {
    return API.httpClient.get('onboarding/surfboard-onboarding-details')
  },

  //
  // POST
  //
  savePositions (positions) {
    const items = positions.map((position) => {
      const item = Object.assign({}, position)
      item.category = null
      return item
    })
    return API.httpClient.post('onboarding/save-positions', {
      positions: items
    })
  }
}
