<template>
  <div class="search-preview-pane pb-3">
    <template v-if="data?.type === 'booking'">
      <booking-data-list
        :booking="data.previewData.booking"
        :hidden-sections="['booking', 'cancellation-policy']"
        position-clickable
        customer-clickable
      ></booking-data-list>
    </template>
    <template v-else-if="data?.type === 'customer'">
      <!-- <bookings-list
        :bookings="bookings"
        :loading="fetchingChunks.includes('bookings')"
        @booking-clicked="showBooking"
        @close="close"
      ></bookings-list> -->
      <span class="pl-4 text-h6">Bokningar</span>
      <customer-bookings-list
        :bookings="data.previewData.customer_bookings"
        @booking-clicked="bookingClicked"
        @close="close"
      ></customer-bookings-list>
    </template>
    <template v-else-if="data?.type === 'position'">
      <p class="text-subtitle-2 text--secondary font-weight-regular mb-2">Incheckad bokning</p>
      <template v-if="checkedInBooking">
        <div style="margin-left: -16px; margin-right: -16px;">
          <bookings-list-row
            :booking="checkedInBooking"
            :title="checkedInBookingTitle"
            :subtitle="checkedInBookingSubtitle"
            :secondary-subtitle="checkedInBookingSecondarySubtitle"
            view="checkouts"
          ></bookings-list-row>
        </div>
      </template>
      <template v-else>
        <v-alert
          dense
          text
          type="info"
          class="ma-0 mb-4"
        >
          Ingen är incheckad på platsen
        </v-alert>
      </template>

      <p class="text-subtitle-2 text--secondary font-weight-regular mb-2 mt-8">Kommande bokningar</p>
      <template v-if="data?.previewData?.future_position_bookings && data?.previewData?.future_position_bookings.length > 0">
        <div
          v-for="booking in data?.previewData?.future_position_bookings"
          :key="booking.id"
          style="margin-left: -16px; margin-right: -16px;"
        >
          <bookings-list-row
            :booking="booking"
            :title="booking.customer.full_name"
            :subtitle="booking.subtitle"
            :secondary-subtitle="secondarySubtitleBookingsList(booking)"
            view="checkins"
          ></bookings-list-row>
        </div>
      </template>
      <template v-else>
        <v-alert
          dense
          text
          type="info"
          class="ma-0 mb-4"
        >
          Det finns inga kommande bokningar på platsen
        </v-alert>
      </template>
    </template>

    <template v-else-if="data?.type === 'product'">
      <sales-history-chart :prefetched-data="salesHistoryData"></sales-history-chart>
    </template>
  </div>
</template>

<script>
import BookingDataList from '@/components/booking/DataList.vue'
import CustomerBookingsList from '@/components/customer/BookingsList.vue'
import BookingsListRow from '@/components/booking/lists/BookingsListRow.vue'
import SalesHistoryChart from '@/components/product/SalesHistoryChart.vue'

export default {
  name: 'SearchPreviewPane',
  components: {
    BookingDataList,
    CustomerBookingsList,
    BookingsListRow,
    SalesHistoryChart
  },
  props: {
    data: Object
  },
  data: () => ({}),
  computed: {
    salesHistoryData: function () {
      return this.data?.previewData?.sales_history_data
    },
    checkedInBooking: function () {
      return this.data?.previewData?.checked_in_position_booking
    },
    checkedInBookingTitle: function () {
      if (!this.checkedInBooking) {
        return ''
      }
      return this.checkedInBooking.customer.full_name
    },
    checkedInBookingSubtitle: function () {
      if (!this.checkedInBooking) {
        return ''
      }
      return this.checkedInBooking.subtitle
    },
    checkedInBookingSecondarySubtitle: function () {
      if (!this.checkedInBooking) {
        return ''
      }
      return this.secondarySubtitleBookingsList(this.checkedInBooking)
    }
  },
  methods: {
    close: function () {
      this.$emit('close')
    },
    bookingClicked: function (booking) {
      // this.close()
      this.$store.dispatch('booking/openDialogById', { bookingId: booking.id })
    },
    secondarySubtitleBookingsList: function (booking) {
      const checkInDateFormatted = booking.check_in.toHumanReadableDate()
      const checkOutDateFormatted = booking.check_out.toHumanReadableDate()
      return `${checkInDateFormatted} till ${checkOutDateFormatted}`
    }
  },
  watch: {}
}
</script>
