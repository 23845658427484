<template>
  <div class="tally-consumption-table">
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              Tjänst
            </th>
            <th class="text-right">
              Antal
            </th>
            <th class="text-right">
              Tidpunkt
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-if="isFetchingConsumptionPage || isLoading">
            <tr v-for="i in skeletonRows" :key="i">
              <td>
                <v-skeleton-loader
                  style=""
                  type="table-cell"
                  class=""
                ></v-skeleton-loader>
              </td>
              <td v-for="x in 2" :key="x" class="text-right">
                <v-skeleton-loader
                  style=""
                  type="table-cell"
                  class=""
                ></v-skeleton-loader>
              </td>
            </tr>
          </template>
          <template v-else-if="pageItems.length === 0">
            <tr class="table-row-no-consumption">
              <td colspan="3">
                <v-alert
                  dense
                  text
                  color="info"
                  class="my-4 mx-auto text-center"
                >
                  Det finns ingen tally-konsumption
                </v-alert>
              </td>
            </tr>
          </template>
          <template v-else>
            <tr
              v-for="(item, i) in pageItems"
              :key="i"
            >
              <td>{{ item.service_name }}</td>
              <td class="text-right">{{ item.consumption }}</td>
              <td class="text-right">{{ formatDateTime(item.consumed_at) }}</td>
            </tr>
          </template>
        </tbody>
      </template>
    </v-simple-table>
    <div v-show="totalPages > 1" class="text-center pt-4">
      <v-pagination
        v-model="currentPage"
        :length="totalPages"
        :total-visible="6"
        :disabled="isFetchingConsumptionPage"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import TallyService from '@/services/TallyService.js'

export default {
  name: 'TallyConsumptionTable',
  components: {},
  props: {
    bookingId: Number,
    isLoading: Boolean,
    isDisplayed: Boolean
  },
  data: () => ({
    isFetchingConsumptionPage: false,
    pageItems: [],
    totalPages: 1,
    currentPage: 1,
    skeletonRows: 2
  }),
  computed: {},
  methods: {
    getPage: function (page) {
      this.isFetchingConsumptionPage = true
      this.pageItems = []
      const bookingId = this.bookingId
      TallyService.getConsumptionPage({ bookingId, page })
        .then(({ data }) => {
          if (data.status === 'success') {
            const responsePage = parseInt(data.data.current_page, 10)
            if (this.bookingId === bookingId && this.currentPage === responsePage) {
              this.pageItems = data.data.consumption
              this.skeletonRows = Math.max(this.pageItems.length, 2)
              this.totalPages = parseInt(data.data.total_pages, 10)
            }
          }
        }).finally(() => {
          this.isFetchingConsumptionPage = false
        })
    },
    formatDateTime: function (dt) {
      return window.dayjs.parseServerDateTime(dt).toHumanReadableDate()
    }
  },
  watch: {
    bookingId: function () {
      this.skeletonRows = 2
      this.totalPages = 1
      console.log('watch bookingId', (this.bookingId && this.isDisplayed), this.bookingId, this.isDisplayed)
      if (this.bookingId && this.isDisplayed) {
        this.getPage(1)
      }
    },
    isDisplayed: function () {
      console.log('watch isDisplayed', (this.bookingId && this.isDisplayed))
      if (this.bookingId && this.isDisplayed) {
        this.getPage(1)
      }
    },
    currentPage: function () {
      this.getPage(this.currentPage)
    }
  }
}
</script>
